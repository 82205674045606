import { Currency } from '@uniswap/sdk'
import React from 'react'
import styled from 'styled-components'
import CurrencyLogo from '../CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
}

const HigherLogo = styled(CurrencyLogo)`
  z-index: 2;
`
const CoveredLogo = styled(CurrencyLogo)<{ sizeraw: number }>`
position: absolute;
left: ${({ sizeraw }) => '-' + (sizeraw / 2).toString() + 'px'} !important;
`

const VaultHigherLogo = styled.div`
  z-index: 2;
  position: absolute;

`

const VaultCoveredLogo = styled.div`
  z-index: 1;
  position: absolute;
  top: 16px;
  left: 16px;
`

// left: ${({ sizeraw }) => '-' + (sizeraw / 2).toString() + 'px'} !important;

const OverlapHigherLogo = styled.div`
  z-index: 2;
  position: absolute;
`
const OverlapCoveredLogo = styled.div`
  z-index: 1;
  position: absolute;
  left: 16px;
`

const OverlapWrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  height: 24px;
  width: 50px;
  position: relative;
  margin-right: -10px;
`
  // margin-right: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};  

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 16,
  margin = false
}: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      {currency0 && <HigherLogo  currency={currency0} size={size.toString() + 'px'} />}
      {currency1 && <CoveredLogo currency={currency1} size={size.toString() + 'px'} sizeraw={size} />}
    </Wrapper>
  )
}

export function OverlapDoubleCurrencyLogo({
  currency0,
  currency1,
  size = 24,
  margin = false
}: DoubleCurrencyLogoProps) {
  return (
    <OverlapWrapper margin={true} sizeraw={32}>
      {currency0 && <OverlapHigherLogo><CurrencyLogo currency={currency0} size="24px" /> </OverlapHigherLogo>}
      {currency1 && <OverlapCoveredLogo><CurrencyLogo currency={currency1} size="24px" /></OverlapCoveredLogo>}

    </OverlapWrapper>
  )
}

const CurrenyWrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  height: 50px;
  width: 50px;
  position: relative;
  margin-right: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};  
`

export function VaultCurrencyLogo({
  currency0,
  currency1,
  size = 32,
  margin = false
}: DoubleCurrencyLogoProps) {
  return (
    <CurrenyWrapper margin={true} sizeraw={32}>
      {currency0 && <VaultHigherLogo><CurrencyLogo currency={currency0} size="32px" /> </VaultHigherLogo>}
      {currency1 && <VaultCoveredLogo><CurrencyLogo currency={currency1} size="32px" /></VaultCoveredLogo>}
    </CurrenyWrapper>
  )
}

interface CustomCurrenyLogoProps {
  margin?: boolean
  size?: number
  url : string
}

const CustomCurrenyWrapper = styled.div<{ margin: boolean }>`
  display: flex;
  height: 50px;
  width: 50px;
  align-items: center;
  margin-right: ${({ margin }) => margin && (32 / 3 + 8).toString() + 'px'};  
`
  

export function CustomCurrencyLogo ({
  url,
  size = 50,
  margin = false
}: CustomCurrenyLogoProps) {
  return (
    <CustomCurrenyWrapper margin={true}>
      <img src={url} width={size} alt="" />
    </CustomCurrenyWrapper>
  )
}

const SmallCustomCurrenyWrapper = styled.div<{ margin: boolean }>`
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  margin-right: ${({ margin }) => margin && '2px'};  
`
  

export function SmallCustomCurrencyLogo ({
  url,
  size = 40,
  margin = false
}: CustomCurrenyLogoProps) {
  return (
    <SmallCustomCurrenyWrapper margin={true}>
      <img src={url} width={size} alt="" />
    </SmallCustomCurrenyWrapper>
  )
}

