import React from "react";
import { Link } from 'react-router-dom'
import styled from "styled-components";
import CraftCoinImg from '../../assets/images/CraftCoinHead.png'
import CraftCoinDarkImg from '../../assets/images/CraftCoinHead-dark.png'
import bgImage from '../../assets/images/land-bg.png'
import bgImageDark from '../../assets/images/land-bg-dark.png'
import ExternalLink from '../../assets/images/ExternalLink.png'
// import BlueBox from '../../assets/images/bluebox-bg.png'

import CraftRedeem from '../../assets/images/CraftRedeem.png'
import CraftMint from '../../assets/images/CraftMint.png'

import { ButtonCraft } from '../Button'
import { useIsDarkMode } from "state/user/hooks";



function CraftCoinLanding() {
  const darkMode = useIsDarkMode()

  return (
    <LandingContainer>


    <LandingScreen>
    
      <LandingRow>
      <WhiteBox url={darkMode ? bgImageDark : bgImage}><CraftCoinHead src={darkMode ? CraftCoinDarkImg : CraftCoinImg} /></WhiteBox>
      <WhiteBoxText url={darkMode ? bgImageDark : bgImage}><BoldText>CRAFT</BoldText> is our first asset backed stable coin in AleSwap. <br />
        1 CRAFT = 1 BUSD<br />
        Want to know more about CRAFT <a href="https://aleswapth.gitbook.io/aleswap/craft-ale-usage-guide"><ExternalImg src={ExternalLink} /></a></WhiteBoxText>
    
      <CraftRow>
        <ButtonCraft as={Link} to="/craft/mint"><CraftItemImg src={CraftMint} />MINT</ButtonCraft>
        <ButtonCraft as={Link} to="/craft/redeem"><CraftItemImg src={CraftRedeem} />REDEEM</ButtonCraft>
      </CraftRow> 
      </LandingRow>
    
    
    </LandingScreen>
    
    </LandingContainer>

    // <CraftCoinContainer>

    //   <MerchantScreen>
    //     <WhiteBox><CraftCoinHead src={CraftCoinImg} /></WhiteBox>
    //     <WhiteBoxText><BoldText>CRAFT</BoldText> is our first asset backed stable coin in AleSwap. <br />
    //       1 CRAFT = 1 BUSD<br />
    //       Want to know more about CRAFT <a href="https://aleswap-finance.gitbook.io/aleswap/craft-ale-usage-guide"><ExternalImg src={ExternalLink} /></a></WhiteBoxText>

    //     <CraftRow>
    //       <ButtonCraft as={Link} to="/craft/mint"><CraftItemImg src={CraftMint} />MINT</ButtonCraft>
    //       <ButtonCraft as={Link} to="/craft/redeem"><CraftItemImg src={CraftRedeem} />REDEEM</ButtonCraft>
    //     </CraftRow>



    //   </MerchantScreen>

    // </CraftCoinContainer>


  );
}


const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top:10px;
`;




const LandingRow = styled.div`
display:grid;
  width: 100%;
  
  align-items: center;
justify-items: center;


`;



const LandingScreen = styled.div`
  width: 100%;
  height: 100%;
  
  flex-direction: column;
 
  display: flex;
  align-items: center;
  
   margin: 0;
  
  
  
`;

// const CraftCoinContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 60%;
  
  
// `;


const WhiteBox = styled.div<{ url : string }>`
  background: url(${({ url }) => url});
// @media (max-width: 992px) {
//   width: 520px;
// }

// @media (max-width: 600px) {
//   width: 400px;
//   margin-top:50px;
// }
width:100%;
max-width:520px;
background-color:#ffffff;
color:#0B2553;
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;
display:grid;
align-items: center;
justify-items: center;
margin-bottom:20px;
padding-top:10px;
padding-bottom:10px;

  font-size: 1.5vw;
  text-align:center;
  


`;


const WhiteBoxText = styled.div<{ url : string }>`
background: url(${({ url }) => url});

// @media (max-width: 992px) {
//   width: 520px;
// }

// @media (max-width: 600px) {
//   width: 400px;
//   font-size: 3vw;
// }
width:100%;
max-width:520px;
background-color:#ffffff;
color:${({ theme }) => theme.text6};
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;

align-items: center;
justify-items: center;
margin-bottom:20px;
padding-top:10px;
padding-bottom:10px;


  text-align:center;


`;

const BoldText = styled.span`

font-weight:bold;



`;

const CraftCoinHead = styled.img`

width: 60%;

display:grid;
align-items: center;
justify-items: center;


`;

const ExternalImg = styled.img`

width: 18px;
display: inline-block;



`;

const CraftRow = styled.div`
// @media (max-width: 992px) {
//   width: 520px;
// }

// @media (max-width: 600px) {
//   width: 400px;
//   font-size: 1.5vw;
// }
width:100%;
max-width:520px;
display: grid;
    grid-template-columns: 1fr 1fr;
    
    column-gap:20px;
    row-gap: 20px;


`;

// const CraftItem = styled.div`


// padding: 10px;

// padding-top: 20px;




// background-color:#347AF0;
// color:#FFFFFF;
// box-shadow: 0px 1px 1px #00000040;
// border-radius: 15px;

// align-items: center;
// justify-items: center;
// margin-bottom:20px;

// background: url(${BlueBox}); 
// display:grid;
// font-weight:bold;
// font-size:1.8vw;


// `;

const CraftItemImg = styled.img`
margin-left:10%;
margin-bottom:20px;
margin-top:10px;

width: 80%;




`;




// const MerchantScreen = styled.div`
//   width: 40%;
//   height: 700px;
//   padding-top:10px;

  
  
//   flex-direction: column;
  
  
 
//   display: flex;
//   align-items: center;
  
//    margin: 0;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   -ms-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
  
// `;



export default CraftCoinLanding;
