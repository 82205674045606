import NewMenu from 'components/NewMenu'
import { AllMenu } from 'components/NewMenu/NewMenu'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import React, { Suspense, useRef, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { HideSmall } from 'theme'
import Logo from '../assets/svg/logo.svg'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import Craft from './Craft'
import Farm from './Farm'
import Home from './Home'
import Info from './Info'
import MintCraft from './MintCraft'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import Redeem from './Redeem'
import RedeemCraft from './RedeemCraft'
import RedeemCraftMerchant from './RedeemCraftMerchant'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import SpendByCraft from './SpendByCraft'
import SpendByCryptoV2 from './SpendByCryptoV2'
import Swap from './Swap'
import { RedirectPathToHome, RedirectPathToSpend, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Vault from './Vault'
import VaultPrivate from './VaultPrivate'
import AleSwapHeader from '../assets/images/AleSwap-Text-blue.png';
import AleSwapHeaderDark from '../assets/images/AleSwap-Text-white.png';
import { useIsDarkMode } from 'state/user/hooks'
import WithdrawVault from './WithdrawVault'
import Merchant from './Merchant'
import GenerateQR from './GenerateQR'
import { useActiveWeb3React } from 'hooks'
import { ChainId } from '../constants'
//import { ErrnoException } from 'node'

interface ErrnoException {
  // errno?: number;
  code: number;
  // path?: string;
  // syscall?: string;
  stack: string;
  message: string;
}


const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  // overflow-x: hidden;
`

// const HeaderWrapper = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap}
//   width: 100%;
//   justify-content: space-between;
//   position: fixed;
//   top: 0;
//   z-index: 1;
// `



const HeaderBar = styled.div`
  display: flex;
  // margin-top: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  // position: fixed;
  // top: 0;
  z-index: 1;
  background: ${({ theme }) => theme.headerBG};
  border-bottom: 3px solid ${({ theme }) => theme.headerLine};;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  align-items: center;
  flex: 1;
  z-index: 1;
  background: ${({ theme }) => theme.NewBG};
  min-height: 100vh;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 16px;
  `};
`
//     padding-top: 6rem;
const Marginer = styled.div`
  margin-top: 5rem;
`

const BodyWithNavMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: ${({ theme }) => theme.headerBG};
  
  z-index: 2;
`
// const NavBarItem = styled.div`
//   cursor: pointer;
// `




const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg2)};
  border-radius: 12px;
  white-space: nowrap;
  margin-right: 20px;
  // width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`


const AleLogo = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  align-items: center;
  align-content: center;

`
//   border: 1px solid blue;
const AleHeader = styled.div`

height: 24px;
`
// border: 1px solid white;

// const AleHeaderImg = styled.img<{ img: string }>`
//   src: url(${({ img }) => img});

// `

const HeaderMenuWrapper = styled.div`
  display: block;
  width: 100%;
`



export const StyledBurger = styled.button`
  // position: absolute;
  top: 5%;
  left: 2rem;
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`  
  display: flex;
  `};
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  &:focus {
    outline: none;
  }  
  svg {
    fill: ${({ theme }) => theme.textNavMenu};
  }     
`;

interface BurgerProps {
  open: boolean
  setOpen: (_open: boolean) => void
}

const Burger = ({ open, setOpen } : BurgerProps) => {
  return (
    <StyledBurger onClick={() => setOpen(!open)}>
    <svg focusable="false" viewBox="0 0 24 24">
      <path d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z" />
    </svg>
    </StyledBurger>
  )
}

// function isError(error: any): error is ErrnoException {
//   return error instanceof ErrnoException;
// }

function CheckRedirectChainDomain() {
  const { chainId } = useActiveWeb3React()  
  if (chainId === ChainId.REI && window.location.hostname !== "rei.aleswap.finance") {
    // to must be covered by from.<Redirect to="/somewhere/else" />
    return (<>{ window.location.assign('http://rei.aleswap.finance')} </>)
  }  
  return (<></>)
}

async function checkChain() {
  const ethereum = (window as any).ethereum

  if (ethereum && window.location.hostname === "rei.aleswap.finance") {
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xD903' }],
      });
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask.
      console.log(error)
      if ((error as ErrnoException).code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{ chainId: '0xD903', nativeCurrency: {name:"REI", symbol:"REI", decimals: 18} , rpcUrls: ["https://rei-rpc.moonrhythm.io"], chainName: 'REI Chain', blockExplorerUrls: ["https://reiscan.com/"]}],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  } else if (ethereum) {
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }],
      });
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask.
      console.log(error)
      if ((error as ErrnoException).code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{ chainId: '0x38', nativeCurrency: {name:"BNB", symbol:"BNB", decimals: 18} , rpcUrls: ["https://bsc-dataseed.binance.org/"], chainName: 'Smart Chain', blockExplorerUrls: ["https://bscscan.com/"]}],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }

}

export default function App() {

  const node = useRef()
  useOnClickOutside(node, () => setOpen(false));  
  const darkMode = useIsDarkMode()
  const [open, setOpen] = useState<boolean>(false);  
  const { chainId } = useActiveWeb3React()

  checkChain()


  // useEffect(() => {
  //   // Update the document title using the browser API
  //   document.title = !alePrice ? "AleSwap" : "AleSwap - $"+alePrice 
  // });  

  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        {/* <URLWarning /> */}
        {/* <HeaderWrapper>
          <Header />
        </HeaderWrapper>  */}
        {/* <CheckRedirectChainDomain /> */}
        <HeaderMenuWrapper ref={node as any}>
          <HeaderBar>
            <AleLogo>
              <Burger open={open} setOpen={setOpen} /><img width={'45px'} src={Logo} alt="logo" /> <HideSmall><AleHeader><img src={ darkMode ? AleSwapHeaderDark : AleSwapHeader} height="30px"/></AleHeader></HideSmall>
            </AleLogo>
            <Header/>
          </HeaderBar>
          <NewMenu isOpen={open} setOpen={setOpen}></NewMenu>
        </HeaderMenuWrapper>
        <BodyWithNavMenuWrapper>
          <AllMenu />

          <BodyWrapper>
            <Popups />
            {/* <Polling /> */}
            {/* <TopLevelModals /> */}
            <Web3ReactManager>
              <>
                {
                  window.location.hostname !== "rei.aleswap.finance" && chainId !== ChainId.REI ? (
                    <Switch>
                      <Route exact strict path="/swap" component={Swap} />
                      <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                      <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                      <Route exact strict path="/find" component={PoolFinder} />
                      <Route exact strict path="/pool" component={Pool} />
                      <Route exact strict path="/farm" component={Farm} />
                      <Route exact strict path="/vault" component={Vault} />
                      <Route exact strict path="/vault/private" component={VaultPrivate} />
                      <Route exact strict path="/vault/withdraw/" component={WithdrawVault} />
                      <Route exact strict path="/craft" component={Craft} />
                      <Route exact strict path="/craft/mint" component={MintCraft} />
                      <Route exact strict path="/craft/redeem" component={RedeemCraft} />
                      <Route exact strict path="/craft/merchant" component={RedeemCraftMerchant} />
                      <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                      <Route exact path="/add" component={AddLiquidity} />
                      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact path="/create" component={AddLiquidity} />
                      <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

                      <Route exact strict path="/redeem" component={Redeem} />
                      <Route exact strict path="/redeem/crypto" component={SpendByCryptoV2} />
                      <Route exact strict path="/redeem/craft" component={SpendByCraft} />
                      <Route exact strict path="/info" component={Info} />
                      <Route exact strict path="/home" component={Home} />
                      <Route exact strict path="/merchant" component={Merchant} />
                      <Route exact strict path="/merchant/invoice" component={GenerateQR} />
                      <Route component={RedirectPathToHome} />
                    </Switch>
                  ) : (
                    <Switch>
                      <Route exact strict path="/redeem" component={Redeem} />
                      <Route exact strict path="/redeem/crypto" component={SpendByCryptoV2} />
                      <Route exact strict path="/redeem/craft" component={SpendByCraft} />
                      <Route exact strict path="/info" component={Info} />
                      <Route exact strict path="/merchant" component={Merchant} />
                      <Route exact strict path="/merchant/invoice" component={GenerateQR} />
                      <Route component={RedirectPathToSpend} />
                    </Switch>)
                }


                {/* <Route exact strict path="/redeem/crypto/v2" component={RedeemByCryptoV2} /> */}
                {/* <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} /> */}
                {/* <Route exact strict path="/redeem/:outputCurrency" component={RedirectToRedeem} /> */}
                {/* <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} /> */}
                {/* <Route exact strict path="/migrate/v1" component={MigrateV1} />
                <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} /> */}
                {/* <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} /> */}
                {/* <Route exact strict path="/vote/:id" component={VotePage} /> */}

                {/* <Route component={RedirectPathToSwapOnly} /> */}

                {/* RedirectPathToHome */}
              </>
            </Web3ReactManager>
            <Marginer />
          </BodyWrapper>
        </BodyWithNavMenuWrapper>
      </AppWrapper>
    </Suspense>
  )
}
