import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { AleStakingInfo } from '../../state/stake/hooks'
// import { useStakingContract } from '../../hooks/useContract'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useActiveWeb3React } from '../../hooks'

import { TokenAmount } from '@uniswap/sdk'
import {  useAleStakingRewardsContract, useBrewmasterContract } from '../../hooks/useContract'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: AleStakingInfo
}

export default function ClaimRewardModal({ isOpen, onDismiss, stakingInfo }: StakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  // const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)

  // async function onClaimReward() {
  //   if (stakingContract && stakingInfo?.stakedAmount) {
  //     setAttempting(true)
  //     await stakingContract
  //       .getReward({ gasLimit: 350000 })
  //       .then((response: TransactionResponse) => {
  //         addTransaction(response, {
  //           summary: `Claim accumulated UNI rewards`
  //         })
  //         setHash(response.hash)
  //       })
  //       .catch((error: any) => {
  //         setAttempting(false)
  //         console.log(error)
  //       })
  //   }
  // }

  const brewmasterContract = useBrewmasterContract()

  async function onClaimReward() {
    if (brewmasterContract && stakingInfo?.stakedAmount) {
      setAttempting(true)
      await brewmasterContract
        .deposit(stakingInfo.poolId,0, { gasLimit: 300000 }) 
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Harvested ALE`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? 'Enter an amount'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Harvest</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {stakingInfo?.earnedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {stakingInfo?.earnedAmount?.toSignificant(6)}
              </TYPE.body>
              <TYPE.body>ALE to harvest</TYPE.body>
            </AutoColumn>
          )}
          {/* <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you harvest without withdrawing your liquidity remains in the mining pool.
          </TYPE.subHeader> */}
          <ButtonError disabled={!!error} error={!!error && !!stakingInfo?.stakedAmount} onClick={onClaimReward}>
            {error ?? 'Harvest'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Harvesting {stakingInfo?.earnedAmount?.toSignificant(6)} ALE</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Harvested ALE</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}

interface SingleStakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  earnedAmount : TokenAmount | undefined
}


export function SingleClaimRewardModal({ isOpen, onDismiss, earnedAmount }: SingleStakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const brewmasterContract = useBrewmasterContract()

  async function onClaimReward() {
    if (brewmasterContract && earnedAmount) {
      setAttempting(true)
      await brewmasterContract
        .enterStaking(0, { gasLimit: 300000 }) 
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Harvested ALE`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  // if (!stakingInfo?.stakedAmount) {
  //   error = error ?? 'Enter an amount'
  // }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Harvest</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {earnedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {earnedAmount?.toSignificant(6)}
              </TYPE.body>
              <TYPE.body>ALE to harvest</TYPE.body>
            </AutoColumn>
          )}
          <ButtonError disabled={!!error} error={!!error && !!earnedAmount} onClick={onClaimReward}>
            {error ?? 'Harvest'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Harvesting {earnedAmount?.toSignificant(6)} ALE</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Harvested ALE</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}

interface EarnBNBStakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  earnedAmount : TokenAmount | undefined
}


export function EarnBNBClaimRewardModal({ isOpen, onDismiss, earnedAmount }: EarnBNBStakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const stakingRewardsContract = useAleStakingRewardsContract()

  async function onClaimReward() {
    if (stakingRewardsContract && earnedAmount) {
      setAttempting(true)
      await stakingRewardsContract
        .getRewardNative({ gasLimit: 300000 }) 
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Harvested BNB`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  // if (!stakingInfo?.stakedAmount) {
  //   error = error ?? 'Enter an amount'
  // }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Harvest</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {earnedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {earnedAmount?.toSignificant(6)}
              </TYPE.body>
              <TYPE.body>BNB to harvest</TYPE.body>
            </AutoColumn>
          )}
          <ButtonError disabled={!!error} error={!!error && !!earnedAmount} onClick={onClaimReward}>
            {error ?? 'Harvest'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Harvesting {earnedAmount?.toSignificant(6)} BNB</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Harvested BNB</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}