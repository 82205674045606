import React, { Component } from 'react'

import HopsConner from '../../assets/images/hops-topbox.png';
import Tablebg from '../../assets/images/Table-bg.png';
import TablebgDark from '../../assets/images/Table-bg-dark.png';



import styled, { css } from "styled-components";
import { useIsDarkMode } from 'state/user/hooks';


export default function HomeHeaderBox()  {
  const darkMode = useIsDarkMode()

  return (
            // <TopBoxContainer>
            // <FeatureBox>
                
            //     <FeatureMidText>Farm to Table. Deposit your <br/>cryptocurrency<br/> and yield Food, Beverages and <br/>more with our VAULT.</FeatureMidText>
                
            // </FeatureBox>
            // <HopsImg src={HopsConner}></HopsImg>
            // </TopBoxContainer>
          <PageBox>
            <Container background={darkMode ? TablebgDark : Tablebg}>
              <FeatureMidText>
                <MidBox>Farm to Table. Deposit your
                  cryptocurrency </MidBox>
                <MidBox>and yield Food, Beverages and
                  more with our VAULT.</MidBox>

              </FeatureMidText>
              <Overlay><HopsImg src={HopsConner}></HopsImg></Overlay>
            </Container>
          </PageBox>
        )
    
}

const PageBox = styled.div`

width: 100%;
min-height: 250px;
height:100%;

@media (max-width: 360px) {
 
  min-height: 100px;
    
  }

  
`;
const Container = styled.div<{ background: string }>`
background-color: ${({ theme }) => theme.panelBG};
position: relative;
height:100%;
min-height:190px;
z-index: 1;

background-image: url(${({ background }) => background}); 
  background-repeat: no-repeat;
  
  
  background-position:bottom center;
  background-size: auto;


border-radius: 10px;
text-align:left;
padding-left:50px;
padding-top:50px;
padding-bottom:50px;
box-shadow: 0px 1px 1px #00000040;

@media (max-width: 360px) {
 
background-size: 90%;
min-height:100px;

padding-left:10px;
padding-top:10px;
  
}

@media (max-width: 414px) {
  
background-size: 120%;


  
}

@media (max-width: 500px) {
 
  background-size: 90%;
  min-height:100px;
  
  padding-left:10px;
  padding-top:10px;
    
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding-left:20px;
  padding-top:10px;
  `}; 

  
`;

const Box = styled.div`
width: 100%;
height: 100%;
position: absolute;
top: 0;
eft: 0;
        
background:#ffffff;
  
`;

const Overlay = styled.div`
position: absolute;
right: -220px;
bottom: -15px;
z-index: 2;
 
${({ theme }) => theme.mediaWidth.upToSmall`
width: 200px;
right: -70px;
bottom: -30px;
`};    
  
`;

// @media (max-width: 414px) {
//   width:50%;
//   right: -30px;
// bottom: -70px;
  
// }

// @media (max-width: 500px) {
//   width:50%;
//   right: -30px;
// bottom: -70px;
  
// }


const TopBoxContainer = styled.div`
position: relative;
  
`;

const FeatureBox = styled.div`
  display: flex;
  flex-direction: column;
  font-weight:bold;
  
  min-width:300px;
  min-height:200px;
 
  
  // background-repeat: no-repeat;
  
  
  background-position:95% 50%;
  background-size: 15%;

  @media (max-width: 600px) {
    
    width: 100%;
    margin-top:20px;
  }

  @media (max-width: 360px) {
    
    width: 100%;
    margin-top:20px;
  }

  @media (max-width: 414px) {
    width: 100%;
    margin-top:20px;
    
  }

  background-color:#FFFFFF;

  
  
color:#47912c;
box-shadow: 0px 1px 1px #00000040;
border-radius: 5px;

text-align:left;
margin-left:10px;
margin-right:10px;
padding-bottom:20px;
padding-left:20px;
  
`;

const HopsImg = styled.img`

        width:45%;

`;

const FeatureMidText = styled.div`
  display: flex;
  flex-direction: column;
  font-weight:bold;
  font-size:36px;
  
  color:#347af0;
  line-height:40px;
  @media (max-width: 360px) {
    font-size:24px;
    
  }

  @media (max-width: 414px) {
    font-size:24px;
    
  }

  @media (max-width: 500px) {
    font-size:24px;
    
  }
    
`;

const MidBox = styled.div`
color: ${({ theme }) => theme.panelText2};
width:70%;
z-index: 5;
@media (max-width: 360px) {
  width:90%;
  
}
  
}
  
`;

const OrButton = styled.button`
background-color:#eabb4e;
color:#ffffff;
font-weight:700;
font-size:14px;
border-radius: 5px;
border:0px;

font-family:'Titillium Web';
padding-left:5px;
padding-right:5px;
padding-bottom:4px;
margin-top:10px;

&:hover {
  
  box-shadow: 0 0 0 3pt #195BCB;
  cursor: pointer;
}

@media (max-width: 414px) {
  font-size:16px;
  
min-width:120px;
  
}
`
  ;

const cssstyle = `
.HomeHeaderBox {
  background-color:#ffffff;
  width:100%;
color:#0B2553;
box-shadow: 0px 1px 1px #00000040;
border-radius: 5px;

justify-items: center;
margin-left:10px;
margin-right:10px;
padding-bottom:10px;
  
}

@media only screen and (max-width: 360px) {
  .container {
    margin: 0 auto;
    padding: 0px 40px 40px 40px;
    width: 280px;
    
  }
  .PartnerPhoto{ 
    width: 200%;
  }
  
}

@media only screen and (max-width: 375px) {
  .container {
    margin: 0 auto;
    padding: 0px 40px 40px 40px;
    width: 280px;
    
  }
  .PartnerPhoto{ 
    width: 200%;
  }
  
}



`


  

  ;