import { Checkbox, createStyles, FormControl, FormControlLabel, FormGroup, InputLabel, makeStyles, MenuItem, Select, Theme } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { PairVaultCard } from 'components/VaultCard/PairVaultCard'
import VaultHeader from 'components/VaultHeader'
import React, { useState } from 'react'
import styled from 'styled-components'
import { getVaultAssets, VAULT_GROUP_DESCRIPTION, VAULT_LISTS } from 'utils/vault'
import { AutoColumn } from '../../components/Column'
import { DataCard } from '../../components/earn/styled'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'


const PageWrapper = styled(AutoColumn)`
  max-width: 1024px;
  padding: 15px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0px;
  `};    
  width: 100%;
`

const RoundShadowCard = styled(DataCard)`

  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;
  background: ${({ theme }) => theme.itemBG};
  overflow: hidden;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const badgeLight = "https://badge.inspex.co/aleswap/light.svg"
const badgeDark = "https://badge.inspex.co/aleswap/dark.svg"




export const LogoBox = styled.div`
  width: 100%;
  height: 185px;
  display: flex;
  align-items: center  
  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 100px;
  `};  
`

export const CardBGImage = styled.span<{ url: string }>`
  background: url(${({ url }) => url});
  width: 1000px;
  height: 600px;
  position: absolute;
  border-radius: 12px;
  user-select: none;
`

const BoldWhiteText = styled.span`
  font-size:20px;
  font-weight:bold;
  color:#ffffff;
`

export const InspexBadge = styled.img`
width: 150%;
hieght: 150%;

${({ theme }) => theme.mediaWidth.upToSmall`
width: 100%;
hieght: 100%;

`};  
`

const StyledCheckbox = styled(Checkbox)`
color: ${({ theme }) => theme.tokenText};
&.Mui-checked {
  color: ${({ theme }) => theme.tokenText};
}
`

const StyledInputLabel = styled(InputLabel)`
color: ${({ theme }) => theme.subText};
`

const StyledSelect = styled(Select)`
color: ${({ theme }) => theme.tokenText};

& .MuiSvgIcon-root {
  color: ${({ theme }) => theme.tokenText};
}

&.MuiInput-underline:before {
  border-bottom: 1px solid ${({ theme }) => theme.tokenText};
}

&.MuiInput-underline:hover:before {
  border-bottom: 2px solid lightblue;
}

&.MuiInput-underline:after {
  border-bottom: 2px solid red;
}

`


const StyledAutoCompleteTextField = styled(TextField)`
color: ${({ theme }) => theme.tokenText};
& .MuiInputLabel-root {
  color: ${({ theme }) => theme.subText};
}
& .MuiInputBase-input {
  color: ${({ theme }) => theme.tokenText};
}

& .MuiSvgIcon-root {
  color: ${({ theme }) => theme.tokenText};
}

& .MuiInput-underline:before {
  border-bottom: 1px solid ${({ theme }) => theme.tokenText};
}

& .MuiInput-underline:hover:before {
  border-bottom: 2px solid lightblue;
}

& .MuiInput-underline:after {
  border-bottom: 2px solid red;
}


`

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center  
  flex-direction: row;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};  
`

export const FilterWrapperTop = styled.div`
  width: 66%;
  display: flex;
  align-items: center
  flex-direction: row;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};  
`

export const FilterWrapperBottom = styled.div`
  width: 34%;
  display: flex;
  align-items: center
  flex-direction: row;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
  `};  
`


interface VaultGroupProps {
  group: string
  asset : string | undefined
  deposited : boolean
  boost : boolean
  vaultType : string
  showNoResult? : boolean
}


function VaultGroup({group, asset, deposited, boost, vaultType, showNoResult} : VaultGroupProps) {

  let vaults = VAULT_LISTS.filter((vault) => vault.farm.name === group)
  if (asset && asset !== 'All') 
    vaults = vaults.filter((vault) => vault.name.indexOf(asset) > -1 )

  if (boost) 
    vaults = vaults.filter((vault) => vault.alePoolId !== -1 )    

  if (vaultType === 'Stable')
    vaults = vaults.filter((vault) => vault.vaultType && vault.vaultType === "Stable" )    

  return (
    vaults.length > 0 ? (
    <>
      <TitleRow padding={'0'}>
        <BoldWhiteText style={{ marginTop: '0.5rem', justifySelf: 'flex-start' }}>
          {group} {VAULT_GROUP_DESCRIPTION[group] !== '' ? ` - ${VAULT_GROUP_DESCRIPTION[group]}` : ''}
        </BoldWhiteText>
      </TitleRow>
      {
        vaults.map((vault) =>
          <PairVaultCard vault={vault} showDepositedOnly={deposited}></PairVaultCard>
        )
      }
    </>
    ) : (
      showNoResult ? (
      <TitleRow padding={'0'}>
        <BoldWhiteText style={{ marginTop: '0.5rem', justifySelf: 'flex-start' }}>
          No Results
        </BoldWhiteText>
      </TitleRow>      
        ) : (
      <></>
      )
    )
  )    
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);


export default function Vault() {
  // const darkMode = useIsDarkMode()
  const classes = useStyles();

  let vault_group = Object.keys(VAULT_GROUP_DESCRIPTION).map((vault) => vault)
  // vault_group = vault_group.filter((v, i, a) => a.indexOf(v) === i);

  const [filterAsset, setAsset] = useState<string | undefined>("All")
  const [filterPlatform, setFilterPlatform] = useState("All")
  const [filterType, setFilterType] = useState("All")
  const [stateCheckbox, setStateCheckBox] = React.useState({
    deposited: false,
    boost: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCheckBox({ ...stateCheckbox, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        {/* <RoundShadowCard>
          <AutoRow>


            <CardBGImage url={darkMode ? bgImageDark : bgImage} />

            <CardSection>
              <AutoColumn gap="md">
                <LogoBox><CardLogo src={darkMode ? logodark : logo} /></LogoBox>
              </AutoColumn>
            </CardSection>

          </AutoRow>
        </RoundShadowCard> */}

        <AutoRow><VaultHeader></VaultHeader></AutoRow>
        <AutoRow marginTop="40px" justify="center">
          <InspexBadge src={badgeDark} />
        </AutoRow>
        <AutoRow marginTop="20px">
          <RoundShadowCard>
            <AutoColumn justify="center">
              <AutoRow width="95%" marginTop="10px">
                <RowBetween>
                  <AutoRow>
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          control={
                            <StyledCheckbox
                              checked={stateCheckbox.deposited}
                              onChange={handleChange}
                              name="deposited"
                            />
                          }
                          label="Deposited Vaults"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={
                            <StyledCheckbox
                              checked={stateCheckbox.boost}
                              onChange={handleChange}
                              name="boost"
                            />
                          }
                          label="Boost"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </AutoRow>
                  <AutoRow justify="right">
                    {/* <Button>Clear Filter</Button> */}
                  </AutoRow>
                </RowBetween>
              </AutoRow>
              <RowBetween width="95%" marginBottom="10px">
                <FilterWrapper>
                  <FilterWrapperTop>
                    <FormControl className={classes.formControl} style={{ width: "50%" }}>
                      <StyledInputLabel shrink id="platform-label">
                        Platform
                      </StyledInputLabel>
                      <StyledSelect
                        onChange={(e) => {
                          setFilterPlatform(e.target.value as string);
                        }}
                        className="platform-select"
                        aria-label="Platform"
                        value={filterPlatform}
                      >
                        <MenuItem color="inherit" value="All">All</MenuItem>
                        {
                          vault_group.map((group) =>
                            <MenuItem color="inherit" value={group}>{group}</MenuItem>
                          )
                        }
                      </StyledSelect>
                    </FormControl>
                    <FormControl className={classes.formControl} style={{ width: "50%" }}>
                      <StyledInputLabel shrink id="vault-type-label">
                        Vault type
                      </StyledInputLabel>
                      <StyledSelect
                        onChange={(e) => {
                          setFilterType(e.target.value as string);
                        }}
                        className="type-select"
                        aria-label="Vault type"
                        value={filterType}

                      >
                        <MenuItem color="inherit" value="All">All</MenuItem>
                        <MenuItem color="inherit" value="Stable">Stable LPs</MenuItem>

                      </StyledSelect>
                    </FormControl>
                  </FilterWrapperTop>
                  <FilterWrapperBottom>
                    <FormControl className={classes.formControl} style={{ width: "100%" }}>
                      <Autocomplete
                        id="combo-box-asset"
                        disableClearable={true}
                        value={filterAsset}
                        onChange={(e, value) => {
                          setAsset(value);
                        }}
                        options={["All", ...getVaultAssets()]}
                        getOptionLabel={(option) => option}
                        style={{ width: "100%" }}
                        renderInput={(params) => <StyledAutoCompleteTextField {...params} label="Asset" variant="standard" />}
                      />
                    </FormControl>
                  </FilterWrapperBottom>
                </FilterWrapper>
              </RowBetween>
            </AutoColumn>
          </RoundShadowCard>
        </AutoRow>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
            </TitleRow>

            <>
              {
                filterPlatform !== 'All' ? (
                  <VaultGroup group={filterPlatform} asset={filterAsset} showNoResult={true} deposited={stateCheckbox.deposited} boost={stateCheckbox.boost} vaultType={filterType}/>
                ) : (
                  vault_group.map((group) => {
                    return (
                      <VaultGroup group={group} asset={filterAsset} deposited={stateCheckbox.deposited} boost={stateCheckbox.boost} vaultType={filterType}/>
                    )
                  })
                )
              }
            </>

          </AutoColumn>
        </AutoColumn>

      </PageWrapper>
    </>
  )
}
