import { Pair, Token  } from "@uniswap/sdk"
import { getCreate2Address } from "ethers/lib/utils"
import { keccak256, pack } from '@ethersproject/solidity';
import { DAI, USDC, WBTC, ALE,BUSD_TEST, BUSD, USDT, UST, ETH, BTCB, CRAFT, JDI, ZFARM, WETH  } from '../constants'


const PANCAKE_FACTORY = "0xca143ce32fe78f1f7019d7d551a6402fc5350c73"
const FOODCOURT_FACTORY = "0xc801c7980c8c7900bc898b1f38392b235ff64097"
const APESWAP_FACTORY = "0x0841bd0b734e4f5853f0dd8d7ea041c241fb0da6"
const WARDEN_FACTORY = "0x3657952d7ba5a0a4799809b5b6fdff9ec5b46293"
const TWINDEX_FACTORY = "0x4e66fda7820c53c1a2f601f84918c375205eac3e"
const DEFINIX_FACTORY = "0x43ebb0cb9bd53a3ed928dd662095ace1cef92d19"
const TUKTUK_FACTORY = "0xd6faa863be0795d5aab2ba7ef44e3e7521fa62ae"
const BISWAP_FACTORY = "0x858E3312ed3A876947EA49d572A7C42DE08af7EE"


interface InitCode {
    [key: string]: string 
  }

const initCode : InitCode = {
    [PANCAKE_FACTORY] : "0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5",
    [FOODCOURT_FACTORY] : "0xf1d77ba4cb681341af63066d44297a59a8962ec4fe422a20eca5077dca88cef9",
    [APESWAP_FACTORY] : "0xf4ccce374816856d11f00e4069e7cada164065686fbef53c6167a63ec2fd8c5b",
    [WARDEN_FACTORY] : "0xfbbcb3ce9944dd53a7ef447076d0e4e4811911365339bab292e4733d5b37a120",
    [TWINDEX_FACTORY] : "0x652b5a4fa6b1efd55c9821e56279c871f9999588a5472d22ab78b8ec61e2521e",
    [DEFINIX_FACTORY] : "0x898b8e59fbd4e8d6538b7d59d469440d56e24a77616ec248916ca9b36712dfe2",
    [TUKTUK_FACTORY] : "0x54d4b1e4b842bc29e62344879fa140236148005d30118e3fd1672b3dcab304f4",
    [BISWAP_FACTORY] : "0xfea293c909d87cd4153593f077b76bb7e94340200f4ee84211ae8e4f9bd7ffdf",
    
}

// BISWAP Router 0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8

const tokenInfo = [
    {
      symbol : "ZFARM",
      address: "0x42d1B21EaBE04D308148eA9Ab90Be674b64b4eef",
      aggregate: true,
      aggregatePair: [WETH[56]],
      fee: 30,
      chainId : 56,
      factory : [PANCAKE_FACTORY]
    },  
    {
        symbol : "ZMN",
        address: "0xFCb8a4B1a0B645e08064e05B98E9cc6f48D2aa57",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },      
    {
        symbol : "FIRO",
        address: "0xd5d0322b6bab6a762c79f8c81a0b674778e13aed",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },          
    {
        symbol : "COUPON",
        address: "0x084bb94e93891D74579B54Ab63ED24C4ef9cd5Ef",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [FOODCOURT_FACTORY]
    },     
    {
        symbol : "wMMP",
        address: "0x422d0A431D8fb752e3697e90BA04b3324Ea0Cb4a",
        aggregate: true,
        aggregatePair: [BUSD],
        fee: 30,
        chainId : 56,
        factory : [FOODCOURT_FACTORY]
    },     
    {
        symbol : "SIX",
        address: "0x070a9867Ea49CE7AFc4505817204860e823489fE",
        aggregate: true,
        aggregatePair: [BUSD],
        fee: 30,
        chainId : 56,
        factory : [DEFINIX_FACTORY]
    },     
    {
        symbol : "FINIX",
        address: "0x0F02b1F5AF54E04Fb6dd6550f009aC2429C4e30D",
        aggregate: true,
        aggregatePair: [BUSD],
        fee: 30,
        chainId : 56,
        factory : [DEFINIX_FACTORY]
    },     
    {
        symbol : "TWIN",
        address: "0x3806aae953a3a873D02595f76C7698a57d4C7A57",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [TWINDEX_FACTORY]
    },  
    {
        symbol : "DOP",
        address: "0x844FA82f1E54824655470970F7004Dd90546bB28",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [TWINDEX_FACTORY]
    },     
    {
        symbol : "DOPX",
        address: "0x802A183ac9F6b082716DFeE55432ed0C04ACB49a",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [TWINDEX_FACTORY]
    },        
    {
        symbol : "TWX",
        address: "0x41171D5770C4c68686d1aF042Ada88a45B02f82b",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [TWINDEX_FACTORY]
    },       
    {
        symbol : "SCZ",
        address: "0x39f1014a88c8ec087ceDF1BFc7064d24f507b894",
        aggregate: true,
        aggregatePair: [BUSD],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },      
    {
        symbol : "TUK",
        address: "0xe363096E780FB8675966b3FFCa88BEDD62b838fA",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [TUKTUK_FACTORY]
    },          
    // {
    //     symbol : "BUSD",
    //     address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    //     aggregate: true,
    //     aggregatePair: [WETH[56]],
    //     fee: 30,
    //     factory : [PANCAKE_FACTORY]
    // },       
    {
        symbol : "USDC",
        address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },          
    {
        symbol : "CAKE",
        address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },            
    {
        symbol : "DAI",
        address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },           
    {
        symbol : "BTCB",
        address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },     
    {
        symbol : "ETH",
        address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },   
    {
        symbol : "BSW",
        address: "0x965F527D9159dCe6288a2219DB51fc6Eef120dD1",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [BISWAP_FACTORY]
    },               
    // {
    //     "name": "Biswap",
    //     "symbol": "BSW",
    //     "address": "0x965F527D9159dCe6288a2219DB51fc6Eef120dD1",
    //     "chainId": 56,
    //     "decimals": 18,
    //     "logoURI": "https://bscscan.com/token/images/biswap_32.png"
    //   },    
    {
        symbol : "JDI",
        address: "0x0491648C910ad2c1aFaab733faF71D30313Df7FC",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [APESWAP_FACTORY]
    },      
    // {
    //     symbol : "DINOP",
    //     address: "0xea90DC6F64d18771Ca1dac8098526a9082265B42",
    //     aggregate: true,
    //     aggregatePair: [WETH[56]],
    //     fee: 30,
    //     chainId : 56,
    //     factory : [APESWAP_FACTORY]
    // },      
    {
        symbol : "INFTEE",
        address: "0xc350caa89eb963d5d6b964324a0a7736d8d65533",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    }, 
    {
        symbol : "WAD",
        address: "0x0fEAdcC3824E7F3c12f40E324a60c23cA51627fc",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [WARDEN_FACTORY]
    }, 
    {
        symbol : "NAFT",
        address: "0xD7730681B1DC8f6F969166B29D8A5EA8568616a3",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    }, 
    {
        symbol : "ThaiRB",
        address: "0x7708343575E0e57168C4Acd45A0f296939DE7415",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },     
    {
        symbol : "LOREM",
        address: "0x401D9c80B8F349c462C1f85F16f0E7158216d98B",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [FOODCOURT_FACTORY]
    },        
    {
        symbol : "ADA",
        address: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },
    {
        symbol : "DOT",
        address: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },
    {
        symbol : "XRP",
        address: "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },
    {
        symbol : "ALPACA",
        address: "0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },    
    {
        symbol : "MIR",
        address: "0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9",
        aggregate: true,
        aggregatePair: [UST],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },          
    {
        symbol : "DOGE",
        address: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
        aggregate: true,
        aggregatePair: [BUSD],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },        
    {
        symbol : "KUSD",
        address: "0x940Ff63e82d15fb47371BFE5a4ed7D7D183dE1A5",
        aggregate: true,
        aggregatePair: [WETH[56]],
        fee: 30,
        chainId : 56,
        factory : [PANCAKE_FACTORY]
    },         
  ]

// DOGE 0xbA2aE424d960c26247Dd6c32edC70B295c744C43
// BANANA 0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95

export function getAggregatePairAddress(tokenA: Token, tokenB: Token, chainId?: number) {
    const factory = getAggregateTokenFactory(tokenA.symbol, chainId)
    if (factory === "") return undefined
    
    var tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

    return getCreate2Address(factory, keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]), initCode[factory])
}

export function getPairAddressWithInitCode(tokenA: Token, tokenB: Token,factory : string, _initCode : string) {
    
    var tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

    return getCreate2Address(factory, keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]), _initCode)
}

export function getAggregatePairToken(symbol: string | undefined) : Token {
    return tokenInfo.find(token => token.symbol === symbol)?.aggregatePair[0] ?? WETH[56]
}

export function getAggregateTokenFactory(symbol: string | undefined, chainId?: number) {

    if (!chainId) chainId = 56
    return tokenInfo.find(token => token.symbol === symbol && token.chainId === chainId)?.factory[0] ?? ""
}

export function getAggregateTokenByAddressFactory(address: string | undefined) {
    return tokenInfo.find(token => token.address === address)?.factory[0] ?? ""
}

// export function getAggregatePairFactory(pair : Pair) {
//     const pairAddress = getAggregatePairAddress(pair.token0,pair.token1)
//     tokenInfo.find(token => token.symbol === symbol)?.factory[0] ?? ""
// }

export function getAggregateTokenFee(symbol: string | undefined) {
    return tokenInfo.find(token => token.symbol === symbol)?.fee ?? 0
}

export function isAggregateToken(symbol: string | undefined, chainId?: number) {
    if (!chainId) chainId = 56
    return tokenInfo.find(token => token.symbol === symbol && token.chainId === chainId)?.aggregate ?? false
}

export function isAggreatePair(pair: Pair) : boolean {
    return isAggregateToken(pair.token0.symbol) || isAggregateToken(pair.token1.symbol)
}

//   export function getPredefinedPairAddress(tokenA: Token, tokenB: Token): string {
//     if (tokenA.symbol === "COUPON" && tokenB.symbol === "WBNB") return "0x9d368ba7176f77b2c0abe129eeb1a47b58cdde7e"
//     if (tokenA.symbol === "ZMN" && tokenB.symbol === "WBNB") return "0xCd50c95F1caCCBd949Cd28E51d899f431411047B"
//     // if (tokenA.symbol === "ZFARM" && tokenB.symbol === "WBNB") return "0xCd50c95F1caCCBd949Cd28E51d899f431411047B"
    
//     return ""
//   }