import React from 'react'
import styled from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow } from '../../components/Row'
import RedeemPage from './page'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

export default function Redeem() {

  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        <AutoRow>
          <RedeemPage />
        </AutoRow>
      </PageWrapper>
    </>
  )
}
