import { ChainId, Currency, currencyEquals, JSBI, Price } from '@uniswap/sdk'
import { useMemo } from 'react'
//import { ALE, BUSD, BUSD_TEST, USDC } from '../constants'
import { ALE, BUSD, BUSD_TEST, CRAFT, USDC, WETH } from '../constants'
import { PairState, usePairs } from '../data/Reserves'
import { useActiveWeb3React } from '../hooks'
import { wrappedCurrency } from './wrappedCurrency'



/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
// export  function useUSDCPrice(currency?: Currency): Price | undefined {
//   const { chainId } = useActiveWeb3React()
//   const wrapped = wrappedCurrency(currency, chainId)
//   const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
//     () => [
//       [
//         chainId && wrapped && currencyEquals(WETH[chainId], wrapped) ? undefined : currency,
//         chainId ? WETH[chainId] : undefined
//       ],
//       [wrapped?.equals(USDC) ? undefined : wrapped, chainId === ChainId.MAINNET ? USDC : undefined],
//       [chainId ? WETH[chainId] : undefined, chainId === ChainId.MAINNET ? USDC : undefined]
//     ],
//     [chainId, currency, wrapped]
//   )
//   const [[ethPairState, ethPair], [usdcPairState, usdcPair], [usdcEthPairState, usdcEthPair]] = usePairs(tokenPairs)

//   return useMemo(() => {
//     if (!currency || !wrapped || !chainId) {
//       return undefined
//     }
//     // handle weth/eth
//     if (wrapped.equals(WETH[chainId])) {
//       if (usdcPair) {
//         const price = usdcPair.priceOf(WETH[chainId])
//         return new Price(currency, USDC, price.denominator, price.numerator)
//       } else {
//         return undefined
//       }
//     }
//     // handle usdc
//     if (wrapped.equals(USDC)) {
//       return new Price(USDC, USDC, '1', '1')
//     }

//     const ethPairETHAmount = ethPair?.reserveOf(WETH[chainId])
//     const ethPairETHUSDCValue: JSBI =
//       ethPairETHAmount && usdcEthPair ? usdcEthPair.priceOf(WETH[chainId]).quote(ethPairETHAmount).raw : JSBI.BigInt(0)

//     // all other tokens
//     // first try the usdc pair
//     if (usdcPairState === PairState.EXISTS && usdcPair && usdcPair.reserveOf(USDC).greaterThan(ethPairETHUSDCValue)) {
//       const price = usdcPair.priceOf(wrapped)
//       return new Price(currency, USDC, price.denominator, price.numerator)
//     }
//     if (ethPairState === PairState.EXISTS && ethPair && usdcEthPairState === PairState.EXISTS && usdcEthPair) {
//       if (usdcEthPair.reserveOf(USDC).greaterThan('0') && ethPair.reserveOf(WETH[chainId]).greaterThan('0')) {
//         const ethUsdcPrice = usdcEthPair.priceOf(USDC)
//         const currencyEthPrice = ethPair.priceOf(WETH[chainId])
//         const usdcPrice = ethUsdcPrice.multiply(currencyEthPrice).invert()
//         return new Price(currency, USDC, usdcPrice.denominator, usdcPrice.numerator)
//       }
//     }
//     return undefined
//   }, [chainId, currency, ethPair, ethPairState, usdcEthPair, usdcEthPairState, usdcPair, usdcPairState, wrapped])
// }

export default function useBUSDPrice(currency?: Currency): Price | undefined {
  const { chainId } = useActiveWeb3React()
  const wrapped = wrappedCurrency(currency, chainId)
  const busd_token = chainId && chainId === ChainId.BSCTEST ? BUSD_TEST : BUSD
  const craft_token = CRAFT

  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [
        chainId && wrapped && currencyEquals(WETH[chainId], wrapped) ? undefined : currency,
        chainId ? WETH[chainId] : undefined
      ],
      // [wrapped?.equals(BUSD_TEST) ? undefined : wrapped, chainId === ChainId.MAINNET ? BUSD_TEST : undefined],
      // [chainId ? WETH[chainId] : undefined, chainId === ChainId.MAINNET ? BUSD_TEST : undefined]
      [wrapped?.equals(busd_token) ? undefined : wrapped, chainId === ChainId.MAINNET ? busd_token : undefined],
      [wrapped?.equals(craft_token) ? undefined : wrapped, chainId === ChainId.MAINNET ? craft_token : undefined],
      [chainId ? WETH[chainId] : undefined, chainId === ChainId.MAINNET ? busd_token : undefined]

    ],
    [busd_token, chainId, craft_token, currency, wrapped]
  )
  const [[bnbPairState, bnbPair], [busdPairState, busdPair], [craftPairState, craftPair],  [busdBnbPairState, busdBnbPair]] = usePairs(tokenPairs)

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined
    }
    // handle weth/eth
    if (wrapped.equals(WETH[chainId])) {
      if (busdPair) {
        const price = busdPair.priceOf(WETH[chainId])
        return new Price(currency, busd_token, price.denominator, price.numerator)
      } else {
        return undefined
      }
    }
    // handle busd
    if (wrapped.equals(busd_token)) {
      return new Price(busd_token, busd_token, '1', '1')
    }

    const ethPairETHAmount = bnbPair?.reserveOf(WETH[chainId])
    const ethPairETHUSDCValue: JSBI =
      ethPairETHAmount && busdBnbPair ? busdBnbPair.priceOf(WETH[chainId]).quote(ethPairETHAmount).raw : JSBI.BigInt(0)

    // all other tokens
    // first try the busd pair
    if (busdPairState === PairState.EXISTS && busdPair && busdPair.reserveOf(busd_token).greaterThan(ethPairETHUSDCValue)) {
      const price = busdPair.priceOf(wrapped)
      return new Price(currency, busd_token, price.denominator, price.numerator)
    }

    // if (wrapped.equals(CRAFT)) {
    //   return new Price(CRAFT, busd_token, '1', '1')
    // }

    if (craftPairState === PairState.EXISTS && craftPair ) {
      const price = craftPair.priceOf(wrapped)
      return new Price(currency, busd_token, price.denominator, price.numerator)
    }
    
    if (bnbPairState === PairState.EXISTS && bnbPair && busdBnbPairState === PairState.EXISTS && busdBnbPair) {
      if (busdBnbPair.reserveOf(busd_token).greaterThan('0') && bnbPair.reserveOf(WETH[chainId]).greaterThan('0')) {
        const ethUsdcPrice = busdBnbPair.priceOf(busd_token)
        const currencyEthPrice = bnbPair.priceOf(WETH[chainId])
        const usdcPrice = ethUsdcPrice.multiply(currencyEthPrice).invert()
        return new Price(currency, busd_token, usdcPrice.denominator, usdcPrice.numerator)
      }
    }
    return undefined
  }, [chainId, currency, bnbPair, bnbPairState, busdBnbPair, busdBnbPairState, busdPair, busdPairState, wrapped])
}

// export function useALEPrice(): string | undefined {
//   const { data, error } = useSWR('https://api.aleswap.finance/info')

//   if (!data) return undefined
//   return parseFloat(data.alePrice).toFixed(5)

//   // const { chainId } = useActiveWeb3React()
//   // const price = useBUSDPrice(chainId ? ALE[chainId] : undefined)
//   // if (price)
//   // {
//   //   return price.toFixed(5)
//   // }
//   // return undefined
// }
