import { ChainId, CurrencyAmount, JSBI, Pair, Token, TokenAmount } from '@uniswap/sdk'
import { useBrewmasterContract, useVaultContract } from 'hooks/useContract'
import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'
import { useMemo } from 'react'
import { useFarmsData } from 'utils/aleSwapAPI'
import { Vault, VAULT_LISTS } from 'utils/vault'
import { ALE, BTCB, BUSD, BUSD_TEST, CRAFT, ETH, JDI, USDC, USDT, UST, WETH, ZFARM } from '../../constants'
import ERC20 from '../../constants/abis/erc20'
import { useActiveWeb3React } from '../../hooks'
import { useMultipleContractSingleData, useSingleContractMultipleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'

export const POOLS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    lpAddress: string
    poolId: number
  }[]
} = {
  [ChainId.MAINNET]: [
    {
      tokens: [ALE[ChainId.MAINNET],WETH[ChainId.MAINNET]],
      lpAddress: "",
      poolId : 1
    },
    {
      tokens: [ALE[ChainId.MAINNET], BUSD],
      lpAddress: "",
      poolId : 3
    },    
    {
      tokens: [WETH[ChainId.MAINNET], BUSD],
      lpAddress: "",
      poolId : 4
    },            
    {
      tokens: [WETH[ChainId.MAINNET], BTCB],
      lpAddress: "",
      poolId : 5
    },    
    {
      tokens: [WETH[ChainId.MAINNET], ETH],
      lpAddress: "",
      poolId : 6
    },       
    {
      tokens: [USDT, BUSD],
      lpAddress: "",
      poolId : 7
    },    
    {
      tokens: [UST, BUSD],
      lpAddress: "",
      poolId : 8
    },       
    {
      tokens: [CRAFT, BUSD],
      lpAddress: "",
      poolId : 9
    },       
    {
      tokens: [ALE[ChainId.MAINNET],CRAFT],
      lpAddress: "",
      poolId : 11
    },
    {
      tokens: [CRAFT, UST],
      lpAddress: "",
      poolId : 12
    },    
    {
      tokens: [CRAFT, USDT],
      lpAddress: "",
      poolId : 13
    },    
    {
      tokens: [CRAFT, USDC],
      lpAddress: "",
      poolId : 14
    },            
    {
      tokens: [ETH, CRAFT],
      lpAddress: "",
      poolId : 15
    },    
    {
      tokens: [WETH[ChainId.MAINNET], CRAFT],
      lpAddress: "",
      poolId : 16
    },       
    {
      tokens: [BTCB, CRAFT],
      lpAddress: "",
      poolId : 17
    },        
    {
      tokens: [ALE[ChainId.MAINNET], JDI],
      lpAddress: "",
      poolId : 18
    },     
    {
      tokens: [ALE[ChainId.MAINNET], ZFARM],
      lpAddress: "",
      poolId : 19
    }, 
    // {
    //   tokens: [WETH[ChainId.MAINNET], USDT],
    //   lpAddress: "",
    //   poolId : 20
    // },            
    
    // {
    //   tokens: [WETH[ChainId.BSCTEST], USDT_TEST],
    //   stakingRewardAddress: '0xCA35e32e7926b96A9988f61d510E038108d8068e',
    //   poolId : 100
    // }
  ],
  [ChainId.BSCTEST]: [

    {
      tokens: [ALE[ChainId.BSCTEST],WETH[ChainId.BSCTEST]],
      lpAddress: "",
      poolId : 1
    },
    {
      tokens: [ALE[ChainId.BSCTEST], BUSD_TEST],
      lpAddress: "",
      poolId : 2
    },    
    {
      tokens: [WETH[ChainId.BSCTEST], BUSD_TEST],
      lpAddress: "",
      poolId : 3
    },        
    // {
    //   tokens: [WETH[ChainId.BSCTEST], USDT_TEST],
    //   stakingRewardAddress: '0xCA35e32e7926b96A9988f61d510E038108d8068e',
    //   poolId : 100
    // }
  ]  
}

// export const VAULT_POOLS_INFO: {
//     vaultLPAddress: string
//     poolId: number
// } [] = 
//   [
//     {
//       vaultLPAddress: "",
//       poolId : 1
//     },
//   ]

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // if pool is active
  active: boolean
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount
}

export interface AleStakingInfo {
  // the address of the reward contract
  poolId: number
  // the tokens involved in this pair
  tokens: [Token, Token]
  // tokens: Token[]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount

  stakedTokenAddress: string
}

export function useAleStakingInfo(pairToFilterBy?: Pair | null): AleStakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? POOLS_INFO[chainId]?.filter(poolInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(poolInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(poolInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const lpAddresses = useMemo(() => info.map(({ lpAddress }) => lpAddress), [info])

  const brewmaster = useBrewmasterContract()
  const poolIds = useMemo(() => info.map(({ poolId }) => poolId), [info])

  // const accountArg = useMemo(() => [account ?? undefined], [account])
  const poolArg = useMemo(() => info.map(({ poolId }) => [poolId]), [info])
  const poolAccountArg = useMemo(() => info.map(({ poolId }) => [poolId,account ?? undefined]), [info,account])

  // get all the info from the staking rewards contracts
  const poolBalances = useSingleContractMultipleData(brewmaster, 'userInfo', poolAccountArg) 
  const pendingAles = useSingleContractMultipleData(brewmaster, 'pendingAle', poolAccountArg)
  const totalSupplies = useMultipleContractSingleData(lpAddresses, ERC20 , 'totalSupply')

  return useMemo(() => {
    if (!chainId || !brewmaster) return []

    return poolIds.reduce<AleStakingInfo[]>((memo, poolId, index) => {
      // these two are dependent on account
      // const balanceState = balances[index]
      // const earnedAmountState = earnedAmounts[index]
      const pendingAleState = pendingAles[index]
      const poolBalanceState = poolBalances[index]
      const totalSupplyState = totalSupplies[index]
      // const allocPointState = allocPoints[index]

      // these get fetched regardless of account
      // const totalSupplyState = totalSupplies[index]
      // const rewardRateState = rewardRates[index]
      // const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        // !balanceState?.loading &&
        // !earnedAmountState?.loading &&

        // !balanceState?.loading &&
        !poolBalanceState?.loading &&
        !pendingAleState?.loading 
        // !totalSupplyState?.loading 
        // !allocPointState?.loading 

        // always need these

        // pendingAleState &&
        // !pendingAleState?.loading &&        
        // totalSupplyState &&
        // !totalSupplyState.loading &&
        // rewardRateState &&
        // !rewardRateState.loading &&
        // periodFinishState &&
        // !periodFinishState.loading
      ) {
        // get the LP token  
        const tokens = info[index].tokens
        const pair = poolId !== 0 ? new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0')) : undefined
        const liquidityToken = !pair ? ALE[chainId] : pair.liquidityToken

        const poolStakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(poolBalanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(totalSupplyState?.result?.[0] ?? 0))     

        memo.push({
          poolId: info[index].poolId,
          tokens: info[index].tokens,
          earnedAmount: new TokenAmount(ALE[56], JSBI.BigInt(pendingAleState?.result?.[0] ?? 0)),
          stakedAmount: poolStakedAmount,
          totalStakedAmount: totalStakedAmount,
          stakedTokenAddress: liquidityToken.address
        })
      }
      return memo
    }, [])
  }, [chainId, brewmaster, poolIds, pendingAles, poolBalances, info])
}



export interface PoolStakingInfo {
  // the address of the reward contract
  poolId: number
  // the tokens involved in this pair
  tokens: [Token, Token]
  // tokens: []
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount

  stakedTokenAddress: string
}

export function useLiquidityPoolStakingInfo(pairToFilterBy?: Pair | null): PoolStakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? POOLS_INFO[chainId]?.filter(poolInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(poolInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(poolInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const lpAddresses = useMemo(() => info.map(({ lpAddress }) => lpAddress), [info])

  const brewmaster = useBrewmasterContract()
  const poolIds = useMemo(() => info.map(({ poolId }) => poolId), [info])

  // const accountArg = useMemo(() => [account ?? undefined], [account])
  const poolArg = useMemo(() => info.map(({ poolId }) => [poolId]), [info])
  const poolAccountArg = useMemo(() => info.map(({ poolId }) => [poolId,account ?? undefined]), [info,account])

  // get all the info from the staking rewards contracts
  const poolBalances = useSingleContractMultipleData(brewmaster, 'userInfo', poolAccountArg) 
  const pendingAles = useSingleContractMultipleData(brewmaster, 'pendingAle', poolAccountArg)
  const totalSupplies = useMultipleContractSingleData(lpAddresses, ERC20 , 'totalSupply')

  return useMemo(() => {
    if (!chainId || !brewmaster) return []

    return poolIds.reduce<PoolStakingInfo[]>((memo, poolId, index) => {
      // these two are dependent on account
      // const balanceState = balances[index]
      // const earnedAmountState = earnedAmounts[index]
      const pendingAleState = pendingAles[index]
      const poolBalanceState = poolBalances[index]
      const totalSupplyState = totalSupplies[index]
      // const allocPointState = allocPoints[index]

      // these get fetched regardless of account
      // const totalSupplyState = totalSupplies[index]
      // const rewardRateState = rewardRates[index]
      // const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        // !balanceState?.loading &&
        // !earnedAmountState?.loading &&

        // !balanceState?.loading &&
        !poolBalanceState?.loading &&
        !pendingAleState?.loading 
        // !totalSupplyState?.loading 
        // !allocPointState?.loading 

        // always need these

        // pendingAleState &&
        // !pendingAleState?.loading &&        
        // totalSupplyState &&
        // !totalSupplyState.loading &&
        // rewardRateState &&
        // !rewardRateState.loading &&
        // periodFinishState &&
        // !periodFinishState.loading
      ) {
        // get the LP token  
        const tokens = info[index].tokens
        const pair = poolId !== 0 ? new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0')) : undefined
        const liquidityToken = !pair ? ALE[chainId] : pair.liquidityToken

        const poolStakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(poolBalanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(totalSupplyState?.result?.[0] ?? 0))     

        memo.push({
          poolId: info[index].poolId,
          tokens: info[index].tokens,
          earnedAmount: new TokenAmount(ALE[56], JSBI.BigInt(pendingAleState?.result?.[0] ?? 0)),
          stakedAmount: poolStakedAmount,
          totalStakedAmount: totalStakedAmount,
          stakedTokenAddress: liquidityToken.address
        })
      }
      return memo
    }, [])
  }, [chainId, brewmaster, poolIds, pendingAles, poolBalances, totalSupplies, info])
}

export interface AleVaultStakingInfo {
  // the address of the reward contract
  poolId: number
  // the tokens involved in this pair
  //tokens: [Token, Token]
  // tokens: Token[]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount

  stakedTokenAddress: string
}

export function useAleVaultStakingInfo(vaultLPAddress?: string | null): AleStakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? VAULT_LISTS.filter(poolInfo =>
          vaultLPAddress === undefined
              ? true
              : vaultLPAddress === null
              ? false
              : poolInfo.vaultAddress === vaultLPAddress
          ) ?? []
        : [],
    [chainId, vaultLPAddress]
  )

  const lpAddresses = useMemo(() => info.map(({ lpTokenAddress }) => lpTokenAddress), [info])

  const brewmaster = useBrewmasterContract()
  const poolIds = useMemo(() => info.map(({ alePoolId }) => alePoolId), [info])
  const vaultAddresses = useMemo(() => info.map(({ vaultAddress }) => vaultAddress), [info])

  // const accountArg = useMemo(() => [account ?? undefined], [account])
  const poolArg = useMemo(() => info.map(({ alePoolId }) => [alePoolId]), [info])
  const poolAccountArg = useMemo(() => info.map(({ alePoolId }) => [alePoolId,account ?? undefined]), [info,account])

  // get all the info from the staking rewards contracts
  const poolBalances = useSingleContractMultipleData(brewmaster, 'userInfo', poolAccountArg) 
  const pendingAles = useSingleContractMultipleData(brewmaster, 'pendingAle', poolAccountArg)
  const totalSupplies = useMultipleContractSingleData(lpAddresses, ERC20 , 'totalSupply')

  return useMemo(() => {
    if (!chainId || !brewmaster) return []

    return poolIds.reduce<AleStakingInfo[]>((memo, poolId, index) => {
      // these two are dependent on account
      // const balanceState = balances[index]
      // const earnedAmountState = earnedAmounts[index]
      const pendingAleState = pendingAles[index]
      const poolBalanceState = poolBalances[index]
      const totalSupplyState = totalSupplies[index]
      // const allocPointState = allocPoints[index]

      // these get fetched regardless of account
      // const totalSupplyState = totalSupplies[index]
      // const rewardRateState = rewardRates[index]
      // const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        // !balanceState?.loading &&
        // !earnedAmountState?.loading &&

        // !balanceState?.loading &&
        !poolBalanceState?.loading &&
        !pendingAleState?.loading &&
        !totalSupplyState?.loading 
        // !allocPointState?.loading 

        // always need these

        // pendingAleState &&
        // !pendingAleState?.loading &&        
        // totalSupplyState &&
        // !totalSupplyState.loading &&
        // rewardRateState &&
        // !rewardRateState.loading &&
        // periodFinishState &&
        // !periodFinishState.loading
      ) {
        // get the LP token  
        // const tokens = info[index].tokens
        // const pair = poolId !== 0 ? new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0')) : undefined
        // const liquidityToken = !pair ? ALE[chainId] : pair.liquidityToken
        const liquidityToken = new Token(56,vaultAddresses[index],18)
        const poolStakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(poolBalanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(totalSupplyState?.result?.[0] ?? 0))     

        memo.push({
          poolId: info[index].alePoolId,
          tokens: [liquidityToken,liquidityToken],
          earnedAmount: new TokenAmount(ALE[56], JSBI.BigInt(pendingAleState?.result?.[0] ?? 0)),
          stakedAmount: poolStakedAmount,
          totalStakedAmount: totalStakedAmount,
          stakedTokenAddress: liquidityToken.address
        })
      }
      return memo
    }, [])
  }, [chainId, brewmaster, poolIds, pendingAles, poolBalances, totalSupplies, vaultAddresses, info])
}


export function useUserTotalStaked() {
  const stakingInfos = useAleStakingInfo()
  const farmsData = useFarmsData()

  return useMemo(() => {
    function getPricePerShare(stakedTokenAddress : string) {
      if (!farmsData) return 0
      for (let i = 0; i < farmsData.length;i++ ) {
        if (farmsData[i].pair === stakedTokenAddress) return farmsData[i].pricePerShare
      }    
    }

    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => { 
          const pricePerShare = JSBI.BigInt(Math.trunc(getPricePerShare(stakingInfo.stakedTokenAddress)*10000))
          const stakedValue = JSBI.divide(JSBI.multiply(stakingInfo.stakedAmount.raw,JSBI.BigInt(pricePerShare)),JSBI.BigInt(10000))
          return accumulator.add(new TokenAmount(BUSD, stakedValue) ) 
        },
        new TokenAmount(BUSD, '0')
      ) ?? new TokenAmount(BUSD, '0')
    )
  }, [farmsData, stakingInfos])

}

export function useUserTotalAleEarned() {
  const stakingInfos = useAleStakingInfo()
  return useMemo(() => {
    // if (!uni) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(ALE[56], '0')
      ) ?? new TokenAmount(ALE[56], '0')
    )
  }, [stakingInfos])
}

export function useVaultStakingInfo(vault: Vault,pairToFilterBy?: Pair | null): AleStakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? POOLS_INFO[chainId]?.filter(poolInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(poolInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(poolInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const brewmaster = useBrewmasterContract()
  const vaultContract = useVaultContract(vault.vaultAddress)

  // const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const poolIds = useMemo(() => info.map(({ poolId }) => poolId), [info])

  // const brewmasterAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => brewmasterAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])
  const poolArg = useMemo(() => info.map(({ poolId }) => [poolId]), [info])
  const poolAccountArg = useMemo(() => info.map(({ poolId }) => [poolId,account ?? undefined]), [info,account])

  // get all the info from the staking rewards contracts

  // const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const poolBalances = useSingleContractMultipleData(brewmaster, 'userInfo', poolAccountArg) 
  const pendingAles = useSingleContractMultipleData(brewmaster, 'pendingAle', poolAccountArg)
  // const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  // const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  const allocPoints = useSingleContractMultipleData(
    brewmaster,
    'poolInfo',
    poolArg
  )  

  return useMemo(() => {
    // if (!chainId || !uni) return []
    if (!chainId || !brewmaster) return []

    return poolIds.reduce<AleStakingInfo[]>((memo, poolId, index) => {
      // these two are dependent on account
      // const balanceState = balances[index]
      // const earnedAmountState = earnedAmounts[index]
      const pendingAleState = pendingAles[index]
      const poolBalanceState = poolBalances[index]
      const allocPointState = allocPoints[index]

      // these get fetched regardless of account
      // const totalSupplyState = totalSupplies[index]
      // const rewardRateState = rewardRates[index]
      // const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        // !balanceState?.loading &&
        // !earnedAmountState?.loading &&

        // !balanceState?.loading &&
        !poolBalanceState?.loading &&
        !pendingAleState?.loading &&
        !allocPointState?.loading 

        // always need these

        // pendingAleState &&
        // !pendingAleState?.loading &&        
        // totalSupplyState &&
        // !totalSupplyState.loading &&
        // rewardRateState &&
        // !rewardRateState.loading &&
        // periodFinishState &&
        // !periodFinishState.loading
      ) {
        // if (
        //   balanceState?.error ||
        //   earnedAmountState?.error ||
        //   totalSupplyState.error ||
        //   rewardRateState.error ||
        //   periodFinishState.error
        // ) {
        //   console.error('Failed to load staking rewards info')
        //   return memo
        // }

        // get the LP token  
        const tokens = info[index].tokens
        const liquidityToken = poolId == 0 ? ALE[chainId] : new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0')).liquidityToken

        // check for account, if no account set to 0

        const poolStakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(poolBalanceState?.result?.[0] ?? 0))
        const stakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(0))
        const totalStakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(0))

        // const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber()
        // const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        // const active =
        //   periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true
        const active = true
        memo.push({
          poolId: info[index].poolId,
          tokens: info[index].tokens,
          // periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(liquidityToken, JSBI.BigInt(pendingAleState?.result?.[0] ?? 0)),
          stakedAmount: poolStakedAmount,
          totalStakedAmount: totalStakedAmount,
          stakedTokenAddress: liquidityToken.address 
          // getHypotheticalRewardRate,
          // active
        })
      }
      return memo
    }, [])
  }, [chainId, brewmaster, poolIds, pendingAles, poolBalances, allocPoints, info])
}



export function usePoolStakingInfo(pairToFilterBy?: Pair | null): AleStakingInfo[] {
  const { chainId, account } = useActiveWeb3React()


  // detect if staking is ended
  const currentBlockTimestamp = useCurrentBlockTimestamp()

  const info = useMemo(
    () =>
      chainId
        ? POOLS_INFO[chainId]?.filter(poolInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(poolInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(poolInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const brewmaster = useBrewmasterContract()

  // const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const poolIds = useMemo(() => info.map(({ poolId }) => poolId), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])
  const poolArg = useMemo(() => info.map(({ poolId }) => [poolId]), [info])
  const poolAccountArg = useMemo(() => info.map(({ poolId }) => [poolId,account ?? undefined]), [info,account])

  // get all the info from the staking rewards contracts

  // console.log("brewmaster "+brewmaster)
  // const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const poolBalances = useSingleContractMultipleData(brewmaster, 'userInfo', poolAccountArg) 
//  const pendingAles = useSingleContractMultipleData(brewmaster, 'pendingAle', poolAccountArg)

  // const allocPoints = useSingleContractMultipleData(
  //   brewmaster,
  //   'poolInfo',
  //   poolArg
  // )  

  return useMemo(() => {
    if (!chainId) return []

    return poolIds.reduce<AleStakingInfo[]>((memo, poolId, index) => {
      // these two are dependent on account
      // const balanceState = balances[index]
      // const earnedAmountState = earnedAmounts[index]
      // const pendingAleState = pendingAles[index]
      const poolBalanceState = poolBalances[index]
      // const allocPointState = allocPoints[index]

      // these get fetched regardless of account
      // const totalSupplyState = totalSupplies[index]
      // const rewardRateState = rewardRates[index]
      // const periodFinishState = periodFinishes[index]

      if (
        !poolBalanceState?.loading         
        // these may be undefined if not logged in
        // !balanceState?.loading &&
        // !earnedAmountState?.loading &&

        // !balanceState?.loading

        // always need these

        // pendingAleState &&
        // !pendingAleState?.loading &&        
        // totalSupplyState &&
        // !totalSupplyState.loading &&
        // rewardRateState &&
        // !rewardRateState.loading &&
        // periodFinishState &&
        // !periodFinishState.loading
      // ) {
        ) {
        if (
          poolBalanceState?.error
        //   balanceState?.error ||
        //   earnedAmountState?.error ||
        //   totalSupplyState.error ||
        //   rewardRateState.error ||
        //   periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token  
        const tokens = info[index].tokens
        const liquidityToken = poolId == 0 ? ALE[chainId] : new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0')).liquidityToken
        // check for account, if no account set to 0

        const poolStakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(poolBalanceState?.result?.[0] ?? 0))
        const stakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(0))
        const totalStakedAmount = new TokenAmount(liquidityToken, JSBI.BigInt(0))
        const totalRewardRate = new TokenAmount(liquidityToken, JSBI.BigInt(0))

        // const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)
        // const individualRewardRate = new TokenAmount(ALE[chainId], JSBI.BigInt(allocPointState?.result?.[1] ?? 0))

        // const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber()
        // const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        // const active =
        //   periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true
        const active = true
        memo.push({
          poolId: info[index].poolId,
          tokens: info[index].tokens,
          // periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(liquidityToken, JSBI.BigInt(0)),
          stakedAmount: poolStakedAmount,
          // stakedAmount: new TokenAmount(liquidityToken, JSBI.BigInt(0)),
          totalStakedAmount: totalStakedAmount,
          stakedTokenAddress: liquidityToken.address
          // getHypotheticalRewardRate,
          // active
        })
      }
      return memo
    }, [])
  }, [chainId, info, poolBalances, poolIds])
}



// // gets the staking info from the network for the active chain id
// export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
//   const { chainId, account } = useActiveWeb3React()

//   // detect if staking is ended
//   const currentBlockTimestamp = useCurrentBlockTimestamp()

//   const info = useMemo(
//     () =>
//       chainId
//         ? POOLS_INFO[chainId]?.filter(stakingRewardInfo =>
//             pairToFilterBy === undefined
//               ? true
//               : pairToFilterBy === null
//               ? false
//               : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
//                 pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
//           ) ?? []
//         : [],
//     [chainId, pairToFilterBy]
//   )

//   // const uni = chainId ? UNI[chainId] : undefined

//   const uni = undefined
//   // const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

//   const accountArg = useMemo(() => [account ?? undefined], [account])

//   // get all the info from the staking rewards contracts
//   const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
//   const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
//   const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

//   // tokens per second, constants
//   const rewardRates = useMultipleContractSingleData(
//     rewardsAddresses,
//     STAKING_REWARDS_INTERFACE,
//     'rewardRate',
//     undefined,
//     NEVER_RELOAD
//   )
//   const periodFinishes = useMultipleContractSingleData(
//     rewardsAddresses,
//     STAKING_REWARDS_INTERFACE,
//     'periodFinish',
//     undefined,
//     NEVER_RELOAD
//   )

//   return useMemo(() => {
//     if (!chainId || !uni) return []

//     return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
//       // these two are dependent on account
//       const balanceState = balances[index]
//       const earnedAmountState = earnedAmounts[index]

//       // these get fetched regardless of account
//       const totalSupplyState = totalSupplies[index]
//       const rewardRateState = rewardRates[index]
//       const periodFinishState = periodFinishes[index]

//       if (
//         // these may be undefined if not logged in
//         !balanceState?.loading &&
//         !earnedAmountState?.loading &&
//         // always need these
//         totalSupplyState &&
//         !totalSupplyState.loading &&
//         rewardRateState &&
//         !rewardRateState.loading &&
//         periodFinishState &&
//         !periodFinishState.loading
//       ) {
//         if (
//           balanceState?.error ||
//           earnedAmountState?.error ||
//           totalSupplyState.error ||
//           rewardRateState.error ||
//           periodFinishState.error
//         ) {
//           // console.error('Failed to load staking rewards info')
//           return memo
//         }

//         // get the LP token
//         const tokens = info[index].tokens
//         const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

//         // check for account, if no account set to 0

//         const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
//         const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
//         const totalRewardRate = new TokenAmount(BUSD, JSBI.BigInt(rewardRateState.result?.[0]))

//         const getHypotheticalRewardRate = (
//           stakedAmount: TokenAmount,
//           totalStakedAmount: TokenAmount,
//           totalRewardRate: TokenAmount
//         ): TokenAmount => {
//           return new TokenAmount(
//             BUSD,
//             JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
//               ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
//               : JSBI.BigInt(0)
//           )
//         }

//         const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

//         const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber()
//         const periodFinishMs = periodFinishSeconds * 1000

//         // compare period end timestamp vs current block timestamp (in seconds)
//         const active =
//           periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

//         memo.push({
//           stakingRewardAddress: rewardsAddress,
//           tokens: info[index].tokens,
//           periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
//           earnedAmount: new TokenAmount(BUSD, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
//           rewardRate: individualRewardRate,
//           totalRewardRate: totalRewardRate,
//           stakedAmount: stakedAmount,
//           totalStakedAmount: totalStakedAmount,
//           getHypotheticalRewardRate,
//           active
//         })
//       }
//       return memo
//     }, [])
//   }, [
//     balances,
//     chainId,
//     currentBlockTimestamp,
//     earnedAmounts,
//     info,
//     periodFinishes,
//     rewardRates,
//     rewardsAddresses,
//     totalSupplies,
//     uni
//   ])
// }

// export function useTotalUniEarned(): TokenAmount | undefined {
//   const { chainId } = useActiveWeb3React()
//   const uni = chainId ? UNI[chainId] : undefined
//   const stakingInfos = useStakingInfo()

//   return useMemo(() => {
//     if (!uni) return undefined
//     return (
//       stakingInfos?.reduce(
//         (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
//         new TokenAmount(uni, '0')
//       ) ?? new TokenAmount(uni, '0')
//     )
//   }, [stakingInfos, uni])
// }

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
