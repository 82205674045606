import { TransactionResponse } from '@ethersproject/providers'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Vault } from 'utils/vault'
import { TokenAmount } from '../../../../@uniswap/sdk/dist'
import { useActiveWeb3React } from '../../hooks'
import { useVaultContract } from '../../hooks/useContract'
import { AleStakingInfo } from '../../state/stake/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { CloseIcon, TYPE } from '../../theme'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import Modal from '../Modal'
import { LoadingView, SubmittedView } from '../ModalViews'
import { RowBetween } from '../Row'
import { ArrowDown } from 'react-feather'
import { parseEther } from 'ethers/lib/utils'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`


interface VaultUnstakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  vault: Vault
  stakingInfo?: AleStakingInfo
  outputToken: string
  outputAmount: number
  withdrawAmount: TokenAmount
  atMaxWithdrawAmount: boolean
}

export function VaultUnstakingModal({ isOpen, onDismiss, stakingInfo, vault, outputToken, outputAmount, withdrawAmount, atMaxWithdrawAmount }: VaultUnstakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const vaultContract = useVaultContract(vault.vaultAddress)

  const minOutputAmount = outputAmount ? parseEther((outputAmount*0.99).toFixed(18)) : '0'

  async function withdraw() {
    if (vaultContract && withdrawAmount) {
      setAttempting(true)
      await vaultContract
        .withdraw(`0x${withdrawAmount.raw.toString(16)}`, { gasLimit: 450000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  async function withdrawToNative() {
    if (vaultContract && withdrawAmount) {
      setAttempting(true)
      await vaultContract
        .withdrawToNative(`0x${withdrawAmount.raw.toString(16)}`, minOutputAmount, { gasLimit: 1500000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  async function withdrawToToken(tokenAddress : string) {
    if (vaultContract && withdrawAmount) {
      setAttempting(true)
      await vaultContract
        .withdrawToToken( `0x${withdrawAmount.raw.toString(16)}`, tokenAddress, minOutputAmount, { gasLimit: 1500000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }  

  async function withdrawAll() {
    if (vaultContract && withdrawAmount) {
      setAttempting(true)
      await vaultContract
        .withdrawAll({ gasLimit: 450000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  async function withdrawAllToNative() {
    if (vaultContract && withdrawAmount) {
      setAttempting(true)
      await vaultContract
        .withdrawAllToNative( minOutputAmount, { gasLimit: 1000000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  async function withdrawAllToToken(tokenAddress : string) {
    if (vaultContract && withdrawAmount) {
      setAttempting(true)
      await vaultContract
        .withdrawAllToToken( tokenAddress, minOutputAmount, { gasLimit: 1000000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }    

  async function handleWithdraw() {
    if (atMaxWithdrawAmount) {
      if (outputToken === 'LP') {
        await withdrawAll()
      } else if (outputToken === 'BNB') {
        await withdrawAllToNative()
      } else if (outputToken === 'BUSD') {
        await withdrawAllToToken('0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56')
      } else if (outputToken === 'CRAFT') {
        await withdrawAllToToken('0x19Ea6042ca81bcd1FEC329004Fd5967AFdC6745e')
      }      
    } else {
      if (outputToken === 'LP') {
        await withdraw()
      } else if (outputToken === 'BNB') {
        await withdrawToNative()
      } else if (outputToken === 'BUSD') {
        await withdrawToToken('0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56')
      } else if (outputToken === 'CRAFT') {
        await withdrawToToken('0x19Ea6042ca81bcd1FEC329004Fd5967AFdC6745e')
      }
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!withdrawAmount) {
    error = error ?? 'Enter an amount'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          <AutoColumn justify="center" gap="md">
            <TYPE.body fontWeight={600} fontSize={36}>
              {<FormattedCurrencyAmount currencyAmount={withdrawAmount} significantDigits={12} />}
            </TYPE.body>
            <TYPE.body>Deposited liquidity:</TYPE.body>
          </AutoColumn>
          {
            outputToken !== "LP" && (
              <AutoColumn justify="center" gap="md">
                <ArrowDown size="16" />
                <TYPE.body fontWeight={600} fontSize={36}>
                  ~{outputAmount}
                </TYPE.body>
                <TYPE.body>{outputToken}</TYPE.body>
              </AutoColumn>
            )
          }
          {/* {stakingInfo?.earnedAmount && (
              <AutoColumn justify="center" gap="md">
                <TYPE.body fontWeight={600} fontSize={36}>
                  {<FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />}
                </TYPE.body>
                <TYPE.body>ALE to Claim</TYPE.body>
              </AutoColumn>
            )} */}
          {/* <TYPE.subHeader style={{ textAlign: 'center' }}>
              When you withdraw, your ALE is harvested and your liquidity is removed from the mining pool.
            </TYPE.subHeader> */}
          <ButtonError disabled={!!error} error={!!error && !!withdrawAmount} onClick={handleWithdraw}>
            {error ?? 'Withdraw'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Withdrawing {withdrawAmount.toSignificant(4)} LP from Vault</TYPE.body>
            {/* <TYPE.body fontSize={20}>Harvesting {stakingInfo?.earnedAmount?.toSignificant(4)} ALE</TYPE.body> */}
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Withdrew LP from Vault</TYPE.body>
            {/* <TYPE.body fontSize={20}>Harvested ALE</TYPE.body> */}
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}