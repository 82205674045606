import useParsedQueryString from "hooks/useParsedQueryString";
import { stringify } from 'qs';
import React from "react";
import { Link } from "react-router-dom";
import { useIsDarkMode } from "state/user/hooks";
import styled from "styled-components";
import AleSwapHeadDarkImg from '../../assets/images/AleSwapLogoHead-dark.png';
import AleSwapHeadImg from '../../assets/images/AleSwapLogoHead.png';
import BlueBox from '../../assets/images/bluebox-bg.png';
import bgImageDark from '../../assets/images/land-bg-dark.png';
import { default as bgImage, default as uImage } from '../../assets/images/land-bg.png';
import UseCraftImg from '../../assets/images/UseCraft.png';
import UseOtherImg from '../../assets/images/UseOther.png';
import { ButtonCraft } from '../../components/Button';


function RedeemPage() {
  const darkMode = useIsDarkMode()
  const search = useParsedQueryString()

  const spendCraft = search ? "/redeem/craft?" + stringify({ ...search }) : '"/redeem/craft"'
  const spendCrypto = search ? "/redeem/crypto?" + stringify({ ...search }) : "/redeem/crypto"

  return (
    <LandingContainer>
      <LandingScreen>

        <LandingRow>
          <WhiteBox url={darkMode ? bgImageDark : bgImage}><CraftCoinHead src={darkMode ? AleSwapHeadDarkImg : AleSwapHeadImg} /></WhiteBox>
          <HeadBoldText>Spend your Coin with our beloved Partners.</HeadBoldText>
          <CraftRow>
            <ButtonCraft as={Link} to={spendCraft}><CraftItemImg src={UseCraftImg} />Use CRAFT</ButtonCraft>
            <ButtonCraft as={Link} to={spendCrypto}><CraftItemImg src={UseOtherImg} />Use Other Coins</ButtonCraft>
          </CraftRow>
        </LandingRow>
      </LandingScreen>

    </LandingContainer>

  );
}

const HeadBoldText = styled.div`
// @media (max-width: 992px) {
//   width: 520px;
// }

// @media (max-width: 600px) {
//   width: 400px;
//   font-size: 24px;
// }
width:100%;
max-width:520px;
color:${({ theme }) => theme.text6};


align-items: center;
justify-items: center;
margin-bottom:20px;

padding-top:10px;
padding-bottom:10px;

font-size: 24px;
font-weight:bold;
  text-align:center;


`;

const WhiteBoxText = styled.div`

// @media (max-width: 992px) {
//   width: 520px;
// }

// @media (max-width: 600px) {
//   width: 400px;
//   font-size: 3vw;
// }



width:100%;
max-width:520px;
background-color:#ffffff;
color:#0B2553;
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;

align-items: center;
justify-items: center;
margin-bottom:20px;
background: url(${uImage}); 
padding-top:10px;
padding-bottom:10px;

  
  text-align:center;


`;


const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
`;




const LandingRow = styled.div`
display:grid;
  width: 100%;
  
  align-items: center;
justify-items: center;

`;



const LandingScreen = styled.div`
  width: 100%;
  height: 100%;
  padding-top:10px;
  // @media (max-width: 600px) {
  //   margin-top:-90px;
  // }

  // @media (max-width: 360px) {
  //   width:500px;
  //   margin-top:-90px;
  // }

  

  
  
  flex-direction: column;
 
  display: flex;
  align-items: center;
  
   margin: 0;
  
  
`;



const CraftCoinContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  
 
  
  
  
`;

const WhiteBox = styled.div<{ url : string }>`
 background: url(${({ url }) => url});
// @media (max-width: 992px) {
//   width: 520px;
// }

// @media (max-width: 600px) {
//   width: 400px;
//   margin-top:50px;
// }

// @media (max-width: 360px) {
//   width: 340px;
  
// }

width:100%;
max-width:520px;
background-color:${({ theme }) => theme.panelBG};
color:#0B2553;
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;
display:grid;
align-items: center;
justify-items: center;
margin-bottom:20px;
padding-top:10px;
padding-bottom:10px;

  font-size: 1.5vw;
  text-align:center;


`;
const BoxText = styled.div`
@media (max-width: 992px) {
  width: 520px;
}

@media (max-width: 600px) {
  width: 400px;
  font-size: 3vw;
}
width:520px;
max-width:520px;
color:#0B2553;


align-items: center;
justify-items: center;
margin-bottom:20px;

padding-top:10px;
padding-bottom:10px;

  font-size: 1.5vw;
  text-align:center;


`;

const BoldText = styled.span`

font-weight:bold;



`;

const CraftCoinHead = styled.img`

width: 60%;

display:grid;
align-items: center;
justify-items: center;


`;

const ExternalImg = styled.img`

width: 18px;
display: inline-block;



`;

const CraftRow = styled.div`

// @media (max-width: 992px) {
//   width: 520px;
// }

// @media (max-width: 600px) {
//   width: 400px;
//   font-size: 24px;

//   margin-right:10px;
//   margin-left:10px;  
// }

// @media (max-width: 360px) {
//   width: 340px;
  
// }

width:100%;
max-width:520px;
display: grid;
    grid-template-columns: 1fr 1fr;
    
    column-gap:20px;
    row-gap: 20px;


`;

const CraftItem = styled.div`


padding: 10px;

padding-top: 20px;




background-color:#347AF0;
color:#FFFFFF;
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;

align-items: center;
justify-items: center;
margin-bottom:20px;

background: url(${BlueBox}); 
display:grid;
font-weight:bold;
font-size:1.8vw;


`;

const CraftItemImg = styled.img`
margin-left:10%;
margin-bottom:20px;
margin-top:10px;

width: 80%;




`;




const MerchantScreen = styled.div`
  width: 40%;
  height: 700px;
  padding-top:10px;

  
  
  flex-direction: column;
  
  
 
  display: flex;
  align-items: center;
  
   margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  
`;



export default RedeemPage;
