// import { ChainId } from '@uniswap/sdk'
import { ChainId } from '../'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [ChainId.BSCTEST]: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.POLYGON]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e', // TODO: 
  [ChainId.REI]: '0x6e2A64fE96732C8D4cA9DfF8DAf3178C25D2b434', // TODO: 
  [ChainId.HARMONY]: '0x34b415f4d3b332515e66f70595ace1dcf36254c5', // TODO: 
  // [ChainId.HARMONY_TEST]: '0xd078799c53396616844e2fa97f0dd2b4c145a685', // TODO: 
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
