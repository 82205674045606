// import { ChainId } from '@uniswap/sdk';
import { ChainId } from '../../constants';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { VAULT_LISTS, VAULT_LISTS_PRIVATE } from 'utils/vault';
import bgImageDark from '../../assets/images/land-bg-dark.png';
import bgImage from '../../assets/images/land-bg.png';
import logodark from '../../assets/images/landing-logo-2x-dark.png';
import logo from '../../assets/images/landing-logo-2x.png';
import { ButtonPrimary, ButtonSecondary } from '../../components/Button';
import Card from '../../components/Card';
import { AutoColumn } from '../../components/Column';
import { BottomDataCard, CardLogo, CardSection, DataCard, TopDataCard } from '../../components/earn/styled';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import { AutoRow, RowBetween, RowFixed } from '../../components/Row';
import { PairVaultPrivateCard } from '../../components/VaultCard';
import { useActiveWeb3React } from '../../hooks';
import { useAllTokens } from '../../hooks/Tokens';
import { useIsDarkMode } from '../../state/user/hooks';
import { TYPE } from '../../theme';









const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopRoundShadowCard = styled(TopDataCard)`
  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;
  background: white;
  overflow: hidden;
`

const BottomRoundShadowCard = styled(BottomDataCard)`
  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;  
  background: white;
  overflow: hidden;
`

const RoundShadowCard = styled(DataCard)`

  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;
  background: ${({ theme }) => theme.white};
  overflow: hidden;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LogoBox = styled.div`
  width: 100%;
  height: 185px;
  display: flex;
  align-items: center  
  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 100px;
  `};  
`

export const CardBGImage = styled.span<{ url : string }>`
  background: url(${({ url }) => url});
  width: 1000px;
  height: 600px;
  position: absolute;
  border-radius: 12px;
  user-select: none;
`


export default function VaultPrivate() {
  const theme = useContext(ThemeContext)
  const { account,chainId } = useActiveWeb3React()
  const { width, height } = useWindowSize()  
  const allTokens = useAllTokens()


  const darkMode = useIsDarkMode()
  
  interface FarmRowProps {
    poolName: string,
    poolId : number,
    chainId : ChainId | undefined
    startTime?: string
  }

  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        {/* <LogoBox /> */}
        <RoundShadowCard>
          <AutoRow>
          
          
          <CardBGImage url={darkMode ? bgImageDark : bgImage}/> 
          
          <CardSection>
            <AutoColumn gap="md">
            <LogoBox><CardLogo src = {darkMode ? logodark: logo} /></LogoBox>
            </AutoColumn>
          </CardSection>

          </AutoRow>
        </RoundShadowCard> 

        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
              
            <TYPE.mediumHeader style={{ marginTop: '0.5rem', justifySelf: 'flex-start' }}>
              Definix
            </TYPE.mediumHeader>             
              
            
            </TitleRow>
             {!account ? (
              <Card padding="40px">
                <TYPE.body color={theme.text3} textAlign="center">
                  Connect to a wallet to view AleSwap Farm.
                </TYPE.body>
              </Card>
              ) : (
                <>                             
                  <RoundShadowCard>
                    {
                      VAULT_LISTS_PRIVATE.map((vault) => 
                        <PairVaultPrivateCard vault={vault} pairName="USDT-BUSD" poolId={5}></PairVaultPrivateCard>                    
                      )
                    }
                  </RoundShadowCard>
{/*                   
                    { width && width >= 600 ?(
                      <AutoRow gap="2px">
                      <TopRoundShadowCard>
                        <FarmRow poolName={farmLists[1].name} poolId={farmLists[1].id} startTime={farmLists[1].startTime} chainId={chainId} ></FarmRow>
                      </TopRoundShadowCard>
                       { farmLists.map((item, i) =>
                       i > 1 && i < farmLists.length - 1 ? (<FarmRow poolName={item.name} poolId={item.id} startTime={item.startTime} chainId={chainId}></FarmRow>) : <></>
                      )}
                      
                      <BottomRoundShadowCard>
                        <FarmRow poolName={farmLists[farmLists.length - 1].name} poolId={farmLists[farmLists.length - 1].id} startTime={farmLists[farmLists.length - 1].startTime} chainId={chainId} ></FarmRow>
                      </BottomRoundShadowCard>
                      </AutoRow>
                    ) : (
                      <AutoRow gap="10px">
                      { farmLists.map((item, i) =>
                        i >= 1 ? (
                          <RoundShadowCard>
                            <FarmRow poolName={item.name} poolId={item.id} chainId={chainId}></FarmRow>
                          </RoundShadowCard>
                        ) : <></>
                       )}
                      </AutoRow>
                    )}

                  


                  <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
                      <TYPE.mediumHeader style={{ marginTop: '0.5rem', justifySelf: 'flex-start' }}>
                        Legacy Farm
                      </TYPE.mediumHeader>

                  </TitleRow>
                  <AutoRow gap="1px">
                    <TopRoundShadowCard>
                      <FarmRow poolName={legacyFarmLists[0].name} poolId={legacyFarmLists[0].id} chainId={chainId}></FarmRow>
                    </TopRoundShadowCard>

                    {legacyFarmLists.map((item, i) =>
                      i >= 1 && i < legacyFarmLists.length - 1 ? (<FarmRow poolName={item.name} poolId={item.id} chainId={chainId}></FarmRow>) : <></>
                    )
                    }
                    <BottomRoundShadowCard>
                      <FarmRow poolName={legacyFarmLists[legacyFarmLists.length - 1].name} poolId={legacyFarmLists[legacyFarmLists.length - 1].id} chainId={chainId}></FarmRow>
                    </BottomRoundShadowCard>
                  </AutoRow> */}
                </>
              )} 
  
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </>
  )
}
