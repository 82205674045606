import React, { Component } from 'react'
// import {Link} from 'react-scroll'
import PartnerBg from '../../assets/images/Partner-bg.png';
import PartnerBgDark from '../../assets/images/Partner-bg-dark.png';
import { useIsDarkMode } from 'state/user/hooks';

import styled, { css } from "styled-components";

export default function PartnerBox()  {
  const darkMode = useIsDarkMode()

  return (
      
            <WhiteCard background={darkMode ? PartnerBgDark : PartnerBg}>
                <TopText>Spend reward with :</TopText>
                <BlueBoldText>OUR</BlueBoldText>
                <BlueBoldText>PARTNER</BlueBoldText>
                <div>Step by step to shop</div>
                <ButtonRow><a href="https://aleswapth.gitbook.io/aleswap/redeem-aleswap"><OrButton>Learn More</OrButton></a></ButtonRow>
            </WhiteCard>
        )
      }

const WhiteCard = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  font-weight:bold;
  width:100%;
  min-width:300px;
height:250px;
background-image: url(${({ background }) => background}); 
 background-repeat: no-repeat;
  align-self:center;
  
  background-position:bottom right;
  background-size: auto;

  
  background-color: ${({ theme }) => theme.panelBG};
  color: ${({ theme }) => theme.panelText1};
  align-self:center;
  
  

box-shadow: 0px 1px 1px #00000040;
border-radius: 5px;

align-items: center;
  justify-content: center;


  
`;

const TopText = styled.div`
 
  font-weight:600;
  font-size:22px;
  
  align-self:center;
    
`;

const BlueBoldText = styled.div`
  
  font-weight:bold;
  font-size:48px;
  line-height:45px;
  
  color:${({ theme }) => theme.panelText2};
  
  align-self:center;
`;

const ButtonRow = styled.div`
  
  
  align-self:center;
    
`;


const OrButton = styled.button`
background-color:${({ theme }) => theme.panelButtonBG};
color:#ffffff;
font-weight:700;
font-size:14px;
border-radius: 5px;
border:0px;
align-self:center;
font-family:'Titillium Web';
padding-left:5px;
padding-right:5px;
padding-bottom:4px;
margin-top:10px;

&:hover {
  
  box-shadow: 0 0 0 3pt #195BCB;
  cursor: pointer;
}

@media (max-width: 414px) {
  font-size:16px;
  
min-width:120px;
  
}
`
  ;




  

  ;