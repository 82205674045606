import React from "react";
import { useIsDarkMode } from "state/user/hooks";
import styled from "styled-components";
import { useALEPrice, useRewardPerBlock, useTVL } from "utils/aleSwapAPI";
import BscLogo from '../../assets/images/bsc.png';
import FaceBookLogo from '../../assets/images/fb398.png';
import GitBookLogo from '../../assets/images/gitbook398.png';
import GitHubLogo from '../../assets/images/github398.png';
import bgImageDark from '../../assets/images/land-bg-dark.png';
import bgImage from '../../assets/images/land-bg.png';
import MediumLogo from '../../assets/images/mediumlogo.png';
import TelegramLogo from '../../assets/images/telegram398.png';
import TwitterLogo from '../../assets/images/twitter398.png';
import { Dots } from '../swap/styleds';



function InfoBox() {
  const alePrice = useALEPrice()
  const tvl = useTVL()
  const alePerBlock = useRewardPerBlock()
  const darkMode = useIsDarkMode()


  return (
    <LandingContainer>

      <LandingScreen>
        <WhiteBox url={darkMode ? bgImageDark : bgImage}>

          <Container>
          <Row><LeftBox>Total Value Locked</LeftBox><RightBox>${!tvl ? <Dots>Loading</Dots> : parseInt(tvl).toLocaleString('en-US')}</RightBox></Row>
          <Row><LeftBox>ALE PRICE</LeftBox><RightBox>${!alePrice ? <Dots>Loading</Dots> : parseFloat(alePrice).toFixed(5)}</RightBox></Row>
          <Row><LeftBox>ALE/BLOCK</LeftBox><RightBox>{!alePrice ? <Dots>Loading</Dots> : alePerBlock} <BonusSpan>Bonus Period</BonusSpan></RightBox></Row>
          <CenterRow>
          <OneIcon><a href='https://twitter.com/aleswapfinance'><TwitterIcon src={TwitterLogo}></TwitterIcon></a></OneIcon>
              <OneIcon><a href='https://t.me/aleswap'><TelegramIcon src={TelegramLogo}></TelegramIcon></a></OneIcon>
              <OneIcon><a href='https://aleswap.gitbook.io/aleswap/'><GitBookIcon src={GitBookLogo}></GitBookIcon></a></OneIcon>
              <OneIcon><a href='https://github.com/aleswap-finance'><GitHubIcon src={GitHubLogo}></GitHubIcon></a></OneIcon>
              <OneIcon><a href='https://facebook.com/aleswap'><FaceBookIcon src={FaceBookLogo}></FaceBookIcon></a></OneIcon>
              <OneIcon><a href='https://aleswap.medium.com/'><FaceBookIcon src={MediumLogo}></FaceBookIcon></a></OneIcon>
          </CenterRow>
          <CenterRow><BscLogoBottom src={BscLogo}></BscLogoBottom></CenterRow>

          </Container>
          {/* <GridBox>
            <Row1left>Total Value Locked</Row1left><Row1right>${!tvl ? <Dots>Loading</Dots> : parseInt(tvl).toLocaleString('en-US')}</Row1right>
            <Row2left>ALE PRICE</Row2left><Row2right>${!alePrice ? <Dots>Loading</Dots> : parseFloat(alePrice).toFixed(5)}</Row2right>
            <Row3left>ALE/BLOCK</Row3left><Row3right>{!alePrice ? <Dots>Loading</Dots> : alePerBlock} <BonusBox>
              <BonusPeriod>Bonus Period</BonusPeriod>
            </BonusBox></Row3right>
            <Row4><SocialBox>

              <OneIcon><a href='https://twitter.com/aleswapfinance'><TwitterIcon src={TwitterLogo}></TwitterIcon></a></OneIcon>
              <OneIcon><a href='https://t.me/aleswap'><TelegramIcon src={TelegramLogo}></TelegramIcon></a></OneIcon>
              <OneIcon><a href='https://aleswap-finance.gitbook.io/aleswap/'><GitBookIcon src={GitBookLogo}></GitBookIcon></a></OneIcon>
              <OneIcon><a href='https://github.com/aleswap-finance'><GitHubIcon src={GitHubLogo}></GitHubIcon></a></OneIcon>
              <OneIcon><a href='https://facebook.com/aleswap'><FaceBookIcon src={FaceBookLogo}></FaceBookIcon></a></OneIcon>
              <OneIcon><a href='https://aleswap.medium.com/'><FaceBookIcon src={MediumLogo}></FaceBookIcon></a></OneIcon>
            </SocialBox>
            </Row4>

            <Row6><BscLogoBottom src={BscLogo}></BscLogoBottom></Row6>
          </GridBox> */}
        </WhiteBox>


      </LandingScreen>
    </LandingContainer>

    

    




  );
}

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:50px;
  padding-bottom:10px;
  color:${({ theme }) => theme.text6};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding:20px;  
  padding-bottom:0px;
  
  align-contents:center;
  justify-content: center;
  
`};
  
  
`;

const Row = styled.div`
width:100%;
margin-bottom:20px;
align-items: center;
height: 50px;
display: flex;
flex-direction: row;
${({ theme }) => theme.mediaWidth.upToMedium`
flex-direction: column;
margin-bottom:40px;
align-contents:center;
justify-content: center;
  
`};
  
  
`;

const CenterRow = styled.div`
width:100%;
    margin-bottom:20px;
    justify-content: center;
    height: 50px;
    display: flex;

flex-direction: row;
  
  
`;


const LeftBox = styled.div`
width:60%;
  color:rgb(11, 37, 83);
  text-align:left;
  color:${({ theme }) => theme.text6};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  text-align:center;
  width:100%;
`};
  
  
`;

const RightBox = styled.div`

width:40%;
  color: rgba(52,122,240,1);
  
  text-align:left;
  align-items:center;
  display:flex;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  
  width:100%;
  align-contents:center;
  justify-content: center;
`};
  
  
`;

const BonusSpan = styled.span`
background-color: rgba(240,185,11,1);
border-radius: 20px;
color:#FFFFFF;
font-size:20px;  
padding:5px 12px 5px 12px;
margin-left:5px;
${({ theme }) => theme.mediaWidth.upToMedium`
font-size:12px;  
padding:5px 8px 5px 8px;
`};
  
`;


const LandingScreen = styled.div`
  width: 100%;
  height: 100%;
  padding-top:120px;
  
  @media (max-width: 600px) {
    
    margin-top:50px;
  }

  
  
  flex-direction: column;
  
  
 
  display: flex;
  align-items: center;
  
   margin: 0;
  
`;




const WhiteBox = styled.div<{ url : string }>`
  background: url(${({ url }) => url});
  
@media (max-width: 992px) {
  width: 640px;
}

@media (max-width: 600px) {
  width: 100%;
  margin-top:-80px;
  
}

@media (max-width: 375px) {
  width: 100%;
  margin-top:-80px;
  
}

width:640px;
max-width:640px;
background-color:#ffffff;

box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;

color:${({ theme }) => theme.text6};

align-items: center;
justify-items: center;
justify-contents: center;
margin-bottom:20px;

padding-top:10px;

padding-bottom:10px;

  font-size: 32px;
  font-weight:bold;
  text-align:center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 28px;   
  width:100%;
`};

`;

const GridBox  = styled.div`

display:grid;
grid-template-columns: 1fr 1fr; 
  grid-template-rows: 50px 50px 50px 50px 50px ; 
  gap: 20px 0px; 
  grid-template-areas: 
    'row1left row1right'
    'row2left row2right'
    'row3left row3right'
    'row4 row4'
    'row6 row6'; 
    padding-top:30px;

    `;




const Row1left = styled.div`

grid-area:row1left;
text-align:left;
padding-left:50px;
@media (max-width: 600px) {
  padding-left:40px;
  font-size:18px;
}

`;

const Row1right = styled.div`

grid-area:row1right;

@media (max-width: 600px) {
  
  font-size:18px;
}
color: rgba(52,122,240,1);
justify-self: start;

padding-left:50px;
`;

const Row2left = styled.div`

grid-area:row2left;
text-align:left;
padding-left:50px;
@media (max-width: 600px) {
  padding-left:40px;
  font-size:18px;
  
}
`;

const Row2right = styled.div`

grid-area:row2right;
@media (max-width: 600px) {
  
  font-size:18px;
}
color: rgba(52,122,240,1);
justify-self: start;

padding-left:50px;
`;
const Row3left = styled.div`

grid-area:row3left;
text-align:left;
padding-left:50px;

@media (max-width: 600px) {
  padding-left:40px;
  font-size:18px;
  
}
`;

const Row3right = styled.div`

grid-area:row3right;
@media (max-width: 600px) {
  
  font-size:18px;
}
color: rgba(52,122,240,1);
display:flex;

  align-items: center;

  padding-left:50px;
  
`;
const Row4 = styled.div`

grid-area:row4;
display:flex;

  justify-content: center; 

`;


const Row5left = styled.div`

grid-area:row5left;
`;

const Row5right = styled.div`

grid-area:row5right;
`;

const Row6 = styled.div`
grid-area:row6;

`;






const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 640px;
 
  
  
  
`;





const BonusBox = styled.div`

  background-color: rgba(240,185,11,1);
  border-radius: 20px;
  width:45%
  height:30px;
  line-height:20px;
  margin-left:10px;
  
  
  
  
  
  
`;

const BonusPeriod = styled.span`
  font-family: Titillium Web;
  
  font-size:18px;
  color: rgba(255,255,255,1);
  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size:14px;   
`};
  
  
  
`;

const TwitterIcon = styled.img`
  width: 100%;
  height: 38px;
  object-fit: contain;
`;

const TelegramIcon = styled.img`
  width: 100%;
  height: 38px;
  
  object-fit: contain;
`;

const GitHubIcon = styled.img`
  width: 100%;
  height: 38px;
  
  object-fit: contain;
`;

const GitBookIcon = styled.img`
  width: 100%;
  height: 38px;
  
  object-fit: contain;
`;

const FaceBookIcon = styled.img`
  width: 100%;
  height: 38px;
  
  object-fit: contain;
`;

const SocialIconRow = styled.div`
  height: 38px;
  
  
  
  
  
`;



const SocialBox = styled.div`
display:flex;
max-width:250px;
 

`;

const OneIcon = styled.div`
width:42px;
    height:42px;
    padding: 2px;

`;

const BscLogoBottom = styled.img`
  width: 174px;
  
  
  
  object-fit: contain;
`;

export default InfoBox;
