import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { ChainId, CurrencyAmount, JSBI, Token, Trade } from '@uniswap/sdk'
import { CRAFT_ADDRESS } from '../../constants'
import { getMerchantLogo, getMerchantName, MERCHANT_LISTS } from 'constants/merchants'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { useTokenContract } from 'hooks/useContract'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { ThemeContext } from 'styled-components'
import { RedeemAddressInputPanel } from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import Column, { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import ProgressSteps from '../../components/ProgressSteps'
import { AutoRow } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ConfirmRedeemCraftModal } from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import { RedeemByCraftHeader } from '../../components/swap/SwapHeader'
// import { getTradeVersion } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDerivedSendCraftInfo, useRedeemDefaultsFromURLSearch, useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSingleHopOnly, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { calculateGasMargin } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'




const merchantList = MERCHANT_LISTS;

interface MerchantRowProps {
  name: string,
  id : number,
  address : string 
}


export default function SpendByCraft({ history }: RouteComponentProps) {
  const { account, chainId, library } = useActiveWeb3React()
  const loadedUrlParams = useRedeemDefaultsFromURLSearch()

  const { search } = useLocation()

  // token warning stuff
  // const [loadedInputCurrency, loadedOutputCurrency] = [
  //   useCurrency(loadedUrlParams?.inputCurrencyId),
  //   useCurrency(loadedUrlParams?.outputCurrencyId)
  // ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [useCraftToRedeem, setUseCraftToRedeem] = useState<boolean>(false)

  const craftAddress = chainId ? CRAFT_ADDRESS[chainId] : CRAFT_ADDRESS[ChainId.MAINNET]

  const craft = useCurrency(craftAddress)
  const craftToken = useTokenContract(craftAddress)

  const addTransaction = useTransactionAdder()

  const loadedRecipient = loadedUrlParams?.recipient;


  // const urlLoadedTokens: Token[] = useMemo(
  //   () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
  //   [loadedInputCurrency, loadedOutputCurrency]
  // )
  // const handleConfirmTokenWarning = useCallback(() => {
  //   setDismissTokenWarning(true)
  // }, [])

  // dismiss warning if all imported tokens are in active lists
  // const defaultTokens = useAllTokens()
  // const importTokensNotInDefault =
  //   urlLoadedTokens &&
  //   urlLoadedTokens.filter((token: Token) => {
  //     return !Boolean(token.address in defaultTokens)
  //   })

  // const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  // const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    // v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSendCraftInfo()

  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  // const toggledVersion = useToggledVersion()
  // const tradesByVersion = {
  //   [Version.v1]: v2Trade,
  //   [Version.v2]: v2Trade
  // }
  // const trade = showWrap ? undefined : tradesByVersion[toggledVersion]
  const trade = showWrap ? undefined : v2Trade
  // const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]
  // const defaultTrade = showWrap ? undefined : v2Trade

  // const betterTradeLinkV2: Version | undefined =
  //   toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade) ? Version.v2 : undefined

  const parsedAmounts = 
     {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }

  // const parsedAmounts = showWrap
  //   ? {
  //       [Field.INPUT]: parsedAmount,
  //       [Field.OUTPUT]: parsedAmount
  //     }
  //   : {
  //       [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
  //       [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
  //     }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    history.push('/swap/')
  }, [history])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // const [logo, setLogo] = useState<string>(loadedLogo)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const [singleHopOnly] = useUserSingleHopOnly()

  // const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            Version.v2
          ].join('/')
        })

        ReactGA.event({
          category: 'Routing',
          action: singleHopOnly ? 'Swap with multihop disabled' : 'Swap with multihop enabled'
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [
    priceImpactWithoutFee,
    swapCallback,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade,
    singleHopOnly
  ])

  async function onSendCraft() {
    if (!chainId || !library || !account || !craftToken || !recipient) return

    const { [Field.INPUT]: parsedAmountA, [Field.OUTPUT]: parsedAmountOutput } = parsedAmounts
    if (!parsedAmountA || !parsedAmountOutput) {
      return
    }

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null


    estimate = craftToken.estimateGas.transfer
    method = craftToken.transfer
    args = [
      recipient,
      parsedAmountOutput.raw.toString()
    ]    
    value = null    

    // setAttemptingTxn(true)
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          // setAttemptingTxn(false)
          setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: response.hash })

          addTransaction(response, {
            summary:
              'Spend ' + parsedAmounts[Field.OUTPUT]?.toSignificant(3) +' CRAFT'
          })

          // setTxHash(response.hash)

          // ReactGA.event({
          //   category: 'Craft',
          //   action: 'Redeem',
          //   label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/')
          // })
        })
      )
      .catch(error => {
        // setAttemptingTxn(false)
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
      setUseCraftToRedeem(inputCurrency == craft)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  return (
    <>
      <SwapPoolTabs active={'swap'} />
      <AppBody>
        <RedeemByCraftHeader />
        <Wrapper id="swap-page">
          <ConfirmRedeemCraftModal
            isOpen={showConfirm}
            // trade={trade}
            outputAmount={parsedAmounts[Field.OUTPUT]}
            // originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={onSendCraft}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />


          <AutoColumn gap={'md'}>
          <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={'Amount'}
              showMaxButton={false}
              disableCurrencySelect={true}
              //currency={currencies[Field.OUTPUT]}
              currency={ craft }
              onCurrencySelect={handleOutputSelect}
              id="swap-currency-output"
            />

            <AutoColumn justify="space-between">
              <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                  {recipient === null && !showWrap ? ( 
                    <>
                    Merchant: 

                    { merchantList.map((item, i) =>  
                      <LinkStyledButton id="add-recipient-button" onClick={() => { onChangeRecipient(item.address)}}>
                      {item.name}
                      </LinkStyledButton>  
                    )}                    


                  </>              
                ) : null}
                {recipient !== null && !showWrap ? (
                  <>
                    <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                      <ColumnCenter>
                      <ArrowWrapper clickable={false}>
                        <ArrowDown size="16" color={theme.text2} />
                      </ArrowWrapper>
                      </ColumnCenter>
                      { !loadedRecipient && (
                        <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                          Change merchant
                        </LinkStyledButton>
                      )}
                    </AutoRow>
                    <RedeemAddressInputPanel merchantName={ getMerchantName(recipient) } logo={ getMerchantLogo(recipient) } id="recipient" value={recipient} onChange={onChangeRecipient} />

                  </>
                ) : null}                
              </AutoRow>
            </AutoColumn>

          </AutoColumn>
          <BottomGrouping>
            {swapIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
              </ButtonPrimary>
            ) : !account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
            // ) : showWrap ? (
            //   <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
            //     {wrapInputError ??
            //       (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
            //   </ButtonPrimary>
            // ) : noRoute && userHasSpecifiedInputOutput ? (
            //   <GreyCard style={{ textAlign: 'center' }}>
            //     <TYPE.main mb="4px">Insufficient liquidity for this trade.</TYPE.main>
            //     {singleHopOnly && <TYPE.main mb="4px">Try enabling multi-hop trades.</TYPE.main>}
            //   </GreyCard>
            // ) : showApproveFlow ? (
            //   <RowBetween>
            //     <ButtonConfirmed
            //       onClick={approveCallback}
            //       disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
            //       width="48%"
            //       altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
            //       confirmed={approval === ApprovalState.APPROVED}
            //     >
            //       {approval === ApprovalState.PENDING ? (
            //         <AutoRow gap="6px" justify="center">
            //           Approving <Loader stroke="white" />
            //         </AutoRow>
            //       ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
            //         'Approved'
            //       ) : (
            //         'Approve ' + currencies[Field.INPUT]?.symbol
            //       )}
            //     </ButtonConfirmed>
            //     <ButtonError
            //       onClick={() => {
            //         if (isExpertMode) {
            //           handleSwap()
            //         } else {
            //           setSwapState({
            //             tradeToConfirm: trade,
            //             attemptingTxn: false,
            //             swapErrorMessage: undefined,
            //             showConfirm: true,
            //             txHash: undefined
            //           })
            //         }
            //       }}
            //       width="48%"
            //       id="swap-button"
            //       disabled={
            //         !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode || recipient === null)
            //       }
            //       error={isValid && priceImpactSeverity > 2}
            //     >
            //       <Text fontSize={16} fontWeight={500}>
            //         {priceImpactSeverity > 3 && !isExpertMode
            //           ? `Price Impact High 1`
            //           : `Redeem${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
            //       </Text>
            //     </ButtonError>
            //   </RowBetween>
            ) : (
              <ButtonError
                onClick={() => {
                  // if (isExpertMode) {
                  //   handleSwap()
                  // } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined
                    })
                  // }
                }}
                id="swap-button"
                // disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError || recipient === null}
                disabled={!isValid || recipient === null || parsedAmounts[Field.OUTPUT] === undefined}
                error={false}
                //error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
              >
                <Text fontSize={20} fontWeight={500}>
                  Spend
                  {/* { swapInputError ? swapInputError : 'Spend'} */}
                  {/* {swapInputError
                    ? swapInputError
                    : priceImpactSeverity > 3 && !isExpertMode
                    ? `Price Impact Too High 2`
                    : `Redeem${priceImpactSeverity > 2 ? ' Anyway' : ''}`} */}
                </Text>
              </ButtonError>
            )}
            {showApproveFlow && (
              <Column style={{ marginTop: '1rem' }}>
                <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
              </Column>
            )}
            {/* {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null} */}
          </BottomGrouping>
        </Wrapper>
      </AppBody>
    </>
  )
}
