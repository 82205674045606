const merchantList = [
    { id: 0, name: 'Mitr Brewery',address: "0x69D1F1ebf53967395B53A5a13eCb15EcE03100f7" , logo: "merchants/Mitr400.png"  },
    { id: 1, name: 'Devanom',address: "0x50c6eD30fec70Fc481C210645121E34929fD0184"  , logo: "merchants/Devanom400.png"  },
    { id: 10, name: 'Chitbeer',address: "0xFcDF3109251ef31990EaE9428574DfBA20FF0a53", logo: "merchants/Chitbeer400.png"  },  
    { id: 9, name: 'Beervana',address: "0x5090179dB33d637d5e94568bbC459c480ACCd73D", logo: "merchants/Beervana400.png"  },  
    { id: 17, name: 'Bavarian Craft',address: "0x379C31C1327DDbd76a33b95A8c21d075103150C3", logo: "merchants/Bavarian400.png"  },  
    { id: 18, name: 'Drinkable',address: "0x28aAbDD0DD658F475D0775BEB063A9f6E628032F", logo: "merchants/Drinkable400.png" },
    { id: 2, name: 'Group B',address: "0x44BB4779CB6df1d1010CC93aD00A0C6A3EAa4f9a", logo: "merchants/GroupB400.png"  },
    { id: 3, name: 'AKKEE',address: "0xfd6E8ed7417cbc00F3fc812E60f8336674465b3C", logo: "merchants/Akkee400.png"  },
    { id: 24, name: 'Best Beef',address: "0x4bF089B978E73e13dd2B7e93f7AeE1bB47Dc8a76", logo: "merchants/bestbeef400.png"  },
    { id: 25, name: 'BBQ Resort',address: "0x6B8160d0254D1A260484932f602f2309f2A6938f", logo: "merchants/Bbqresort400.png"  },
    { id: 4, name: 'Blu dove',address: "0x401AD069d743b9f4C0c3ad16212c0c9d14F20404", logo: "merchants/BlueDove400.png"  },
    { id: 5, name: 'Code Craft',address: "0xBEE127FA4D1698fb1B45B2993E94df4A5fbe2541", logo: "merchants/CodeCraft400.png"  },
    { id: 6, name: 'Thongpradit Brewing',address: "0x9Bf8fEfe7b61aa2D840cCB628cBB7ce2ada2D11f" , logo: "merchants/Thongpradit400.png"  },
    { id: 26, name: 'The Moon',address: "0x63a2Ba6D294c707672D1C8BD4612Ba1EBF79D8bb" , logo: "merchants/themoon400.png"  },
    
    { id: 7, name: 'YodBar',address: "0xDdc9B6D9dbc6468ECf1D67116Eb31cb86efb784B" , logo:'merchants/YodBar400.png' },
    { id: 20, name: 'The Six Taps',address: "0xCF15CD0a7B0397c9540B266c5a4429046CA1C966", logo: "merchants/TheSixTaps400.png" },
    { id: 27, name: 'เหนือสุด โฮมบริว',address: "0x9B7529dc802aCe395d3b18D25f3F25028ffCb223", logo: "merchants/NuaSood400.png" },

    
    { id: 8, name: '90NinetyCoffee',address: "0xF478Fd22ef21A9e68019DE145620d392DE9CE51C", logo: "merchants/90coffee400.png" },
    { id: 11, name: 'Sobta Cafe&Co',address: "0x1A30254019b2dfBC7DD54B651458482B9451e821", logo: "merchants/Sobta400.png" },
    { id: 12, name: 'Nammon',address: "0xFC8f55BB6aFd69Ddd118c800ddE56d92E368D8A0", logo: "merchants/nammon400.png" },
    { id: 21, name: 'Rare Malt',address: "0x35Cf03E03AC34dbdaE7836bCF638c182c3803CDE", logo: "merchants/RareMalt400.png" },
    { id: 13, name: 'Haus',address: "0x4ee31175F8C35A7f313890Cc61556169C81778D3", logo: "merchants/HausCraftBeer400.png" },
    { id: 15, name: 'Mash Up Supply',address: "0x8C47c0C4d729Dd8983d6a20e79eCd6BbE8ca53F0", logo: "merchants/Mashup400.png" },
    { id: 19, name: 'Global Hops',address: "0x60664bb58C697808aE2324322388a6A5Ed5FC0c7", logo: "merchants/GBH400.png" },
    { id: 23, name: 'iCoolBar',address: "0x8C65A4CFAa64523e5bBd722dD310e3739282780D", logo: "merchants/Icoolbar400.png" },
    { id: 16, name: 'Notom milk&toast',address: "0xE2C417AAe0af71Cf7199750b893613b7e3E0940F", logo: "merchants/Notom400.png" },
    { id: 14, name: 'Talanda Massage & Relax',address: "0x58bb99D509ED303B847c146D51C0418C08a08990", logo: "merchants/talanda400.png" },
    { id: 26, name: 'Uncle Joss',address: "0xeB2AE567C423B53b1E1DDeBDb3B89982d143a2dd", logo: "merchants/Unclejoss400.png" },    
    { id: 22, name: 'blackOut Slipper',address: "0x42AF55988eFfb4e19E3373dfCD74066b10A8Bb35", logo: "merchants/BlackOut400.png" },
    
  ];

export const MERCHANT_LISTS = merchantList;

export function getMerchantLogo(address : string)
{
  for (let i = 0;i < merchantList.length;i++)
  {
    if (merchantList[i].address.toLowerCase() === address.toLowerCase())  return merchantList[i].logo;
  }
  return '';
  // const logo = merchantList.find( 
  //   item => 
  //   item.address === 'a'
  //   ).logo
  // const logo = merchantList.find( item => {
  //   if (item.address == address) return item.logo;
  // })
}

export function getMerchantName(address : string)
{
  for (let i = 0;i < merchantList.length;i++)
  {
    if (merchantList[i].address.toLowerCase() == address.toLowerCase()) return merchantList[i].name;
  }
  return '';
  // const logo = merchantList.find( 
  //   item => 
  //   item.address === 'a'
  //   ).logo
  // const logo = merchantList.find( item => {
  //   if (item.address == address) return item.logo;
  // })
}