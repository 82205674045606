// import { ChainId, WETH } from '@uniswap/sdk';
import { PairFarmCard } from 'components/FarmCard/PairFarmCard';
import { StakingRewardsCard } from 'components/FarmCard/StakingRewardsCard';
import { VaultFarmCard } from 'components/FarmCard/VaultFarmCard';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FarmInfo, FARM_LISTS, getTokenByName, legacyFarmLists } from 'utils/farm';
import bgImageDark from '../../assets/images/land-bg-dark.png';
import bgImage from '../../assets/images/land-bg.png';
import logodark from '../../assets/images/landing-logo-2x-dark.png';
import logo from '../../assets/images/landing-logo-2x.png';
import { ButtonPrimary, ButtonSecondary } from '../../components/Button';
import { AutoColumn } from '../../components/Column';
import { BottomDataCard, CardLogo, CardSection, DataCard, TopDataCard } from '../../components/earn/styled';
import { SingleFarmCard } from '../../components/FarmCard';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import { AutoRow, RowBetween, RowFixed } from '../../components/Row';
import { useActiveWeb3React } from '../../hooks';
import { useAllTokens } from '../../hooks/Tokens';
import { useIsDarkMode } from '../../state/user/hooks';


const PageWrapper = styled(AutoColumn)`
  max-width: 1024px;
  padding: 15px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0px;
  `};      
  width: 100%;
`

const TopRoundShadowCard = styled(TopDataCard)`
  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;
  background: white;
  overflow: hidden;
`

const BottomRoundShadowCard = styled(BottomDataCard)`
  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;  
  background: white;
  overflow: hidden;
`

const RoundShadowCard = styled(DataCard)`

  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;
  background: ${({ theme }) => theme.white};
  overflow: hidden;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LogoBox = styled.div`
  width: 100%;
  height: 185px;
  display: flex;
  align-items: center  
  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 100px;
  `};  
`

export const CardBGImage = styled.span<{ url: string }>`
  background: url(${({ url }) => url});
  width: 1000px;
  height: 600px;
  position: absolute;
  border-radius: 12px;
  user-select: none;
`

const BoldWhiteText = styled.span`
  font-size:20px;
  font-weight:bold;
  color:#ffffff;
`


export default function FarmPage() {
  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()
  const { width, height } = useWindowSize()
  const allTokens = useAllTokens()

  const darkMode = useIsDarkMode()

  const coreFarmLists = FARM_LISTS.filter((farm) => farm.farmType === "core")
  const stableFarmLists = FARM_LISTS.filter((farm) => farm.farmType === "stable")
  const legacyFarmLists = FARM_LISTS.filter((farm) => farm.farmType === "legacy")
  // const partnerFarmLists = FARM_LISTS.filter((farm) => farm.farmType === "partner")
  const vaultFarmLists = FARM_LISTS.filter((farm) => farm.farmType === "vault")
  const legacyVaultFarmLists = FARM_LISTS.filter((farm) => farm.farmType === "legacy_vault")
  interface FarmRowProps {
    farm : FarmInfo,
    // poolName: string,
    // poolId: number,
    // chainId: ChainId | undefined
    // startTime?: string
  }

  function FarmRow({ farm }: FarmRowProps) {
    // const tokens = props.poolName.split("-")

    return (
      <>
        {farm.id === 0 ? (

          <SingleFarmCard poolId={farm.id} />
        ) :
          (
            <PairFarmCard farm={farm}  />
          )
        }
      </>
    );
  }

  interface FarmRowsProps {
    header: string
    farmLists: FarmInfo[]
  }  

  function FarmRows({header, farmLists} : FarmRowsProps) {
    return (
      <>
        <TitleRow padding={'0'} style={{ marginBottom: '-1rem' }}>
          <BoldWhiteText style={{ justifySelf: 'flex-start' }}>
            { header }
          </BoldWhiteText>
        </TitleRow>
        {width && width >= 600 ? (

          <AutoRow gap="2px">
            <TopRoundShadowCard>
              <FarmRow farm={farmLists[0]} />
            </TopRoundShadowCard>
            {farmLists.map((item, i) =>
              i > 0 && i < farmLists.length - 1 ? (<FarmRow farm={item}/>) : <></>
            )}

            <BottomRoundShadowCard>
              <FarmRow farm={farmLists[farmLists.length - 1]} />
            </BottomRoundShadowCard>
          </AutoRow>
        ) : (
          <>
            {farmLists.map((item, i) =>
              i >= 0 ? (
                <AutoRow>
                  <RoundShadowCard>
                    <FarmRow farm={item}/>
                  </RoundShadowCard>
                </AutoRow>
              ) : <></>
            )}
          </>
        )}
      </>
    )
  }

  function VaultFarmRows({header, farmLists} : FarmRowsProps) {
    return (
      <>
        <TitleRow padding={'0'} style={{ marginBottom: '-1rem' }}>
          <BoldWhiteText style={{ justifySelf: 'flex-start' }}>
            { header }
          </BoldWhiteText>
        </TitleRow>
        {width && width >= 600 ? (

          <AutoRow gap="2px">
            <TopRoundShadowCard>
            <VaultFarmCard farm={farmLists[0]} />
            </TopRoundShadowCard>
            {farmLists.map((item, i) =>
              i > 0 && i < farmLists.length - 1 ? (<VaultFarmCard farm={item} />) : <></>
            )}

            <BottomRoundShadowCard>
              <VaultFarmCard farm={farmLists[farmLists.length - 1]} />
            </BottomRoundShadowCard>
          </AutoRow>
        ) : (
          <>
            {farmLists.map((item, i) =>
              i >= 0 ? (
                <AutoRow>
                  <RoundShadowCard>
                  <VaultFarmCard farm={item} />
                  </RoundShadowCard>
                </AutoRow>
              ) : <></>
            )}
          </>
        )}
      </>
    )
  }  

  function LegacyVaultFarmRows({header, farmLists} : FarmRowsProps) {
    return (
      <>
        <TitleRow padding={'0'} style={{ marginBottom: '-1rem' }}>
          <BoldWhiteText style={{ justifySelf: 'flex-start' }}>
            { header }
          </BoldWhiteText>
        </TitleRow>
        {width && width >= 600 ? (

          <AutoRow gap="2px">
            <TopRoundShadowCard>
            <VaultFarmCard farm={farmLists[0]} />

            </TopRoundShadowCard>
            {/* {farmLists.map((item, i) =>
              i > 0 && i < farmLists.length - 1 ? (<VaultFarmCard farm={item} />) : <></>
            )} */}

            <BottomRoundShadowCard>
              {/* <VaultFarmCard farm={farmLists[farmLists.length - 1]} /> */}
              <>
              &nbsp;
              </>              
            </BottomRoundShadowCard>
          </AutoRow>
        ) : (
          <>
            {farmLists.map((item, i) =>
              i >= 0 ? (
                <AutoRow>
                  <RoundShadowCard>
                  <VaultFarmCard farm={item} />
                  </RoundShadowCard>
                </AutoRow>
              ) : <></>
            )}
          </>
        )}
      </>
    )
  }  

  return (
    <PageWrapper>
      <SwapPoolTabs active={'pool'} />
      <RoundShadowCard>
        <AutoRow>
          <CardBGImage url={darkMode ? bgImageDark : bgImage} />
          <CardSection>
            <AutoColumn gap="md">
              <LogoBox><CardLogo src={darkMode ? logodark : logo} /></LogoBox>
            </AutoColumn>
          </CardSection>
        </AutoRow>
      </RoundShadowCard>

      <AutoColumn gap="lg" justify="center">
        <AutoColumn gap="lg" style={{ width: '100%' }}>
          <TitleRow style={{ marginTop: '1rem', marginBottom: '-1rem' }} padding={'0'}>
            <BoldWhiteText style={{ marginTop: '0.5rem', justifySelf: 'flex-start' }}>
              ALE Maximizer - Stake ALE to earn ALE
            </BoldWhiteText>
          </TitleRow>
          <RoundShadowCard>
            <FarmRow farm={FARM_LISTS[0]} />
          </RoundShadowCard>
          <TitleRow padding={'0'} style={{ marginBottom: '-1rem' }}>
            <BoldWhiteText style={{ justifySelf: 'flex-start' }}>
              ALE Holder Pool - Stake ALE to earn BNB
            </BoldWhiteText>
          </TitleRow>
          <RoundShadowCard>
            <StakingRewardsCard />
          </RoundShadowCard>

          <FarmRows header="Farms - Stake ALE Pair to earn ALE" farmLists={coreFarmLists} />

          <VaultFarmRows header="Yield Maximizer - Stake Vault Barrel to earn ALE" farmLists={vaultFarmLists} />       

          <FarmRows header="Farms - Stake Stablecoins LPs to earn ALE" farmLists={stableFarmLists} />

          {/* <FarmRows header="Farms - Stake AleSwap LPs to earn ALE" farmLists={[...partnerFarmLists]} /> */}

          <LegacyVaultFarmRows header="Legacy Boost Vault" farmLists={legacyVaultFarmLists} />       

          <FarmRows header="Legacy Farm" farmLists={[...legacyFarmLists]} />


          {/* <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
            <BoldWhiteText style={{ marginTop: '0.5rem', justifySelf: 'flex-start' }}>
              Legacy Farm
            </BoldWhiteText>

          </TitleRow>
          <AutoRow gap="1px">
            <TopRoundShadowCard>
              <FarmRow poolName={legacyFarmLists[0].name} poolId={legacyFarmLists[0].id} chainId={chainId}></FarmRow>
            </TopRoundShadowCard>

            {legacyFarmLists.map((item, i) =>
              i >= 1 && i < legacyFarmLists.length - 1 ? (<FarmRow poolName={item.name} poolId={item.id} chainId={chainId}></FarmRow>) : <></>
            )
            }
            <BottomRoundShadowCard>
              <FarmRow poolName={legacyFarmLists[legacyFarmLists.length - 1].name} poolId={legacyFarmLists[legacyFarmLists.length - 1].id} chainId={chainId}></FarmRow>
            </BottomRoundShadowCard>
          </AutoRow> */}

        </AutoColumn>
      </AutoColumn>
    </PageWrapper>
  )
}
