import { ChainId } from "@uniswap/sdk";

export const allocsPoint = [
  46083,
  11000, // 1
  0,
  10750, // 3
  0, // 4
  0, // 5 
  0, // 6
  0, // 7
  0, // 8
  375, // CRAFT-BUSD // 9
  100263, // MITR-DVN
  12500, // ALE-CRAFT // 11
  112, // 12 CRAFT-UST 
  375, // 13
  375, // 14
  500, // 15
  500, // 16
  500, // 17
  500, // 18 ALE-JDI
  500 // 19 ALE-ZFARM
];

export interface FarmInfo {
  id: number
  name: string
  bonus: string
  startTime: string
  farmType: string
}

export const FARM_LISTS : FarmInfo[] = [
    { id: 0, name: 'ALE',bonus: "45",startTime:"", farmType: ""  },
    { id: 1, name: 'ALE-BNB',bonus: "110",startTime:"", farmType: "core"  },
    { id: 3, name: 'ALE-BUSD',bonus: "108",startTime:"", farmType: "core"  },
    { id: 11, name: 'ALE-CRAFT',bonus: "125",startTime:"", farmType: "core"  },


    { id: 9, name: 'CRAFT-BUSD',bonus: "3.8",startTime:"", farmType: "stable"  },
    // { id: 12, name: 'CRAFT-UST',bonus: "1.2",startTime:"", farmType: "stable"  },
    { id: 13, name: 'CRAFT-USDT',bonus: "3.8",startTime:"", farmType: "stable"  },
    { id: 14, name: 'CRAFT-USDC',bonus: "3.8",startTime:"", farmType: "stable"  },
    { id: 15, name: 'ETH-CRAFT',bonus: "",startTime:"", farmType: "legacy"  },
    { id: 16, name: 'BNB-CRAFT',bonus: "",startTime:"", farmType: "legacy"  },
    { id: 17, name: 'BTCB-CRAFT',bonus: "",startTime:"", farmType: "legacy"  },
    { id: 26, name: 'Barrel Dopple 4 Pool',bonus: "",startTime:"2021-09-08T11:00:00+07:00", farmType: "legacy_vault"  },
    // { id: 27, name: 'Barrel Dop KUSD-BUSD-USDT',bonus: "",startTime:"2021-09-08T11:00:00+07:00", farmType: "legacy_vault"  },
    { id: 18, name: 'ALE-JDI',bonus: "2.5",startTime:"2021-06-29T20:00:00+07:00", farmType: "lecagy"  },              
    { id: 19, name: 'ALE-ZFARM',bonus: "",startTime:"2021-07-10T20:00:00+07:00", farmType: "legacy"  },         

    { id: 22, name: 'Barrel Definix BNB-BUSD',bonus: "5",startTime:"2021-09-01T11:00:00+07:00", farmType: "vault"  },
    { id: 23, name: 'Barrel Definix BTCB-BNB',bonus: "5",startTime:"2021-09-01T11:00:00+07:00", farmType: "vault"  },
    { id: 24, name: 'Barrel Definix ETH-BNB',bonus: "5",startTime:"2021-09-01T11:00:00+07:00", farmType: "vault"  },
    { id: 25, name: 'Barrel Biswap USDT-USDC',bonus: "5",startTime:"2021-09-01T11:00:00+07:00", farmType: "vault"  },
    
  ];

  // 

  export const legacyFarmLists = [
     
    { id: 4, name: 'BNB-BUSD',bonus: "5",apr:200,allocPoint:1000  },
    // { id: 5, name: 'BTCB-BNB',bonus: "5",apr:200,tvl:30000000  },
    { id: 6, name: 'ETH-BNB',bonus: "5",apr:200,tvl:30000000  },
    { id: 7, name: 'USDT-BUSD',bonus: "5",apr:200,tvl:30000000  },
    // { id: 8, name: 'UST-BUSD',bonus: "5",apr:200,tvl:30000000  },
  ];  
  
  export const tokenLists = [
    { id: 0, name: 'XXX',rate: 1.6,priceInBusd : 0, address: '0x29e7CEA3DedcA5984780Bafc599bD69ADd087D56' },
    { id: 1, name: 'BUSD',rate: 1.6,priceInBusd : 0, address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56' },
    { id: 2, name: 'USDT',rate: 1.6,priceInBusd : 0, address: '0x55d398326f99059fF775485246999027B3197955' },
    { id: 3, name: 'BNB',rate: 960,priceInBusd : 0, address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c' },
    { id: 4, name: 'BTCB',rate: 80000,priceInBusd : 0, address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c' },
    { id: 5, name: 'ETH',rate: 6400,priceInBusd : 0, address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8' },
    { id: 6, name: 'ALE',rate: 3.2,priceInBusd : 0, address: '0x99CA242f20424a6565cc17a409E557FA95E25BD7' },
    { id: 7, name: 'UST',rate: 51.2,priceInBusd : 0, address: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC' },
    { id: 8, name: 'DUMMY',rate: 51.2,priceInBusd : 0, address: '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D' },
    { id: 9, name: 'CRAFT',rate: 51.2,priceInBusd : 0, address: '0x19Ea6042ca81bcd1FEC329004Fd5967AFdC6745e' },
    { id: 10, name: 'USDC',rate: 51.2,priceInBusd : 0, address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d' },
    { id: 11, name: 'JDI',rate: 51.2,priceInBusd : 0, address: '0x0491648C910ad2c1aFaab733faF71D30313Df7FC' },
    { id: 12, name: 'ZFARM',rate: 51.2,priceInBusd : 0, address: '0x42d1B21EaBE04D308148eA9Ab90Be674b64b4eef' },
    { id: 13, name: 'ADA',rate: 51.2,priceInBusd : 0, address: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47' },
    { id: 14, name: 'WAD',rate: 51.2,priceInBusd : 0, address: '0x0fEAdcC3824E7F3c12f40E324a60c23cA51627fc' },
    { id: 15, name: 'FINIX',rate: 51.2,priceInBusd : 0, address: '0x0F02b1F5AF54E04Fb6dd6550f009aC2429C4e30D' },
    { id: 16, name: 'BSW',rate: 51.2,priceInBusd : 0, address: '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1' },
    { id: 17, name: 'SIX',rate: 51.2,priceInBusd : 0, address: '0x070a9867Ea49CE7AFc4505817204860e823489fE' },
    { id: 18, name: 'BANANA',rate: 51.2,priceInBusd : 0, address: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95' },
    { id: 19, name: 'DOPX',rate: 51.2,priceInBusd : 0, address: '0x802A183ac9F6b082716DFeE55432ed0C04ACB49a' },
    { id: 20, name: 'KUSD',rate: 51.2,priceInBusd : 0, address: '0x940Ff63e82d15fb47371BFE5a4ed7D7D183dE1A5' },
    { id: 21, name: 'TWX',rate: 51.2,priceInBusd : 0, address: '0x41171D5770C4c68686d1aF042Ada88a45B02f82b' },
    { id: 22, name: 'tJPY',rate: 51.2,priceInBusd : 0, address: '0x85B70a8742A08aEcbc1E3820F808137Fd8a3fB6D' },
    { id: 23, name: 'tEUR',rate: 51.2,priceInBusd : 0, address: '0x05E8766d04a226017357E10c6D6c47c8B2641685' },
    { id: 24, name: 'tXAU',rate: 51.2,priceInBusd : 0, address: '0xf89E68C176282095030401E86BA36A36F94cC08c' },        
    { id: 25, name: 'tAAPL',rate: 51.2,priceInBusd : 0, address: '0xfE5086447a2622a2C20FAF2D2D3E3986A517EB70' },
    { id: 26, name: 'tTSLA',rate: 51.2,priceInBusd : 0, address: '0x2aDDd90144512c001b5943651cc90E2f7c29A91D' },      
    { id: 27, name: 'tTSM',rate: 51.2,priceInBusd : 0, address: '0x743B1D38c2d0824fd6b5a1171EA599723c713693' },      
    { id: 28, name: 'tARKK',rate: 51.2,priceInBusd : 0, address: '0xfb8447B43a3d1B8eeD3E6F18aD4ADe37B5A7F352' },          
    { id: 29, name: 'tMRNA',rate: 51.2,priceInBusd : 0, address: '0x00A501D01df1DF33a5A547f4A2aF1eD83F13DC01' },          
    { id: 30, name: 'CAKE',rate: 51.2,priceInBusd : 0, address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82' },          
    

  ];  


  export const tokenTestLists = [
    { id: 0, name: 'XXX',rate: 1.6,priceInBusd : 0, address: '0x19e7CEA3DedcA5984780Bafc599bD69ADd087D56' },
    { id: 1, name: 'BUSD',rate: 1.6,priceInBusd : 0, address: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee' },
    { id: 2, name: 'USDT',rate: 1.6,priceInBusd : 0, address: '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684' },
    { id: 3, name: 'BNB',rate: 960,priceInBusd : 0, address: '0xae13d989dac2f0debff460ac112a837c89baa7cd' },
    { id: 4, name: 'BTCB',rate: 80000,priceInBusd : 0, address: '0x1130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c' },
    { id: 5, name: 'ETH',rate: 6400,priceInBusd : 0, address: '0x8BaBbB98678facC7342735486C851ABD7A0d17Ca' },
    { id: 6, name: 'ALE',rate: 3.2,priceInBusd : 0, address: '0x004aC6977B4f6382B0798A9123D7A797f5260A5D' },
    { id: 7, name: 'UST',rate: 51.2,priceInBusd : 0, address: '0x13396cF899Ca06c4472205fC903bDB4de249D6fC' },
    { id: 8, name: 'DUMMY',rate: 51.2,priceInBusd : 0, address: '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D' }
  ];  

  export function getTokenByName(tokenName : String, chainId? : ChainId )
  {
    let tokens = tokenLists;
    if (chainId && chainId === ChainId.BSCTEST) 
    {
      tokens = tokenTestLists;
    }

    var i;
    for (i = 0; i < tokens.length; i++) {
        let token = tokens[i]
        if (token.name === tokenName) return token;
    }
    return tokens[0]
  }

  export function getTokenByAddress(tokenAddress : String, chainId? : ChainId)
  {
    // let tokens = tokenLists;
    // if (chainId && chainId === ChainId.BSCTEST) 
    // {
    //   tokens = tokenTestLists;
    // }

    var i;
    for (i = 0; i < tokenLists.length; i++) {
        let token = tokenLists[i]
        if (token.address === tokenAddress) return token;
    }
    return ''
  }  