import { TokenAmount, Pair, Currency, Token } from '@uniswap/sdk'
import { useMemo } from 'react'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Interface } from '@ethersproject/abi'
import { useActiveWeb3React } from '../hooks'

import { useMultipleContractSingleData, useSingleCallResult } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { getAggregatePairAddress, getPairAddressWithInitCode } from 'utils/aggreator'
import { usePairContract, useTokenContract } from 'hooks/useContract'
import { ChainId } from '../constants'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        if (chainId === ChainId.REI) {
          const initCode = "0x93aeddd7aaf5c5779e9e9db1528a4cd63f05cc34802a19ba5fd92a39e1e8ab5a"
          const factory = "0xC437190E5c4F85EbBdE74c86472900b323447603"
          console.log(tokenA?.symbol,tokenB?.symbol)
          
          return tokenA && tokenB && !tokenA.equals(tokenB) ? getPairAddressWithInitCode(tokenA,tokenB,factory,initCode) : undefined
        }
        else return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
      }),
    [chainId, tokens]
  )
  // console.log("pairAddresses: ",pairAddresses);
  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens])
}

export function usePredefinedPairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        //return tokenA && tokenB && !tokenA.equals(tokenB) ? getPredefinedPairAddress(tokenA, tokenB) : undefined
        return tokenA && tokenB && !tokenA.equals(tokenB) ? getAggregatePairAddress(tokenA, tokenB, chainId) : undefined
      }),
    [chainId, tokens]
  )

//  console.log("pairAddresses",pairAddresses)

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}

export function usePredefinedPair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePredefinedPairs([[tokenA, tokenB]])[0]
}

export function usePairFromLpAddress(lpAddress : string,currencyA?: Currency, currencyB?: Currency): [PairState, Pair | null] {
  const { chainId } = useActiveWeb3React()
  const pairContract = usePairContract(lpAddress)

  const results = useSingleCallResult(pairContract, 'getReserves',[])

  return useMemo(() => {
      
      const { result: reserves, loading } = results
      const tokenA = wrappedCurrency(currencyA, chainId)
      const tokenB = wrappedCurrency(currencyB, chainId)

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
  }, [chainId, currencyA, currencyB, results])
  
}


