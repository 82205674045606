import React, { useRef } from 'react';
import { bool } from 'prop-types';
import { NavBar, StyledMenu, StyledNavLink } from './NewMenu.styled';
import { CraftIcon, FarmIcon, HomeIcon, IfoIcon, InfoIcon, LiquidityIcon, TradeIcon, VaultIcon } from './icons';
import { useActiveWeb3React } from 'hooks';
import { ChainId } from '../../constants'

interface SlideMenuProps {
    isOpen: boolean
    setOpen: (_open: boolean) => void
  }

const SlideMenu = ({ isOpen,setOpen } : SlideMenuProps) => {
  const { chainId } = useActiveWeb3React()
    
  return (
    <StyledMenu open={isOpen}>

      { chainId === ChainId.MAINNET && window.location.hostname !== "rei.aleswap.finance" ? (
        <>
          <StyledNavLink to={'/home'} onClick={() => setOpen(false)}><HomeIcon/>Home</StyledNavLink>        
          <StyledNavLink to={'/swap'} onClick={() => setOpen(false)}><TradeIcon/>Exchange</StyledNavLink>
          <StyledNavLink to={'/pool'} onClick={() => setOpen(false)}><LiquidityIcon/> Liquidity</StyledNavLink>
          <StyledNavLink to={'/farm'} onClick={() => setOpen(false)}><FarmIcon/>Farms</StyledNavLink>
          <StyledNavLink to={'/craft'} onClick={() => setOpen(false)}><CraftIcon/>Craft Stablecoin</StyledNavLink>
          <StyledNavLink to={'/vault'} onClick={() => setOpen(false)}><VaultIcon/>Vault</StyledNavLink>
        </>) : (<></>) }
      <StyledNavLink to={'/redeem'} onClick={() => setOpen(false)}><IfoIcon/>Spend</StyledNavLink>
      {/* <StyledNavLink to={'/merchant'} onClick={() => setOpen(false)}><IfoIcon/>Merchant</StyledNavLink> */}
      <StyledNavLink to={'/info'} onClick={() => setOpen(false)}><InfoIcon/>Info</StyledNavLink>
    </StyledMenu>
  )
}

export const AllMenu = () => {
  const { chainId } = useActiveWeb3React()
  return (
    <NavBar>
      { chainId === ChainId.MAINNET && window.location.hostname !== "rei.aleswap.finance" ? (
        <>
          <StyledNavLink to={'/home'}><HomeIcon/>Home</StyledNavLink>        
          <StyledNavLink to={'/swap'}><TradeIcon />Exchange</StyledNavLink>
          <StyledNavLink to={'/pool'}><LiquidityIcon /> Liquidity</StyledNavLink>
          <StyledNavLink to={'/farm'}><FarmIcon />Farms</StyledNavLink>
          <StyledNavLink to={'/craft'}><CraftIcon />Craft Stablecoin</StyledNavLink>
          <StyledNavLink to={'/vault'}><VaultIcon />Vault</StyledNavLink>
        </>) : (<></>)}
      <StyledNavLink to={'/redeem'}><IfoIcon/>Spend</StyledNavLink>
      {/* <StyledNavLink to={'/merchant'}><IfoIcon/>Merchant</StyledNavLink> */}
      <StyledNavLink to={'/info'}><InfoIcon/> Info</StyledNavLink>
    </NavBar>
  )
}

// NewMenu.propTypes = {
//     isOpen: bool.isRequired,
//   }

export default SlideMenu;