import useSWR from 'swr'
import { Vault } from './vault'

export function useALEPrice(): string | undefined {
    const { data,  } = useSWR('https://api.aleswap.finance/info')
  
    if (!data) return undefined
    return parseFloat(data.alePrice).toFixed(5)

}

export function useTVL(): string | undefined {
    const { data, } = useSWR('https://api.aleswap.finance/info')
    if (!data) return undefined
    return data.tvl

}

export function useRewardPerBlock(): number {
    const { data, } = useSWR('https://api.aleswap.finance/info')
    if (!data) return 19
    return parseInt(data.rewardPerBlock)
}

export function useVaultsData() {
    const { data, } = useSWR('https://api.aleswap.finance/vaults')
    if (!data) return undefined
    return data.vaults
}

export function usePrivateVaultsData() {
  const { data, } = useSWR('https://api.aleswap.finance/vaults/private')
  if (!data) return undefined
  return data.vaults
}

export function useFarmsData() {
    const { data, } = useSWR('https://api.aleswap.finance/farms')
    if (!data) return undefined
    return data.farms
}

export function usePoolsData() {
    const { data, } = useSWR('https://api.aleswap.finance/pools')
    if (!data) return undefined
    return data.pools
}

export function useVaultData(vault: Vault) {
  const vaultsData = useVaultsData()

  if (!vaultsData) return undefined
  for (let i = 0; i < vaultsData.length;i++ ) {
    if (vaultsData[i].vault === vault.vaultAddress) return vaultsData[i]
  }
  return undefined
}


export function usePrivateVaultData(vault: Vault) {
  const vaultsData = usePrivateVaultsData()

  if (!vaultsData) return undefined
  for (let i = 0; i < vaultsData.length;i++ ) {
    if (vaultsData[i].vault === vault.vaultAddress) return vaultsData[i]
  }
  return undefined
}

export function useFarmDataFromPairAddress(pairAddress : string) {
    const farmsData = useFarmsData()
  
    if (!farmsData) return undefined
    for (let i = 0; i < farmsData.length;i++ ) {
      if (farmsData[i].pair === pairAddress) return farmsData[i]
    }
    return undefined
}

export function useFarmDataFromPoolID(poolID: number) {
    const farmsData = useFarmsData()
  
    if (!farmsData) return undefined
    for (let i = 0; i < farmsData.length;i++ ) {
      if (farmsData[i].pid === poolID) return farmsData[i]
    }
    return undefined
}

export function useMaxFarmAPR() {
    const farmsData = useFarmsData()
    let apr = 0

    if (!farmsData) return undefined
    for (let i = 0; i < farmsData.length;i++ ) {
      if (farmsData[i].apr > apr) apr = farmsData[i].apr
    }
    return apr
}


export function useVaultTVL(): string | undefined {
  const { data, } = useSWR('https://api.aleswap.finance/vaults')
  if (!data) return undefined
  return data.tvl

}

export function useMaxVaultAPR() {
  const vaultsData = useVaultsData()
  let apy = 0

  if (!vaultsData) return undefined
  for (let i = 0; i < vaultsData.length;i++ ) {
    if (vaultsData[i].apy > apy) apy = vaultsData[i].apy
  }
  return apy
}

