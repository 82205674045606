import { TransactionResponse } from '@ethersproject/providers'
import { TokenAmount } from '@uniswap/sdk'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Vault } from 'utils/vault'
import { useActiveWeb3React } from '../../hooks'
import { useAleStakingRewardsContract, useBrewmasterContract, useVaultContract } from '../../hooks/useContract'
import { AleStakingInfo } from '../../state/stake/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { CloseIcon, TYPE } from '../../theme'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import Modal from '../Modal'
import { LoadingView, SubmittedView } from '../ModalViews'
import { RowBetween } from '../Row'


const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: AleStakingInfo
}

export default function UnstakingModal({ isOpen, onDismiss, stakingInfo }: StakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  // const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)
  const brewmasterContract = useBrewmasterContract()

  async function onWithdraw() {
    if (brewmasterContract && stakingInfo?.stakedAmount) {
      setAttempting(true)
      await brewmasterContract
        .withdraw(stakingInfo.poolId,`0x${stakingInfo.stakedAmount.raw.toString(16)}`, { gasLimit: 300000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? 'Enter an amount'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          {stakingInfo?.stakedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />}
              </TYPE.body>
              <TYPE.body>Deposited liquidity:</TYPE.body>
            </AutoColumn>
          )}
          {stakingInfo?.earnedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />}
              </TYPE.body>
              <TYPE.body>ALE to Harvest</TYPE.body>
            </AutoColumn>
          )}
          {/* <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you withdraw, your ALE is harvested and your liquidity is removed from the mining pool.
          </TYPE.subHeader> */}
          <ButtonError disabled={!!error} error={!!error && !!stakingInfo?.stakedAmount} onClick={onWithdraw}>
            {error ?? 'Withdraw & Harvest'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)} AleSwap LP</TYPE.body>
            <TYPE.body fontSize={20}>Harvesting {stakingInfo?.earnedAmount?.toSignificant(4)} ALE</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Withdrew AleSwap LP</TYPE.body>
            <TYPE.body fontSize={20}>Harvested ALE</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}


interface VaultStakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  vault: Vault
  stakingInfo: AleStakingInfo
}

export function VaultUnstakingModal({ isOpen, onDismiss, stakingInfo, vault }: VaultStakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const vaultContract = useVaultContract(vault.vaultAddress)

  async function onWithdraw() {
    if (vaultContract && stakingInfo?.stakedAmount) {
      setAttempting(true)
      await vaultContract
        .withdrawAll({ gasLimit: 450000 })
        //.withdraw(stakingInfo.poolId,`0x${stakingInfo.stakedAmount.raw.toString(16)}`, { gasLimit: 300000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? 'Enter an amount'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          {stakingInfo?.stakedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />}
              </TYPE.body>
              <TYPE.body>Deposited liquidity:</TYPE.body>
            </AutoColumn>
          )}
          {stakingInfo?.earnedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />}
              </TYPE.body>
              <TYPE.body>ALE to Claim</TYPE.body>
            </AutoColumn>
          )}
          {/* <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you withdraw, your ALE is harvested and your liquidity is removed from the mining pool.
          </TYPE.subHeader> */}
          <ButtonError disabled={!!error} error={!!error && !!stakingInfo?.stakedAmount} onClick={onWithdraw}>
            {error ?? 'Withdraw & Claim'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)} AleSwap LP</TYPE.body>
            <TYPE.body fontSize={20}>Harvesting {stakingInfo?.earnedAmount?.toSignificant(4)} ALE</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Withdrew AleSwap LP</TYPE.body>
            <TYPE.body fontSize={20}>Harvested ALE</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}


interface SingleStakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakedAmount: TokenAmount | undefined
  earnedAmount: TokenAmount | undefined
}

export function SingleUnstakingModal({ isOpen, onDismiss, stakedAmount, earnedAmount }: SingleStakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const brewmasterContract = useBrewmasterContract()

  async function onWithdraw() {
    if (brewmasterContract && stakedAmount) {
      setAttempting(true)
      await brewmasterContract
        .leaveStaking(`0x${stakedAmount.raw.toString(16)}`, { gasLimit: 300000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited ALE`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  // if (!stakingInfo?.stakedAmount) {
  //   error = error ?? 'Enter an amount'
  // }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          {stakedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakedAmount} />}
              </TYPE.body>
              <TYPE.body>Deposited ALE:</TYPE.body>
            </AutoColumn>
          )}
          {earnedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={earnedAmount} />}
              </TYPE.body>
              <TYPE.body>ALE to Harvest</TYPE.body>
            </AutoColumn>
          )}
          {/* <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you withdraw, your ALE is harvested and your liquidity is removed from the mining pool.
          </TYPE.subHeader> */}
          <ButtonError disabled={!!error} error={!!error && !!stakedAmount} onClick={onWithdraw}>
            {error ?? 'Withdraw & Harvest'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Withdrawing {stakedAmount?.toSignificant(4)} AleSwap LP</TYPE.body>
            <TYPE.body fontSize={20}>Harvesting {earnedAmount?.toSignificant(4)} ALE</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Withdrew AleSwap LP</TYPE.body>
            <TYPE.body fontSize={20}>Harvested ALE</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}



interface EarnBNBStakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakedAmount: TokenAmount | undefined
  earnedAmount: TokenAmount | undefined
}

export function EarnBNBUnstakingModal({ isOpen, onDismiss, stakedAmount, earnedAmount }: EarnBNBStakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const stakingRewardsContract = useAleStakingRewardsContract()

  async function onWithdraw() {
    if (stakingRewardsContract && stakedAmount) {
      setAttempting(true)
      await stakingRewardsContract
        .exitNative({ gasLimit: 300000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited ALE`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  // if (!stakingInfo?.stakedAmount) {
  //   error = error ?? 'Enter an amount'
  // }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          {stakedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakedAmount} />}
              </TYPE.body>
              <TYPE.body>Deposited ALE:</TYPE.body>
            </AutoColumn>
          )}
          {earnedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={earnedAmount} />}
              </TYPE.body>
              <TYPE.body>BNB to Harvest</TYPE.body>
            </AutoColumn>
          )}
          {/* <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you withdraw, your ALE is harvested and your liquidity is removed from the mining pool.
          </TYPE.subHeader> */}
          <ButtonError disabled={!!error} error={!!error && !!stakedAmount} onClick={onWithdraw}>
            {error ?? 'Withdraw & Harvest'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Withdrawing {stakedAmount?.toSignificant(4)} AleSwap LP</TYPE.body>
            <TYPE.body fontSize={20}>Harvesting {earnedAmount?.toSignificant(4)} BNB</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Withdrew AleSwap LP</TYPE.body>
            <TYPE.body fontSize={20}>Harvested BNB</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
