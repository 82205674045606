import React, { Component } from 'react'
// import {Link} from 'react-scroll'
import HopsBg from '../../assets/images/hops-bg.png';
import VaultBg from '../../assets/images/Vault-bg.png';
import VaultBgDark from '../../assets/images/Vault-bg-dark.png';


import styled, { css } from "styled-components";
import { useIsDarkMode } from 'state/user/hooks';

export default function NewFeatureBox()  {
  const darkMode = useIsDarkMode()
  return (
      
      <FeatureBox background={darkMode ? VaultBgDark : VaultBg}>
         
          <FeatureTopText>New Feature!!</FeatureTopText>
          <FeatureMidText>VAULT</FeatureMidText>
          <NormalText>What is it & How to</NormalText>
          <LearnButton><a href="#/vault"><OrButton>Learn More</OrButton></a></LearnButton>
          
      </FeatureBox>
  )
}


const FeatureBox = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  font-weight:bold;

  min-width:300px;
height:250px;
 width:100%;
 background-image: url(${({ background }) => background}); 
background-repeat: no-repeat;
background-position:bottom right;
background-size: auto;
background-color: ${({ theme }) => theme.panelBG};
color: ${({ theme }) => theme.panelText1};
box-shadow: 0px 1px 1px #00000040;
border-radius: 5px;

  align-items: center;
  justify-content: center;
  
`;







const FeatureTopText = styled.div`

  font-weight:600;
  font-size:28px;
  
  align-self:center;
    
`;

const FeatureMidText = styled.div`
 
  font-weight:bold;
  font-size:60px;
  line-height:80px;
  color:${({ theme }) => theme.panelText2};
  align-self:center;
  
    
`;

const NormalText = styled.div`

align-self:center;
color: ${({ theme }) => theme.panelText1};
    
`;

const LearnButton = styled.div`

align-self:center;
  
    
`;

const OrButton = styled.button`
background-color:${({ theme }) => theme.panelButtonBG};
color:#ffffff;
font-weight:700;
font-size:14px;
border-radius: 5px;
border:0px;
align-self:center;

font-family:'Titillium Web';
padding-left:5px;
padding-right:5px;
padding-bottom:4px;
margin-top:10px;

&:hover {
  
  box-shadow: 0 0 0 3pt #195BCB;
  cursor: pointer;
}

@media (max-width: 414px) {
  font-size:16px;
  
min-width:120px;
  
}
`
  ;




  

  ;