import React from 'react';
import { useIsDarkMode } from 'state/user/hooks';
import styled from "styled-components";
import { useMaxFarmAPR } from 'utils/aleSwapAPI';
import AprBgDark from '../../assets/images/Apr-bg-dark.png';
import AprBg from '../../assets/images/Apr-bg.png';

export default function AprBox()  {
  const darkMode = useIsDarkMode()
  const maxFarmAPR = useMaxFarmAPR()

  return (
      
    <WhiteCard background={darkMode ? AprBgDark : AprBg}>
      <TopText>Stake LP tokens in <br />FARM and earn up to</TopText>

      <BlueBoldText>{maxFarmAPR ? maxFarmAPR.toFixed(1) : '128.5'}%</BlueBoldText>
      <div></div>
      <ButtonRow><a href="#/farm"><OrButton>Start Farming</OrButton></a></ButtonRow>
    </WhiteCard>
 )
}



const WhiteCard = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  font-weight:bold;
  width:100%;
  background-image: url(${({ background }) => background}); 
  min-width:300px;
  height:250px;
background-repeat: no-repeat;
background-position:bottom right;
background-size: auto;
background-color: ${({ theme }) => theme.panelBG};
color: ${({ theme }) => theme.panelText1};
box-shadow: 0px 1px 1px #00000040;
border-radius: 5px;
align-items: center;
justify-content: center;

  
`;

const TopText = styled.div`
 
  font-weight:600;
  font-size:24px;
  
  align-self:center;
    
`;

const BlueBoldText = styled.div`
  
  font-weight:bold;
  font-size:48px;
  
  color:${({ theme }) => theme.panelText2};
  align-self:center;
    
`;

const ButtonRow = styled.div`
  
  
  align-self:center;
    
`;

const OrButton = styled.button`
background-color:${({ theme }) => theme.panelButtonBG};


color:#ffffff;
font-weight:700;
font-size:14px;
border-radius: 5px;
border:0px;

font-family:'Titillium Web';
padding-left:5px;
padding-right:5px;
padding-bottom:4px;
margin-top:10px;

&:hover {
  
  box-shadow: 0 0 0 3pt #347af0;
  cursor: pointer;
}

@media (max-width: 414px) {
  font-size:16px;
  
min-width:120px;

}
`
  ;




  

  ;