import { Currency, ETHER, Token } from '@uniswap/sdk'
import { ChainId } from '../../constants'
import { useActiveWeb3React } from 'hooks'
import React, { useMemo } from 'react'
import styled from 'styled-components'

// import EthereumLogo from '../../assets/images/ethereum-logo.png'
import BinanceLogo from '../../assets/images/tokens/bnb.png'
import ReiLogo from '../../assets/images/tokens/REI.svg'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import { ETHER_REI } from 'hooks/Tokens'

export const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${address}/logo.png`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  // box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  background-color: ${({ theme }) => theme.white};
  
`

  // box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const { chainId } = useActiveWeb3React()
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency?.symbol === "ALE") return ["https://raw.githubusercontent.com/aleswap-finance/token-list/main/images/ale.png"]
    if (currency?.symbol === "CRAFT") return ["https://raw.githubusercontent.com/aleswap-finance/token-list/main/images/craft.png"]
    

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }
      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER && chainId === ChainId.MAINNET) {
    return <StyledEthereumLogo src={BinanceLogo} size={size} style={style} />
  }
  if (currency === ETHER_REI && chainId === ChainId.REI) {
    return <StyledEthereumLogo src={ReiLogo} size={size} style={style} />
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
