import { TransactionResponse } from '@ethersproject/providers'
import { Pair, Token, TokenAmount } from '@uniswap/sdk'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Vault } from 'utils/vault'
import { useActiveWeb3React } from '../../hooks'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useVaultContract } from '../../hooks/useContract'
// import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
// import { splitSignature } from 'ethers/lib/utils'
import { AleStakingInfo, useDerivedStakeInfo } from '../../state/stake/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { CloseIcon, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
// import ProgressCircles from '../ProgressSteps'
import { LPTokenInputPanel } from '../CurrencyInputPanel'
import Modal from '../Modal'
import { LoadingView, SubmittedView } from '../ModalViews'
import { RowBetween } from '../Row'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface VaultStakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo?: AleStakingInfo
  vault: Vault
  lpToken: Token
  pair?: Pair | null
  userLiquidityUnstaked: TokenAmount | undefined
}

export function VaultStakingModal({ isOpen, onDismiss, stakingInfo, vault, pair, lpToken, userLiquidityUnstaked }: VaultStakingModalProps) {
  const { chainId } = useActiveWeb3React()

  // const vaultAddress = '0xFE663A7534BB00a54fE676ab7a4a15829Ab5DE1c';
  // const vaultAddress = '0x7a135E1b8597c8E7faF8b5A6634466cc23626f12';
  // const lpToken = new Token(56,vault.lpTokenAddress,18);

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  // const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo?.stakedAmount.token, userLiquidityUnstaked)
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, lpToken , userLiquidityUnstaked)
  const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)

  // let hypotheticalRewardRate: TokenAmount = new TokenAmount(stakingInfo.rewardRate.token, '0')
  if (parsedAmountWrapped?.greaterThan('0')) {
    // hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
    //   stakingInfo.stakedAmount.add(parsedAmountWrapped),
    //   stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
    //   stakingInfo.totalRewardRate
    // )
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  // const dummyPair = stakingInfo ? new Pair(new TokenAmount(stakingInfo.tokens[0], '0'), new TokenAmount(stakingInfo.tokens[1], '0')) : undefined
  // const pairContract = usePairContract(dummyPair?.liquidityToken.address)

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  // const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)
  const [approval] = useApproveCallback(parsedAmount, vault.vaultAddress)

  // const isArgentWallet = useIsArgentWallet()
  // const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)
  // const brewmasterContract = useBrewmasterContract()
  const vaultContract = useVaultContract(vault.vaultAddress)
  
  async function onStake() {
    setAttempting(true)
    if (vaultContract && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        if (atMaxAmount) {
          vaultContract.depositAll({ gasLimit: 600000 }) 
          .then((response: TransactionResponse) => {
                  addTransaction(response, {
                    summary: `Stake liquidity`
                  })
                  setHash(response.hash)
                })
                .catch((error: any) => {
                  setAttempting(false)
                  console.log(error)
                })
        } else {
          vaultContract.deposit(`0x${parsedAmount.raw.toString(16)}`, { gasLimit: 600000 }) 
          .then((response: TransactionResponse) => {
                  addTransaction(response, {
                    summary: `Stake liquidity`
                  })
                  setHash(response.hash)
                })
                .catch((error: any) => {
                  setAttempting(false)
                  console.log(error)
                })
        }
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }

    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])


  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Stake</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <LPTokenInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={lpToken}
            pair={null}
            // pair={pair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to stake: '}
            id="stake-liquidity-token"
          />

          {/* <HypotheticalRewardRate dim={!hypotheticalRewardRate.greaterThan('0')}>
            <div>
              <TYPE.black fontWeight={600}>Weekly Rewards</TYPE.black>
            </div>

            <TYPE.black>
              {hypotheticalRewardRate.multiply((60 * 60 * 24 * 7).toString()).toSignificant(4, { groupSeparator: ',' })}{' '}
              UNI / week
            </TYPE.black>
          </HypotheticalRewardRate> */}

          <RowBetween>
            {/* <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
              disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
            >
              Approve
            </ButtonConfirmed> */}
            <ButtonError
              disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? 'Stake'}
            </ButtonError>
          </RowBetween>
          {/* <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} /> */}
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Staking</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} LP</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} LP</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}