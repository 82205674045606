import { ChainId, JSBI, TokenAmount } from '@uniswap/sdk'
import { useWindowSize } from 'hooks/useWindowSize'
import { darken } from 'polished'
import React, { useCallback, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Text } from 'rebass'
import { useWalletModalToggle } from 'state/application/hooks'
import styled from 'styled-components'
import { useFarmDataFromPoolID } from 'utils/aleSwapAPI'
import { SingleClaimRewardModal } from '../../components/earn/ClaimRewardModal'
import { SingleStakingModal } from '../../components/earn/StakingModal'
import { SingleUnstakingModal } from '../../components/earn/UnstakingModal'
import { ALE, BIG_INT_ZERO, BREWMASTER_ADDRESS } from '../../constants'
import { useUserEarned, useUserStaked } from '../../data/TotalStaked'
import { useActiveWeb3React } from '../../hooks'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useColor } from '../../hooks/useColor'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, MEDIA_WIDTHS } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonEmpty, ButtonPrimary } from '../Button'
import Card, { RectLightCard } from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import Row, { AutoRow, RowBetween } from '../Row'
import { Dots } from '../swap/styleds'




export const BonusBox = styled.div`

  background-color: rgba(240,185,11,1);
  border-radius: 12px;
  display: inline;
  height:20px;
  line-height:20px;
  margin-left:5px;
  color: rgba(255,255,255,1);
  padding-left:5px;
  padding-right:5px;
  display:flex;
  align-items: center;
  justify-items: center;
  font-size:12px;
  font-weight:bold;
  
`;


export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const SingleFixedHeightRow = styled(RowBetween)`
  height: 48px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
export const StyledPositionCard = styled(RectLightCard) <{ bgColor: any }>`
  border: none;
  background: ${({ theme }) => theme.itemBG};
  position: relative;
  overflow: hidden;
  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;  
`
export const SingleClickableFixedHeightRow = styled(SingleFixedHeightRow)`
cursor: pointer;
`

export const ClickableFixedHeightRow = styled(FixedHeightRow)`
cursor: pointer;
`

export const PairText = styled(Text)`
  color: ${({ theme }) => theme.tokenText};
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`
export const TVLText = styled(Text)`

  color: #347AF0;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`
export const APRText = styled(Text)`
  color: #347AF0;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`

export const DataText = styled(Text)`
color: ${({ theme }) => theme.aprText};
font-family: var(--font-family-titillium_web);
font-size: 16px;
font-weight: 700;
font-style: normal;
`


export const SubText = styled(Text)`
  color: ${({ theme }) => theme.subText};
  font-family: var(--font-family-titillium_web);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
`

export const DetailText = styled(Text)`
${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

export const PairRow = styled(Row)`
  width:130px;
  // flex-wrap: wrap;  
  flex-direction: row
`

// const alePerBlock = JSBI.BigInt(19)

// const totalAllocPoint = JSBI.BigInt(184333)
// const blockPerDay = JSBI.BigInt(28800)
// const dayPerYear = JSBI.BigInt(365)
// const ONE_HUNDRED = JSBI.BigInt(100)


// interface PairFarmCardProps {
//   //pair: Pair
//   token0: Token
//   token1: Token
//   token0Symbol: string
//   poolId : number
//   showUnwrapped?: boolean
//   border?: string
//   stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
// }


interface SingleFarmCardProps {
  // pair: Pair
  poolId: number
  showUnwrapped?: boolean
  stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
}

export function SingleFarmCard({ poolId, stakedBalance }: SingleFarmCardProps) {
  const { account, chainId } = useActiveWeb3React()

  const { width } = useWindowSize()
  
  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected


  const farmData = useFarmDataFromPoolID(poolId)

  const smallScreen = width && width < MEDIA_WIDTHS.upToSmall ? true : false

  // const toggleFarmStakeModal = useFarmStakeModalToggle()  

  // const allPoolData = useAleStakingInfo()
  // const stakingInfo = useAleStakingInfo(pair)?.[2]

  // const token = pair.token0 
  const token = chainId ? ALE[chainId] : ALE[ChainId.MAINNET]

  // const allocPoint = JSBI.BigInt(allocsPoint[poolId])

  // const alePrice = useBUSDPrice(chainId ? ALE[chainId] : undefined)
  // const alePerBlock = JSBI.BigInt(useRewardPerBlock())

  const currency0 = unwrappedToken(token)
  const rewardToken = unwrappedToken(token)

  const [showMore, setShowMore] = useState(false)

  // const userDefaultPoolBalance = useTokenBalance(account ?? undefined, token)
  // const totalStakedTokens = useTotalStaked(token)

  // const totalValueLocked = totalStakedTokens && alePrice?.quote(totalStakedTokens)


  // const farmAPR = totalValueLocked && JSBI.greaterThan(totalValueLocked.raw, JSBI.BigInt(0)) && alePrice ?
  //   alePrice.divide(totalValueLocked).multiply(blockPerDay).multiply(alePerBlock).multiply(dayPerYear).multiply(allocPoint).divide(totalAllocPoint).multiply(ONE_HUNDRED) : 0


  const userLPBalance = useTokenBalance(account ?? undefined, token)

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = useUserStaked(poolId, token)

  const userPoolEarned = useUserEarned(poolId, token)
  // const userPoolEarned = stakingInfo?.earnedAmount

  //  const poolTokenPercentage = undefined

  const backgroundColor = useColor(token)

  // const tokens = [token, token]

  // const stakingInfo = {
  //   poolId: 0,
  //   tokens: tokens,
  //   stakedAmount: userPoolBalance,
  //   earnedAmount: userPoolEarned,
  //   totalStakedAmount: userPoolBalance,
  //   totalRewardRate: userPoolBalance,
  //   rewardRate: userPoolBalance    
  // }

  const [approvalLP, approveLPCallback] = useApproveCallback(new TokenAmount(token, userLPBalance?.raw || BIG_INT_ZERO), chainId ? BREWMASTER_ADDRESS[chainId] : BREWMASTER_ADDRESS[ChainId.MAINNET])
  // const [approvalLP, approveLPCallback] = useApproveCallback(userLPBalance, pair.liquidityToken.address)

  // console.log("approvalLP: "+approvalLP + " token "+token.symbol)

  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    }
  }, [account])


  return (
    <StyledPositionCard bgColor={backgroundColor}>
      <SingleStakingModal
        isOpen={showStakingModal}
        onDismiss={() => setShowStakingModal(false)}
        // stakingInfo={stakingInfo} 
        userLiquidityUnstaked={userLPBalance}
      />
      <SingleUnstakingModal
        isOpen={showUnstakingModal}
        onDismiss={() => setShowUnstakingModal(false)}
        earnedAmount={userPoolEarned}
        stakedAmount={userPoolBalance}
      />
      <SingleClaimRewardModal
        isOpen={showClaimRewardModal}
        onDismiss={() => setShowClaimRewardModal(false)}
        earnedAmount={userPoolEarned}
      />
      <AutoColumn gap="12px">
        <SingleClickableFixedHeightRow onClick={() => setShowMore(!showMore)}>
            <AutoRow width="150px">
              <AutoColumn>
              <AutoRow>
              <CurrencyLogo currency={currency0} size={'24px'} />
              <PairText>
                &nbsp;{!currency0 ? <Dots>Loading</Dots> : `${currency0.symbol}`}
              </PairText>
              </AutoRow>
              {smallScreen ? (
              <></>
              ) : (              
                <AutoRow gap="2px" width="130px">
                  Earn: <PairText>ALE</PairText><CurrencyLogo currency={rewardToken} size="24px" />
                </AutoRow>                
              )}            
              </AutoColumn>
            </AutoRow>
          {smallScreen ? (
            <></>
          ) : (
              <>
                <AutoRow width="150px" justify="center">
                  <AutoColumn justify="center">
                    <DataText>
                      {farmData ? farmData.apr.toLocaleString('en-US', { maximumFractionDigits: 1 }) : (<Dots></Dots>)}%
                    </DataText>
                    <SubText>
                      APR
                    </SubText>
                  </AutoColumn>
                </AutoRow>
                <AutoRow width="120px" justify="center">
                  <AutoColumn justify="center">
                    <DataText>
                      ${farmData ? farmData.tvl.toLocaleString('en-US', { maximumFractionDigits: 0 }) : (<Dots></Dots>)}
                    </DataText>
                    <SubText>
                      TVL
                    </SubText>
                  </AutoColumn>
                </AutoRow>
              </>
          )}
          {smallScreen ? (
            <></>
          ) : (

            <div>
              <ButtonEmpty
                padding="6px 8px"
                borderRadius="12px"
                width="fit-content"
                color="#B5BBC9"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? (
                  <>
                    {/* <DetailText>Details</DetailText> */}
                    <ChevronUp size="20" style={{ marginLeft: '10px' }} />
                  </>
                ) : (
                  <>
                    {/* <DetailText>Details</DetailText> */}
                    <ChevronDown size="20" style={{ marginLeft: '10px' }} />
                  </>
                )}
              </ButtonEmpty>
            </div>
          )}
        </SingleClickableFixedHeightRow>

        {(showMore || smallScreen) && (
          <AutoColumn gap="8px">
            {smallScreen ? (
              <>
                <FixedHeightRow>
                  <Text fontSize={16} fontWeight={500}>
                    Earn:
                  </Text>
                  <AutoRow gap="2px" width="100px" justify="flex-end" >
                    <PairText>ALE</PairText><CurrencyLogo currency={rewardToken} size="24px" />
                  </AutoRow>
                </FixedHeightRow>
                <FixedHeightRow>
                  <Text fontSize={16} fontWeight={500}>
                    APR:
                  </Text>
                  <DataText>
                    {farmData ? farmData.apr.toLocaleString('en-US', { maximumFractionDigits: 1 }) : (<Dots></Dots>)}%
                  </DataText>
                </FixedHeightRow>
                <FixedHeightRow>
                  <Text fontSize={16} fontWeight={500}>
                    TVL:
                  </Text>
                  <DataText>
                    ${farmData ? farmData.tvl.toLocaleString('en-US', { maximumFractionDigits: 0 }) : (<Dots></Dots>)}
                  </DataText>
                </FixedHeightRow>
              </>
            ) : (<></>)
            }
            <FixedHeightRow>
              <ExternalLink
                style={{ width: '100%', textAlign: 'left' }}
                href={`#/swap?outputCurrency=${currencyId(currency0)}`}
              >
                Buy ALE <span style={{ fontSize: '11px' }}>↗</span>
              </ExternalLink>
            </FixedHeightRow>
            <FixedHeightRow>
              <ExternalLink
                style={{ width: '100%', textAlign: 'left' }}
                href={`http://bscscan.com/address/${token.address}`}
              >
                View contract <span style={{ fontSize: '11px' }}>↗</span>
              </ExternalLink>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your available ALE:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {userLPBalance ? userLPBalance.toSignificant(4) : '-'}
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your deposited ALE:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Earned:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {userPoolEarned ? userPoolEarned.toSignificant(6) : '-'} ALE
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Deposit fee:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                0%
              </Text>
            </FixedHeightRow>            
            <RowBetween marginTop="10px">
              {approvalLP && approvalLP === ApprovalState.NOT_APPROVED || approvalLP === ApprovalState.PENDING ? (
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  onClick={approveLPCallback}
                  disabled={approvalLP === ApprovalState.PENDING}

                  width="32%"
                >
                  {approvalLP === ApprovalState.PENDING ? (
                    <Dots>Approving...</Dots>
                  ) : (
                    'Enable'
                  )}
                </ButtonPrimary>
              )
                : approvalLP && approvalLP === ApprovalState.APPROVED ? (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    onClick={handleDepositClick}
                    width="32%"
                  >
                    Stake
                  </ButtonPrimary>
                  ) : !account ? (
                    <ButtonPrimary
                        padding="8px"
                        borderRadius="8px"
                        onClick={toggleWalletModal}
                        width="32%"
                    >
                        Connect Wallet
                    </ButtonPrimary>
                    ) : (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    disabled={true}
                    onClick={handleDepositClick}
                    width="32%"
                  >
                    Enable
                  </ButtonPrimary>
                )}
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width="32%"
                onClick={() => setShowUnstakingModal(true)}
                disabled={userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, BIG_INT_ZERO) ? false : true}
              >
                Withdraw
              </ButtonPrimary>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width="32%"
                onClick={() => setShowClaimRewardModal(true)}
                disabled={userPoolEarned && JSBI.greaterThan(userPoolEarned.raw, BIG_INT_ZERO) ? false : true}
              >
                Harvest
              </ButtonPrimary>
            </RowBetween>
          </AutoColumn>
        )}
      </AutoColumn>
    </StyledPositionCard>
  )
}


