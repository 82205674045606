import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import UNISWAP_LOGO_URL from '../assets/svg/logo.svg'

// const NETWORK_URL = process.env.REACT_APP_NETWORK_URL
const NETWORK_URL = "https://bsc-dataseed.binance.org/"
// const NETWORK_URL = "http://192.168.7.185/"

const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
// const WALLETCONNECT_BRIDGE_URL = undefined
// const WALLETCONNECT_BRIDGE_URL = process.env.REACT_APP_WALLETCONNECT_BRIDGE_URL
// const WALLETCONNECT_BRIDGE_URL = "https://bridge.walletconnect.​org/"
const WALLETCONNECT_BRIDGE_URL = "https://uniswap.bridge.walletconnect.org"

// export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')
// export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '56')
export const NETWORK_CHAIN_ID: number = 56
export const POLYGON_NETWORK_CHAIN_ID: number = 137
export const REI_NETWORK_CHAIN_ID: number = 55555

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

function getDefaultChain() {
  if (window.location.hostname === "rei.aleswap.finance") 
    return REI_NETWORK_CHAIN_ID;
  return NETWORK_CHAIN_ID
}

export const network = new NetworkConnector({
  urls: { 
    [NETWORK_CHAIN_ID]: NETWORK_URL,
    [POLYGON_NETWORK_CHAIN_ID]: "https://rpc-mainnet.matic.network",
    [REI_NETWORK_CHAIN_ID]: "https://rei-rpc.moonrhythm.io",
   },
  defaultChainId: getDefaultChain()
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [56, 97, 4, 5, 42, POLYGON_NETWORK_CHAIN_ID, REI_NETWORK_CHAIN_ID]
  // supportedChainIds: [56]
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 56: NETWORK_URL },
  bridge: WALLETCONNECT_BRIDGE_URL,
  qrcode: true,
  pollingInterval: 15000
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Uniswap',
  appLogoUrl: UNISWAP_LOGO_URL
})
