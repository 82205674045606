import React from 'react';
import { useUserTotalAleEarned, useUserTotalStaked } from 'state/stake/hooks';
import styled from "styled-components";
import { useTVL } from 'utils/aleSwapAPI';
import useBUSDPrice from 'utils/useUSDCPrice';
import { ALE } from '../../constants';
import { Dots } from '../swap/styleds';

export default function TvlBox()  {
  const tvl = useTVL()
  const aleEarned = useUserTotalAleEarned()
  const userTotalStaked =  useUserTotalStaked()
  const alePrice = useBUSDPrice(ALE[56])
  const aleEarnedValue = aleEarned && alePrice ? alePrice.quote(aleEarned) : undefined

  return (
      
            <WalletBox>
                <WhiteText>Total Value Locked :</WhiteText>
                <ValueBox><TvlText>${!tvl ? <Dots>Loading</Dots> : parseInt(tvl).toLocaleString('en-US')}</TvlText></ValueBox>
                <WhiteText>Your Deposited Value :</WhiteText>
                <ValueBox><LpText>${userTotalStaked ? userTotalStaked.toFixed(0,{ groupSeparator: ',' }) : '0'}</LpText></ValueBox>
                <WhiteText>Ale Earned :</WhiteText>
                <ValueBox><AleText>{aleEarned ? aleEarned.toFixed(0,{ groupSeparator: ',' }) : '0'} (${aleEarnedValue ? aleEarnedValue.toFixed(0,{ groupSeparator: ',' }) : '0'})</AleText></ValueBox>
                
            </WalletBox>
        )
    }



const WalletBox = styled.div`
  display: flex;
  flex-direction: column;
  font-weight:bold;
  
  width: 250px;
  min-height:150px;
  height:100%;


color:#ffffff;
box-shadow: 0px 1px 1px #00000040;

background-color: ${({ theme }) => theme.panelWalletBG};
border-radius: 5px;
text-align:left;
justify-content: start;
padding-bottom:30px;
padding-left:10px;
padding-right:10px;

${({ theme }) => theme.mediaWidth.upToMedium`
  width: 100%;


`};
  
`;

const WhiteText = styled.div`
 
  font-weight:600;
  font-size:22px;
  margin-top:20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  align-self: center;    
`};  
    
`;

const ValueBox = styled.div`
  background-color:#ffffff;
  width: 230px;
  border-radius: 5px;
padding-left:5px;
font-size:20px;
box-shadow: inset 1px 1px 1px rgba(68,68,68,0.6);
${({ theme }) => theme.mediaWidth.upToMedium`
  align-self: center;    
`};
`;

const TvlText = styled.text`
  color:${({ theme }) => theme.panelText4};
  
`;

const LpText = styled.text`
  color:${({ theme }) => theme.panelText3};
    
`;



const AleText = styled.text`
  color:#eabb4e; 

`;










  

  ;