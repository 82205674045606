import { Token, Trade } from '@uniswap/sdk'
import Modal from 'components/Modal'
import { getMerchantLogo, getMerchantName, MERCHANT_LISTS } from 'constants/merchants'
import { useTokenContract } from 'hooks/useContract'
import useParsedQueryString from 'hooks/useParsedQueryString'
import QRCode from 'qrcode.react'
import { stringify } from 'qs'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown, ArrowLeft } from 'react-feather'
import { Link as HistoryLink, RouteComponentProps, useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled, { ThemeContext } from 'styled-components'
import { RedeemAddressInputPanel } from '../../components/AddressInputPanel'
import { ButtonPrimary } from '../../components/Button'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import FiatCurrencyInputPanel from '../../components/CurrencyInputPanel/fiat'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import { ArrowWrapper, BottomGrouping, Wrapper } from '../../components/swap/styleds'
import { useActiveWeb3React } from '../../hooks'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/swap/actions'
import {
  useDerivedSendCraftInfo, useRedeemDefaultsFromURLSearch, useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { CloseIcon } from '../../theme/components'
import AppBody from '../AppBody'




const merchantList = MERCHANT_LISTS;

interface MerchantRowProps {
  name: string,
  id : number,
  address : string 
}

const StyledSwapHeader = styled.div`
  padding: 12px 1rem 0px 1.5rem;
  margin-bottom: -4px;
  width: 100%;
  max-width: 420px;
  color: ${({ theme }) => theme.text2};
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`


const ModalWrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 24px;
`



interface ShowQRModalProps {
  isOpen: boolean
  onDismiss: () => void
  recipient: string | null
  amount: string | undefined
}

export function ShowQRModal({
  isOpen,
  onDismiss,
  recipient,
  amount
}: ShowQRModalProps) {

  const target = `https://app.aleswap.finance/#/redeem?r=${recipient}&amount=${amount}&ef=o`

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <ModalWrapper>
        <Section>
        <RowBetween padding="10px">
          <Text fontWeight={500} fontSize={20}>
            Scan to spend
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>          
          <AutoColumn gap="20px">
            <AutoRow justify="center">
              <QRCode value={target} size={300} />
            </AutoRow>
            <AutoRow justify="center">
              <Text fontWeight={500} fontSize={18}>
              {amount} CRAFT
              </Text>
            </AutoRow>
          </AutoColumn>
        </Section>
      </ModalWrapper>
    </Modal>
  )
}



export function GenerateQRHeader() {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <HistoryLink
          to={"/merchant"}
        >
          <StyledArrowLeft />
        </HistoryLink>
        <TYPE.black fontWeight={500}>Create Invoice</TYPE.black>
        <StyledMenu />
      </RowBetween>
    </StyledSwapHeader>
  )
}

export default function MerchantGenerateQR({ history }: RouteComponentProps) {
  const { account, chainId, library } = useActiveWeb3React()
  const loadedUrlParams = useRedeemDefaultsFromURLSearch()

  const { search } = useLocation()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [useCraftToRedeem, setUseCraftToRedeem] = useState<boolean>(false)

  const craft = useCurrency("0x19Ea6042ca81bcd1FEC329004Fd5967AFdC6745e")
  const craftToken = useTokenContract("0x19Ea6042ca81bcd1FEC329004Fd5967AFdC6745e")

  const [craftAmount, setCraftAmount] = useState(0)
  const [fiatAmount, setFiatAmount] = useState(0)

  const addTransaction = useTransactionAdder()

  const loadedRecipient = loadedUrlParams?.recipient;


  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !Boolean(token.address in defaultTokens)
    })

  // const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  
  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    // v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSendCraftInfo()

  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const trade = showWrap ? undefined : v2Trade

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )


  const handleTypeFiatInput = useCallback(
    (value: string) => {
      // onUserInput(Field.INPUT, value)
      if (value === '') value = '0'
      setFiatAmount(parseFloat(value))
      setCraftAmount(parseFloat(value)/32)
    },
    [setCraftAmount,setFiatAmount]
  )

  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }


  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // const [logo, setLogo] = useState<string>(loadedLogo)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])



  return (
    <>
      <SwapPoolTabs active={'swap'} />
      <AppBody>
        <GenerateQRHeader />
        <Wrapper id="swap-page">
          <ShowQRModal 
            isOpen={showConfirm} 
            onDismiss={handleConfirmDismiss}
            recipient={recipient}
            amount={craftAmount.toFixed(3)}
          />
          {/* <ConfirmRedeemCraftModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={onSendCraft}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          /> */}


          <AutoColumn gap={'md'}>
          <FiatCurrencyInputPanel
              value={ fiatAmount.toString() }
              onUserInput={handleTypeFiatInput}
              label={'Amount'}
              showMaxButton={false}
              disableCurrencySelect={true}
              currency={ craft }
              onCurrencySelect={handleOutputSelect}
              id="swap-currency-output"
            />            
          <CurrencyInputPanel
              value={craftAmount.toFixed(3)}
              onUserInput={handleTypeOutput}
              label={'Amount'}
              showMaxButton={false}
              disableCurrencySelect={true}
              currency={ craft }
              disabled = { true }
              onCurrencySelect={handleOutputSelect}
              id="swap-currency-output"
            />

            <AutoColumn justify="space-between">
              <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                  {recipient === null && !showWrap ? ( 
                    <>
                    Merchant: 

                    { merchantList.map((item, i) =>  
                      <LinkStyledButton id="add-recipient-button" onClick={() => { onChangeRecipient(item.address)}}>
                      {item.name}
                      </LinkStyledButton>  
                    )}                    


                  </>              
                ) : null}
                {recipient !== null && !showWrap ? (
                  <>
                    <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                      <ColumnCenter>
                      <ArrowWrapper clickable={false}>
                        <ArrowDown size="16" color={theme.text2} />
                      </ArrowWrapper>
                      </ColumnCenter>
                      { !loadedRecipient && (
                        <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                          Change merchant
                        </LinkStyledButton>
                      )}
                    </AutoRow>
                    <RedeemAddressInputPanel merchantName={ getMerchantName(recipient) } logo={ getMerchantLogo(recipient) } id="recipient" value={recipient} onChange={onChangeRecipient} />

                  </>
                ) : null}                
              </AutoRow>
            </AutoColumn>

          </AutoColumn>
          <BottomGrouping>
              <ButtonPrimary
                onClick={() => {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined
                    })
                }}
                id="swap-button"
                disabled={recipient === null}
              >
                <Text fontSize={20} fontWeight={500}>
                  Create
                </Text>
              </ButtonPrimary>

          </BottomGrouping>
        </Wrapper>
      </AppBody>
    </>
  )
}
