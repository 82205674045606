import { JSBI, Token, TokenAmount } from '@uniswap/sdk'
import { PrivateVaultUnstakingModal } from 'components/earn/PrivateVaultUnstakingModal'
import { usePair } from 'data/Reserves'
import { useAllTokens } from 'hooks/Tokens'
import { useWindowSize } from 'hooks/useWindowSize'
import { darken } from 'polished'
import React, { useCallback, useState } from 'react'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Text } from 'rebass'
import styled from 'styled-components'
import { usePrivateVaultData } from 'utils/aleSwapAPI'
import { allocsPoint, getTokenByName } from 'utils/farm'
import { VaultPrivate } from 'utils/vault'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { VaultStakingModal } from '../../components/earn/StakingModal'
// import  from '../../components/earn/StakingModal'
import { VaultUnstakingModal } from '../../components/earn/UnstakingModal'
import { ALE, BIG_INT_ZERO, WETH } from '../../constants'
import { useTotalStaked, useUserPrivateVaultStaked, useUserVaultEarned } from '../../data/TotalStaked'
import { useTotalSupply } from '../../data/TotalSupply'
import { useActiveWeb3React } from '../../hooks'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useColor } from '../../hooks/useColor'
import { useVaultStakingInfo } from '../../state/stake/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, MEDIA_WIDTHS } from '../../theme'
import useBUSDPrice from '../../utils/useUSDCPrice'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonEmpty, ButtonPrimary } from '../Button'
import Card, { RectLightCard } from '../Card'
import { AutoColumn } from '../Column'
import { OverlapDoubleCurrencyLogo } from '../DoubleLogo'
import Row, { AutoRow, RowBetween } from '../Row'
import { Dots } from '../swap/styleds'






// import FarmStakingModal from './FarmStakingModal'

export const HeaderRow = styled(RowBetween)`
  height: 80px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 10px;
    height: 40px;
`};  
`

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
const StyledPositionCard = styled(RectLightCard) <{ bgColor: any }>`
  background: ${({ theme }) => theme.panelBG};
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 2px 
#00000040;  
`
const ClickableHeaderRow = styled(HeaderRow)`
cursor: pointer; 
`
const PairText = styled(Text)`
  color: ${({ theme }) => theme.tokenText};
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`
const TVLText = styled(Text)`

  color: #347AF0;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`
const APRText = styled(Text)`
  color: #347AF0;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`

const SubText = styled(Text)`
  color: #347AF0;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`

const DetailText = styled(Text)`
${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

export const PairRow = styled(Row)`
  width:130px;
  // flex-wrap: wrap;  
  flex-direction: row
`

const alePerBlock = JSBI.BigInt(20)
const totalAllocPoint = JSBI.BigInt(184333)
const blockPerDay = JSBI.BigInt(28800)
const dayPerYear = JSBI.BigInt(365)
const ONE_HUNDRED = JSBI.BigInt(100)


interface PairVaultPrivateCardProps {
  vault: VaultPrivate
  pairName: string
  poolId: number
  showUnwrapped?: boolean
  border?: string
  stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
  startTime?: string
}


export function PairVaultPrivateCard({ vault, pairName, poolId, border, stakedBalance, startTime }: PairVaultPrivateCardProps) {
  const { account, chainId } = useActiveWeb3React()

  const { width, } = useWindowSize()

  const smallScreen = width && width < MEDIA_WIDTHS.upToSmall ? true : false

  const allTokens = useAllTokens()

  const tokens = vault.name.split("-")

  const sToken0 = getTokenByName(tokens[0], chainId)
  const sToken1 = getTokenByName(tokens[1], chainId)

  const atoken0 = chainId && sToken0.name == "BNB" ? WETH[chainId] : allTokens[sToken0.address]
  const atoken1 = chainId && sToken1.name == "BNB" ? WETH[chainId] : allTokens[sToken1.address]

  const [, pair] = usePair(atoken0, atoken1)

  // const allPoolData = useAleStakingInfo()
  const dummyVault = { alePoolId : -1,  ...vault}
  const stakingInfo = useVaultStakingInfo(dummyVault, pair)?.[0]
  


  const vaultData = usePrivateVaultData(dummyVault)
  // const vaultApr = vaultData ? vaultData.apr : undefined



  const allocPoint = JSBI.BigInt(allocsPoint[poolId])

  const alePrice = useBUSDPrice(chainId ? ALE[chainId] : undefined)

  // const token0 = (pair.token0.symbol == token0Symbol) ? pair.token0 : pair.token1
  // const token1 = (pair.token1.symbol == token0Symbol) ? pair.token0 : pair.token1

  const token0 = pair ? pair.token0 : undefined
  const token1 = pair ? pair.token1 : undefined

  const currency0 = atoken0 ? unwrappedToken(atoken0) : undefined
  const currency1 = atoken1 ? unwrappedToken(atoken1) : undefined



  const [showMore, setShowMore] = useState(false)

  // const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair?.liquidityToken)
  const totalStakedTokens = useTotalStaked(pair?.liquidityToken)


  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!totalStakedTokens &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThan(totalPoolTokens.raw, JSBI.BigInt(0))
      && JSBI.greaterThanOrEqual(totalPoolTokens.raw, totalStakedTokens.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, totalStakedTokens, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, totalStakedTokens, false)
      ]
      : [undefined, undefined]

  const token0Price = useBUSDPrice(chainId ? token0 : undefined)
  const token1Price = useBUSDPrice(chainId ? token1 : undefined)

  //  console.log("token0Price "+token0Price?.raw.toFixed(2)+ " token1Price "+token1Price?.raw.toFixed(2))

  const token0TVL = token0Deposited && token0Price ? token0Price.quote(token0Deposited) : undefined
  const token1TVL = token1Deposited && token1Price ? token1Price.quote(token1Deposited) : undefined

  const totalValueLocked = token0TVL && token1TVL ? token0TVL.add(token1TVL) : undefined

  // const farmAPR = totalValueLocked && alePrice ? alePrice.multiply(alePerBlock).multiply(blockPerDay).divide(totalValueLocked) : undefined

  const farmAPR = totalValueLocked && JSBI.greaterThan(totalValueLocked.raw, JSBI.BigInt(0)) && alePrice && alePrice.greaterThan(JSBI.BigInt(0)) ?
    alePrice.divide(totalValueLocked).multiply(blockPerDay).multiply(alePerBlock).multiply(dayPerYear).multiply(allocPoint).divide(totalAllocPoint).multiply(ONE_HUNDRED) : 0

  // const CAKE_USDT_BUSD_PAIR = '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00';
  // const lpToken = new Token(56,CAKE_USDT_BUSD_PAIR,18)

  // const DEFINIX_USDT_BUSD_PAIR = '0xC01cF935e122931B688656d5a7bB3f9ab28C83D3';
  // const lpToken = new Token(56,DEFINIX_USDT_BUSD_PAIR,18)
  const lpToken = new Token(56, vault.lpTokenAddress, 18)


  const userLPBalance = useTokenBalance(account ?? undefined, lpToken)

  // if (userLPBalance && stakingInfo) 
  //   stakingInfo.stakedAmount = userLPBalance
  // const userLPBalance = useUserStaked(poolId,pair.liquidityToken)

  // if staked balance balance provided, add to standard liquidity amount
  // const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance
  const userPoolBalance = useUserPrivateVaultStaked(dummyVault)

  // const userPoolEarned = useUserEarned(poolId,pair.liquidityToken)
  //const userPoolEarned = stakingInfo ? stakingInfo.earnedAmount : undefined
  const userPoolEarned = useUserVaultEarned(dummyVault)

  const userPoolWithEarned = userPoolBalance && userPoolEarned ? userPoolBalance.add(userPoolEarned) : undefined

  // const [userToken0Deposited, userToken1Deposited] =
  //   !!pair && 
  //     !!totalPoolTokens &&
  //     !!userPoolBalance &&
  //     // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
  //     JSBI.greaterThan(totalPoolTokens.raw, JSBI.BigInt(0)) &&
  //     JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
  //     ? [
  //       pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
  //       pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
  //     ]
  //     : [undefined, undefined]

  // const [userToken0Deposited, userToken1Deposited] = [undefined, undefined]

  // const userToken0Value = undefined
  // const userToken1Value = undefined

  const userPoolValue = vaultData && vaultData.pricePerShare && userPoolWithEarned ? new TokenAmount(lpToken,
    JSBI.divide(
      JSBI.multiply(userPoolWithEarned.raw, JSBI.BigInt(Math.trunc(vaultData.pricePerShare * 100000))),
      JSBI.BigInt(100000))) : undefined


  // ${vaultData && userPoolBalance? (parseFloat(vaultData.pricePerShare)*userPoolBalance.toSignificant(4)).toFixed(2, { groupSeparator: ',' }) : '-'}
  // const userToken0Value = userToken0Deposited && token0Price ? token0Price.quote(userToken0Deposited) : undefined
  // const userToken1Value = userToken1Deposited && token1Price ? token1Price.quote(userToken1Deposited) : undefined

  // const userPoolValue = userToken0Value && userToken1Value ? userToken0Value.add(userToken1Value) : undefined

  //  const poolTokenPercentage = undefined

  // const vaultAddress = '0xFE663A7534BB00a54fE676ab7a4a15829Ab5DE1c';
  // const vaultAddress = '0x7a135E1b8597c8E7faF8b5A6634466cc23626f12';

  const backgroundColor = useColor(pair?.token0)

  //const [approvalLP, approveLPCallback] = useApproveCallback(new TokenAmount(pair.liquidityToken,JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')), chainId ? BREWMASTER_ADDRESS[chainId] : '')
  const [approvalLP, approveLPCallback] = useApproveCallback(userLPBalance, vault.vaultAddress)

  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    }
  }, [account])

  const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
    if (completed) {
      // Render a completed state
      return <></>;
    } else {
      // Render a countdown
      return <span>{days} days {hours} hours {minutes} minutes {seconds} seconds</span>;
    }
  };


  return (
    <StyledPositionCard border={border} bgColor={backgroundColor} >
      <VaultStakingModal
        isOpen={showStakingModal}
        onDismiss={() => setShowStakingModal(false)}
        stakingInfo={stakingInfo}
        vault={dummyVault}
        // lpToken={lpToken}
        userLiquidityUnstaked={userLPBalance} />
      <PrivateVaultUnstakingModal
        isOpen={showUnstakingModal}
        onDismiss={() => setShowUnstakingModal(false)}
        vault={dummyVault}
        stakingInfo={stakingInfo}
      />
      <ClaimRewardModal
        isOpen={showClaimRewardModal}
        onDismiss={() => setShowClaimRewardModal(false)}
        stakingInfo={stakingInfo}
      />
      <AutoColumn gap="12px">
        <ClickableHeaderRow onClick={() => setShowMore(!showMore)}>
          <AutoRow width="130px">
            <OverlapDoubleCurrencyLogo currency0={currency0} currency1={currency1} size={20} />
            {/* <DoubleTokenLogo token0="https://teerapat.com/aleswap/aleswapcoin.png" token1="https://tokens.bscswap.com/images/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png" pairName="ALE-BNB" multiplier0="20" /> */}
            {/* <DoubleTokenLogo currency0={currency0} currency1={currency1} multiplier="1" /> */}
            <PairText>
              {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}-${currency1.symbol}`}
            </PairText>
            {/* Uses: {vault.farm.name} */}
          </AutoRow>
          {smallScreen ? (
            <></>
          ) : (
            <>
              <AutoRow width="100px">
                <APRText>
                  {/* APR {farmAPR ? farmAPR.toFixed(1, { groupSeparator: ',' }) : (<Dots></Dots>)}% */}
                  APY {vaultData ? vaultData.apy.toFixed(2) : (<Dots></Dots>)}%
                </APRText>
              </AutoRow>
              <AutoRow width="120px">
                <TVLText>
                  TVL ${vaultData ? vaultData.vaultTVL.toLocaleString('en-US', { maximumFractionDigits: 0 }) : (<Dots></Dots>)}
                </TVLText>
              </AutoRow>
            </>
          )}
          {smallScreen ? (
            <></>
          ) : (

            <AutoRow width="90px">
              <ButtonEmpty
                padding="6px 8px"
                borderRadius="12px"
                width="fit-content"
                color="#B5BBC9"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? (
                  <>
                    <DetailText>Details</DetailText>
                    <ChevronUp size="20" style={{ marginLeft: '10px' }} />
                  </>
                ) : (
                  <>
                    <DetailText>Details</DetailText>
                    <ChevronDown size="20" style={{ marginLeft: '10px' }} />
                  </>
                )}
              </ButtonEmpty>
            </AutoRow>
          )}

        </ClickableHeaderRow>

        {(showMore || smallScreen) && (
          <AutoColumn gap="8px">
            {smallScreen ? (
              <>
                <FixedHeightRow>
                  <APRText>
                    APR:
                  </APRText>
                  <APRText>
                    {farmAPR ? farmAPR.toFixed(1, { groupSeparator: ',' }) : (<Dots></Dots>)}%
                  </APRText>
                </FixedHeightRow>
                <FixedHeightRow>
                  <TVLText>
                    TVL:
                  </TVLText>
                  <TVLText>
                    ${totalValueLocked ? totalValueLocked.toFixed(0, { groupSeparator: ',' }) : (<Dots></Dots>)}
                  </TVLText>
                </FixedHeightRow>
              </>
            ) : (<></>)
            }
            {/* <FixedHeightRow>
              {!currency0 || !currency1 ? <Dots>Loading</Dots> :
                <ExternalLink
                  style={{ width: '100%', textAlign: 'left' }}
                  href={`${vault.farm.url}${currencyAddress(currency0, vault.farm)}/${currencyAddress(currency1, vault.farm)}`}
                >
                  Add {currency0.symbol}-{currency1.symbol} LP <span style={{ fontSize: '11px' }}>↗</span>
                </ExternalLink>}
            </FixedHeightRow> */}
            <FixedHeightRow>
              <ExternalLink
                style={{ width: '100%', textAlign: 'left' }}
                href={`http://bscscan.com/address/${pair?.liquidityToken.address}`}
              >
                View contract <span style={{ fontSize: '11px' }}>↗</span>
              </ExternalLink>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your available LP:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {userLPBalance ? userLPBalance.toSignificant(4) : '-'}
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your deposited LP:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your deposited LP + Earned Value:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                ${userPoolValue ? userPoolValue.toFixed(2, { groupSeparator: ',' }) : '-'}
              </Text>
            </FixedHeightRow>
            {startTime && startTime.length > 0 && new Date(startTime) >= new Date() ? (
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Earn ALE. Reward start in
                </Text>
                <Text fontSize={16} fontWeight={700}>
                  <Countdown date={startTime} renderer={renderer}></Countdown>
                </Text>
              </FixedHeightRow>
            ) : (
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Earned:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {userPoolEarned ? userPoolEarned.toSignificant(6) : '-'}
                </Text>
              </FixedHeightRow>
            )}
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Deposit fee:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                0%
              </Text>
            </FixedHeightRow>
            <RowBetween marginTop="10px">
              {/* {!account ? (
              <Card padding="40px">
                <TYPE.body color={theme.text3} textAlign="center">
                  Connect to a wallet to view AleSwap Farm.
                </TYPE.body>
              </Card>
              ) : ( */}

              {approvalLP && approvalLP === ApprovalState.NOT_APPROVED || approvalLP === ApprovalState.PENDING ? (
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  onClick={approveLPCallback}
                  disabled={approvalLP === ApprovalState.PENDING}

                  width="32%"
                >
                  {approvalLP === ApprovalState.PENDING ? (
                    <Dots>Approving...</Dots>
                  ) : (
                    'Enable'
                  )}
                </ButtonPrimary>
              )
                : approvalLP && approvalLP === ApprovalState.APPROVED ? (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    onClick={handleDepositClick}
                    width="32%"
                  >
                    Stake
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    disabled={true}
                    onClick={handleDepositClick}
                    width="32%"
                  >
                    Enable
                  </ButtonPrimary>
                )}

              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width="32%"
                onClick={() => setShowUnstakingModal(true)}
                disabled={userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, BIG_INT_ZERO) ? false : true}
              >
                Withdraw
              </ButtonPrimary>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width="32%"
                onClick={() => setShowClaimRewardModal(true)}
                disabled={userPoolEarned && JSBI.greaterThan(userPoolEarned.raw, BIG_INT_ZERO) ? false : true}
              >
                Claim
              </ButtonPrimary>
            </RowBetween>
          </AutoColumn>
        )}
      </AutoColumn>
    </StyledPositionCard>
  )
}

