import useParsedQueryString from 'hooks/useParsedQueryString'
import { stringify } from 'qs'
import React from 'react'
import { ArrowLeft } from 'react-feather'
import { Link as HistoryLink } from 'react-router-dom'
import styled from 'styled-components'
import { TYPE } from '../../theme'
import { RowBetween } from '../Row'
import Settings from '../Settings'


const StyledSwapHeader = styled.div`
  padding: 12px 1rem 0px 1.5rem;
  margin-bottom: -4px;
  width: 100%;
  max-width: 420px;
  color: ${({ theme }) => theme.text2};
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

export default function SwapHeader() {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <TYPE.black fontWeight={500}>Swap</TYPE.black>
        <Settings />
      </RowBetween>
    </StyledSwapHeader>
  )
}

export function RedeemByCraftHeader() {
  const search = useParsedQueryString()

  const redeem = search ? "/redeem?"+stringify({...search}) : '"/redeem'

  return (
    <StyledSwapHeader>
      <RowBetween>
        <HistoryLink
          to={redeem}
        // onClick={() => {
        //   dispatch(resetMintState())
        // }}
        >
          <StyledArrowLeft />
        </HistoryLink>
        <TYPE.black fontWeight={500}>Spend by CRAFT</TYPE.black>
        {/* <Settings /> */}
        <StyledMenu />
      </RowBetween>
    </StyledSwapHeader>
  )
}

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

export function RedeemByCryptoHeader() {
  const search = useParsedQueryString()

  const redeem = search ? "/redeem?"+stringify({...search}) : '"/redeem'

  return (
    <StyledSwapHeader>
      <RowBetween>
        <HistoryLink
          to={redeem}
        // onClick={() => {
        //   dispatch(resetMintState())
        // }}
        >
          <StyledArrowLeft />
        </HistoryLink>
        <TYPE.black fontWeight={500}>Spend by Crypto</TYPE.black>
        <Settings />
      </RowBetween>
    </StyledSwapHeader>
  )
}