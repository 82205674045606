import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 158.55 161.4" {...props}>
<path d="M77.07,10.55c-39.02,0-70.77,31.75-70.77,70.77c0,39.02,31.75,70.77,70.77,70.77s70.77-31.75,70.77-70.77
  C147.84,42.3,116.09,10.55,77.07,10.55z M77.07,145.1c-35.16,0-63.77-28.61-63.77-63.77c0-35.16,28.61-63.77,63.77-63.77
  c35.16,0,63.77,28.61,63.77,63.77C140.84,116.49,112.23,145.1,77.07,145.1z"/>
 <path d="M77.07,38.99c-11.97,0-32.23,2.53-32.23,12.02v60.62c0,9.49,20.26,12.02,32.23,12.02s32.23-2.53,32.23-12.02V51.02
  C109.3,41.53,89.04,38.99,77.07,38.99z M102.3,65.33c-1.03,1.61-9.44,5.25-25.23,5.25c-15.79,0-24.2-3.65-25.23-5.25v-6.36
  c6.99,3.07,17.73,4.07,25.23,4.07s18.24-0.99,25.23-4.07V65.33z M79.87,46.03c0.31,0.01,0.6,0.03,0.9,0.04
  c0.59,0.02,1.18,0.04,1.74,0.08c0.34,0.02,0.67,0.05,1,0.07c0.5,0.03,1,0.07,1.48,0.11c0.34,0.03,0.67,0.07,1,0.1
  c0.44,0.04,0.89,0.09,1.31,0.14c0.33,0.04,0.64,0.08,0.97,0.13c0.4,0.05,0.8,0.1,1.18,0.16c0.31,0.05,0.61,0.1,0.91,0.15
  c0.36,0.06,0.73,0.12,1.08,0.18c0.29,0.05,0.57,0.11,0.85,0.17c0.33,0.07,0.66,0.13,0.97,0.2c0.27,0.06,0.52,0.12,0.78,0.18
  c0.3,0.07,0.59,0.14,0.88,0.21c0.24,0.06,0.48,0.13,0.71,0.19c0.27,0.07,0.53,0.15,0.78,0.22c0.22,0.06,0.43,0.13,0.63,0.2
  c0.24,0.08,0.47,0.15,0.69,0.23c0.19,0.07,0.38,0.13,0.56,0.2c0.21,0.08,0.41,0.15,0.61,0.23c0.17,0.07,0.32,0.13,0.48,0.2
  c0.18,0.08,0.35,0.15,0.52,0.23c0.14,0.06,0.28,0.13,0.4,0.19c0.15,0.07,0.29,0.15,0.43,0.22c0.11,0.06,0.23,0.12,0.33,0.18
  c0.12,0.07,0.23,0.14,0.34,0.21c0.09,0.06,0.18,0.12,0.25,0.17c0.09,0.07,0.17,0.13,0.25,0.19c0.06,0.05,0.13,0.11,0.18,0.16
  c0.03,0.02,0.04,0.05,0.06,0.07c-1.65,1.7-10.01,5.02-25.05,5.02c-15.05,0-23.4-3.31-25.05-5.02c0.02-0.02,0.04-0.04,0.06-0.07
  c0.05-0.05,0.12-0.11,0.19-0.16c0.07-0.06,0.15-0.12,0.24-0.19c0.08-0.06,0.17-0.12,0.26-0.18c0.1-0.07,0.21-0.13,0.33-0.2
  c0.11-0.06,0.22-0.13,0.34-0.19c0.13-0.07,0.27-0.14,0.41-0.21c0.13-0.07,0.27-0.13,0.41-0.2c0.16-0.07,0.33-0.15,0.5-0.22
  c0.16-0.07,0.32-0.13,0.49-0.2c0.19-0.08,0.39-0.15,0.59-0.23c0.18-0.07,0.37-0.13,0.57-0.2c0.22-0.08,0.45-0.15,0.68-0.22
  c0.21-0.07,0.42-0.13,0.64-0.2c0.25-0.07,0.51-0.15,0.77-0.22c0.23-0.06,0.47-0.13,0.71-0.19c0.28-0.07,0.57-0.14,0.87-0.21
  c0.26-0.06,0.51-0.12,0.78-0.18c0.31-0.07,0.64-0.13,0.97-0.2c0.28-0.06,0.56-0.11,0.85-0.17c0.35-0.06,0.71-0.12,1.07-0.18
  c0.3-0.05,0.6-0.1,0.91-0.15c0.38-0.06,0.78-0.11,1.18-0.16c0.32-0.04,0.64-0.09,0.97-0.13c0.42-0.05,0.87-0.09,1.31-0.14
  c0.33-0.03,0.66-0.07,1-0.1c0.48-0.04,0.98-0.07,1.48-0.11c0.33-0.02,0.66-0.05,1-0.07c0.56-0.03,1.15-0.05,1.74-0.08
  c0.3-0.01,0.59-0.03,0.9-0.04c0.91-0.03,1.84-0.04,2.8-0.04S78.96,46.01,79.87,46.03z M77.07,116.66
  c-15.71,0-24.13-3.61-25.23-5.23V73.52c6.99,3.07,17.73,4.07,25.23,4.07s18.24-0.99,25.23-4.07v37.91
  C101.2,113.04,92.78,116.66,77.07,116.66z"/>
    </Svg>
  );
};

export default Icon;
