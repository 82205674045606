
export interface VaultFarm {
    name: string
    description: string
    url: string
    group : string
}

export const VAULT_GROUP_DESCRIPTION: {[key:string ]: string} = {
    Biswap : "",
    Definix : "",
    Dopple : "",
    Twindex : "",
    ApeSwap : "",
    // Warden : "Yield farming end at 11 Sep 2021",

}

export const VAULT_FARMS: {[key:string ]: VaultFarm} = {
    BISWAP : {name: "Biswap", group: "Biswap", url: "https://exchange.biswap.org/#/add/", description :""},
    DEFINIX : {name: "Definix", group: "Definix", url: "https://exchange.definix.com/#/add/", description :""},
    DOPPLE : {name: "Dopple", group: "Dopple", url: "https://dopple.finance/Deposit/", description :""},   
    DOPPLEX : {name: "Dopple", group: "Dopple", url: "https://exchange.twindex.com/#/add/", description :""},        
    APESWAP : {name: "ApeSwap", group: "ApeSwap", url: "https://app.apeswap.finance/add/", description :""},
    TWINDEX : {name: "Twindex", group: "Twindex", url: "https://exchange.twindex.com/#/add/", description :""},    
    // WARDEN : {name: "Warden",group: "Warden",  url: "https://liquidity.wardenswap.finance/#/",description :"Yield farming end at 11 Sep 2021"},
}

export const VAULT_PRIVATE_FARMS: {[key:string ]: VaultFarm} = {
    DEFINIX : {name: "Definix", group:"", url: "https://exchange.definix.com/#/add/", description :""},
    WARDEN : {name: "Warden", group:"", url: "https://liquidity.wardenswap.finance/#/", description :"Yield farming end at 11 Sep 2021"},
    BISWAP : {name: "Biswap", group:"", url: "https://exchange.biswap.org/#/", description :""},
}


export interface Vault {
    id: number
    alePoolId : number
    name: string
    farm: VaultFarm
    lpTokenAddress: string
    vaultAddress: string
    addLpUrl? : string
    imageUrl? : string
    vaultType?: string
}

export interface VaultPrivate {
    id: number
    name: string
    farm: VaultFarm
    lpTokenAddress: string
    vaultAddress: string
}


export interface VaultData extends Vault {
    apr: number
    apy: number
    pricePerShare: number
    tvl: number
    vaultTVL: number
}

export const VAULT_LISTS_PRIVATE: VaultPrivate[] = [
    { id: 0, name: 'BUSD-USDT',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0xC01cF935e122931B688656d5a7bB3f9ab28C83D3", vaultAddress: "0x7a135E1b8597c8E7faF8b5A6634466cc23626f12"},
    { id: 1, name: 'ADA-BNB',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0x670C739C6C7f84330C50bA8E5E4975aF172c8a12", vaultAddress: "0xABAc67F1b6A27798A3FdC38f3CA6Ba9C949a2E61"},
    { id: 2, name: 'BNB-BUSD',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0x18B8047DDd8b57F1C5Fd59bC57059E0C38e9fE09", vaultAddress: "0x72594AF58Db57b71347fc68d7267f0975bf4BA7a"},
    { id: 3, name: 'ETH-BNB',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0xb8Ac66679938D7354DFEa4Aca56B3E1E0487924d", vaultAddress: "0x38e38F07e8e44308Aa1c2214403C41158E2DeAEC"},
    { id: 4, name: 'BTCB-BNB',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0xE213a50384b8301118712195519D0B87D425F290", vaultAddress: "0x788790B628E7e1BA0c604d3c11Dbd05F3129BF11"},    
    { id: 5, name: 'BNB-USDT',farm: VAULT_FARMS.WARDEN,lpTokenAddress: "0xfD468f81f4a6D859a0eB3667c65F7BEA9Dc69028", vaultAddress: "0x8298e829109331A43189247d790913EcfcfACfca"},    

]

export const VAULT_LISTS: Vault[] = [

    
    { id: 51, alePoolId: -1, name: 'SIX-FINIX',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0xC4B4aC94a73aF2a4788acA0204ce0bE779999dC9", vaultAddress: "0x20b6300f9d2d8832E42F3591e11Cd41CfE7e5Cf1"},    
    { id: 8, alePoolId: -1, name: 'FINIX-BNB',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0xB2A2048de5afb312Fcf076aBf505952c12916f0d", vaultAddress: "0x66B3EC4497e5Aa64fDeD66fA8F7DbC4085c73c8B"},    
    { id: 9, alePoolId: -1, name: 'FINIX-BUSD',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0x9d19afdc03A710cf54f6B3d3764C7d3B26AC892F", vaultAddress: "0xF7923dfa2aB2e67EEE67630A4161DA8794A76c3e"},    
    { id: 7, alePoolId: 22, name: 'BNB-BUSD',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0x18B8047DDd8b57F1C5Fd59bC57059E0C38e9fE09", vaultAddress: "0x40afa1238253E52C32FA26D8c22D39F7377AaB55"},    
    { id: 10, alePoolId: 23, name: 'BTCB-BNB',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0xE213a50384b8301118712195519D0B87D425F290", vaultAddress: "0x994a9d49a50670838A6f1937d884DE3e1d094e15"},    
    { id: 28, alePoolId: -1, name: 'BTCB-ETH',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0xFdeA4583Fa37714806Ece3Fa8F1828A769d94903", vaultAddress: "0xDBFD8E2102E58F6b014C29322ce011FDFDDf3177"},    
    { id: 11, alePoolId: 24, name: 'ETH-BNB',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0xb8Ac66679938D7354DFEa4Aca56B3E1E0487924d", vaultAddress: "0x92290f5c30d45E46cAe1d53c16fBF52C1FC3A1fF"},    
    { id: 12, alePoolId: -1, name: 'ADA-BNB',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0x670C739C6C7f84330C50bA8E5E4975aF172c8a12", vaultAddress: "0x44782F1f4F30C04C9951b3dd72a76744b2bda12b"},    
    { id: 15, alePoolId: -1, name: 'SIX-BNB',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0xEb99eBe638C1106C9CB74B723442307e4Cf3AaD1", vaultAddress: "0x9E40ABAd4d251105c9641f7faAdd0DeaC41f7373"},        
    { id: 30, alePoolId: -1, name: 'SIX-BUSD',farm: VAULT_FARMS.DEFINIX,lpTokenAddress: "0xea79E7d41D143050c23c1E01AC2990a506ef060A", vaultAddress: "0x26E85E4f420eC7b7219132b108309112FFd7965B"},        


    // { id: 27, alePoolId: -1, name: 'DOPX-KUSD',farm: VAULT_FARMS.DOPPLEX,lpTokenAddress: "0x8d9aE514F1713cDbdC991e7D7494e4A91FD24416", vaultAddress: "0x3373cFA5426727031DBbA3410Ed2717C3D174371"},        
    { id: 18, alePoolId: 26, name: 'DAI-BUSD-USDT-USDC',farm: VAULT_FARMS.DOPPLE,lpTokenAddress: "0x9116F04092828390799514Bac9986529d70c3791", vaultAddress: "0xbC8e0c6b630d4BD6fe662ca5A9D313D5182bd397",addLpUrl:"dop-lps", imageUrl: "https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fstable-coin.svg&w=128&q=75", vaultType: "Stable"},    
    // { id: 23, alePoolId: -1, name: 'KUSD-BUSD-USDT',farm: VAULT_FARMS.DOPPLE,lpTokenAddress: "0x7755816b41F04aB62ceC2334263987e7cD78A4Fe", vaultAddress: "0xf664C16d63694713a7C941Fcd36c5640F78BfBEa",addLpUrl:"kusd-busd-usdt-lps", imageUrl: "https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdt.svg&w=128&q=75", vaultType: "Stable"},       
    { id: 19, alePoolId: -1, name: 'BUSD-USDT',farm: VAULT_FARMS.DOPPLE,lpTokenAddress: "0x124166103814E5a033869c88e0F40c61700Fca17", vaultAddress: "0xE39DaEc240881CB07570f1a24F9ca8A1E39c2B4c",addLpUrl:"2-pools-lps", imageUrl: "https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fbusd-usdt.svg&w=128&q=75", vaultType: "Stable"}, 
    // { id: 20, alePoolId: -1, name: 'UST-BUSD-USDT',farm: VAULT_FARMS.DOPPLE,lpTokenAddress: "0x7Edcdc8cD062948CE9A9bc38c477E6aa244dD545", vaultAddress: "0xBa9BDB60aea0a4F9B479F904566a933C55644bb1",addLpUrl:"ust-pools-lps", imageUrl: "https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fust-pool.png&w=128&q=75", vaultType: "Stable"},    
    { id: 21, alePoolId: -1, name: 'BUSD-USDT-DOLLY',farm: VAULT_FARMS.DOPPLE,lpTokenAddress: "0xAA5509Ce0ecEA324bff504A46Fc61EB75Cb68B0c", vaultAddress: "0xa295DDBF6404f868d3D311F66708C6CEC42846b8",addLpUrl:"dolly-lps", imageUrl: "https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fdolly-pool.svg&w=128&q=75", vaultType: "Stable"},       
    // { id: 22, alePoolId: -1, name: 'KUSD-BUSD-USDC',farm: VAULT_FARMS.DOPPLE,lpTokenAddress: "0x2bf718e5FA2106dc1998D3F964C1baea8Bda36E1", vaultAddress: "0x9141E6fc458E02687c448957ecbFc9686f5B3d00",addLpUrl:"kusd-busd-usdc-lps", imageUrl: "https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc.svg&w=128&q=75", vaultType: "Stable"},  
    // // { id: 24, alePoolId: -1, name: 'KUSD-BUSD-DOLLY',farm: VAULT_FARMS.DOPPLE,lpTokenAddress: "0x1483767E665B3591677Fd49F724bf7430C18Bf83", vaultAddress: "0xB2bBa912D9CaB53AB82D91Bd754E61D884c3c505",addLpUrl:"kusd-busd-dolly-lps", imageUrl: "https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-dolly.svg&w=128&q=75", vaultType: "Stable"},       
    // { id: 25, alePoolId: -1, name: 'KUSD-BUSD-USDC-USDN',farm: VAULT_FARMS.DOPPLE,lpTokenAddress: "0x367189B09340a6e157e5fD31Cb0924E91B71710C", vaultAddress: "0xFa15C31dCB0b9f74A7C67459D03BE1A424c86a01",addLpUrl:"kusd-busd-usdc-usdn-lps", imageUrl: "https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc-usdn.svg&w=128&q=75", vaultType: "Stable"},     
    { id: 26, alePoolId: -1, name: 'KUSD-BUSD-USDC-TUSD',farm: VAULT_FARMS.DOPPLE,lpTokenAddress: "0x07FE67a1cFc285E14E72fb5Bb03A6cCfa6224Aa8", vaultAddress: "0x95c40489009Ca9d084B7B1434fcCDFB9DccF330f",addLpUrl:"kusd-busd-usdc-tusd-lps", imageUrl: "https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc-tusd.svg&w=128&q=75", vaultType: "Stable"},    


  

    // { id: 32, alePoolId: -1, name: 'TWX-DOPX',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0xb89DAF71d52A85cC5d373EE91e251bB87BE33940", vaultAddress: "0x50C3229640181d8A3b34595Ba90E015183b0c480"},            
    { id: 33, alePoolId: -1, name: 'TWX-BNB',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0xFD555bD87b98160649DFaA42eaE46B022d71c71A", vaultAddress: "0x085b7cE8c30f4aF095948673BA5B2f31CfD1E690"},            
    { id: 34, alePoolId: -1, name: 'TWX-KUSD',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0x08422f6Cc26cCDa692a36a73A520Da6b0E6d3DE3", vaultAddress: "0xd309191987f3662e9DBe39DA88358E4FfB296c43"},            
    { id: 35, alePoolId: -1, name: 'KUSD-tJPY',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0xA4F1CD73093aC7976c05123E9D0f14bb985c707F", vaultAddress: "0xdbBEc9E83f012cD5AE9cf455954b30D34E2C1dBd"},            
    { id: 36, alePoolId: -1, name: 'KUSD-tEUR',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0xCAf8523E9e4ab85bE21974B571e1F87a5e53EA80", vaultAddress: "0x5357E431feD0f390Ad467E35215F87AFFcC0dA59"},            
    // { id: 37, alePoolId: -1, name: 'KUSD-tXAU',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0x12fc3AB288C4c53b85c702da60602DD4F03d0240", vaultAddress: "0x776Dfec84D3924f824bb9EDdC79f4C8c2bb7e986"},                    
    { id: 38, alePoolId: -1, name: 'KUSD-tAAPL',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0xBf6E9c90d50F9387f037cd7C24C6919Eb5690d3c", vaultAddress: "0x26C3d956fBED80E2818b26458954C5d911B2C31F"},            
    { id: 39, alePoolId: -1, name: 'KUSD-tTSLA',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0x8b90e1e1fa7E6cD22f9CB64ff7977F56405c837f", vaultAddress: "0x36C0430b339504c2eb438372f450839b398b1adc"},                    
    { id: 40, alePoolId: -1, name: 'KUSD-tTSM',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0x1238ae9Ed4da584f4eeD6187b2268975f2787dC0", vaultAddress: "0xF94f84EaE919Bb2eC9c4017C47565641d97716Fc"},                        
    // { id: 41, alePoolId: -1, name: 'KUSD-tARKK',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0x916ca8acDfeE75F8d2902C84635223F7618e772a", vaultAddress: "0x03304b2926E5Db4E4A3e8aDC46B966E08c6CFCf1"},                        
    // { id: 42, alePoolId: -1, name: 'KUSD-tMRNA',farm: VAULT_FARMS.TWINDEX,lpTokenAddress: "0xf73D288b6905f84D953A546Ada3C779726992f81", vaultAddress: "0xE650a44e30219FD18F7C48404c4B80BdDbA54D36"},                            

    { id: 46, alePoolId: -1, name: 'BSW-BNB',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0x46492B26639Df0cda9b2769429845cb991591E0A", vaultAddress: "0xB8781eE628A0D76F818C127A5BF64b22C51BC348"},    
    { id: 13, alePoolId: -1, name: 'BSW-USDT',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0x2b30c317ceDFb554Ec525F85E79538D59970BEb0", vaultAddress: "0xaabC7b3dA2823126EF81F3142C34E46b8d0Eb7EC"},    
    { id: 14, alePoolId: 25, name: 'USDT-USDC',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0x1483767E665B3591677Fd49F724bf7430C18Bf83", vaultAddress: "0xB2bBa912D9CaB53AB82D91Bd754E61D884c3c505", vaultType: "Stable"},
    { id: 49, alePoolId: -1, name: 'USDT-BUSD',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0xDA8ceb724A06819c0A5cDb4304ea0cB27F8304cF", vaultAddress: "0xB92c3bab6f12CDD0566e58443Ea79A595119B889", vaultType: "Stable"},
    { id: 50, alePoolId: -1, name: 'UST-BUSD',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0x9E78183dD68cC81bc330CAF3eF84D354a58303B5", vaultAddress: "0x2Ca82bd2A81E7060c2Bd3eEeC0b7FD7df98b5317", vaultType: "Stable"},        
    { id: 44, alePoolId: -1, name: 'BTCB-USDT',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0xa987f0b7098585c735cD943ee07544a84e923d1D", vaultAddress: "0x3b814fBF33A58eB3F16164808530Be47A4256fa8"},    
    { id: 47, alePoolId: -1, name: 'BTCB-BNB',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0xC7e9d76ba11099AF3F330ff829c5F442d571e057", vaultAddress: "0x01cea2c43F2BEb49A33423130E501599Ab46B1B3"},    
    { id: 48, alePoolId: -1, name: 'BTCB-ETH',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0x6216E04cd40DB2c6FBEd64f1B5830A98D3A91740", vaultAddress: "0x30e87c9AB10cFFAF82dab6AF5B845b588745cbeF"},        
    { id: 29, alePoolId: -1, name: 'ETH-USDT',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0x63b30de1A998e9E64FD58A21F68D323B9BcD8F85", vaultAddress: "0xB82CfEdFE4056Da45A0508c168a69260941F5FAA"},    
    { id: 43, alePoolId: -1, name: 'BNB-BUSD',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0xaCAac9311b0096E04Dfe96b6D87dec867d3883Dc", vaultAddress: "0x2Df557Cb1398A85B52dBCCc21adC35C0bF86BcDe"},    
    { id: 45, alePoolId: -1, name: 'CAKE-BNB',farm: VAULT_FARMS.BISWAP,lpTokenAddress: "0x3d94d03eb9ea2D4726886aB8Ac9fc0F18355Fd13", vaultAddress: "0xAc8AACCe3C809397C40409E7cb0Eb4630201253b"},    

    // { id: 16, alePoolId: -1, name: 'BANANA-BNB',farm: VAULT_FARMS.APESWAP,lpTokenAddress: "0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713", vaultAddress: "0x84FC45745f4232C2aEF5315ad8d6a03fcD95a4a7"},    
    { id: 17, alePoolId: -1, name: 'BUSD-USDC',farm: VAULT_FARMS.APESWAP,lpTokenAddress: "0xC087C78AbaC4A0E900a327444193dBF9BA69058E", vaultAddress: "0x97f7B0918C6c08A69b71AC5DD7317Dc7E1951E86", vaultType: "Stable"},




    // { id: 0, alePoolId: -1, name: 'WAD-BNB',farm: VAULT_FARMS.WARDEN,lpTokenAddress: "0xDc683Adb914EdF91df4A36c33EE4f59ca41bC263", vaultAddress: "0xD9e10F715eFBad7B43099B00F1b0b4CA5766a27F"},    
    // { id: 1, alePoolId: -1, name: 'WAD-BUSD',farm: VAULT_FARMS.WARDEN,lpTokenAddress: "0xc95B1750043FCE5dfCc8539835Ea3830Ec002A89", vaultAddress: "0xd794d913EaACf44849AaC641FA0217Cb05d84C56"},        
    // { id: 5, name: 'BNB-USDT',farm: VAULT_FARMS.WARDEN,lpTokenAddress: "0xfD468f81f4a6D859a0eB3667c65F7BEA9Dc69028", vaultAddress: "0x401b3C4c1240B36B3B0bc126B704364C5A192D41"},    
    // {id: 6, name: "USDT-BUSD",farm: VAULT_FARMS.WARDEN, lpTokenAddress: '0x087d69B97a6dF4FB37E4E93A31752008223A6C19',vaultAddress: '0x87b14df4C2C4484F4DF595533dabF9cD4150F064'},
    // {id: 2, name: "BNB-BUSD", farm: VAULT_FARMS.WARDEN,  lpTokenAddress: '0xCf643C4B9DBf42239aa00e23A0570c90d517E6dC',vaultAddress: '0x3F78C723846974f25AA572ab960B840E58464CE8'},
    // {id: 3, alePoolId: -1, name: "BTCB-BNB",farm: VAULT_FARMS.WARDEN,  lpTokenAddress: '0x1b1675A97b2f62B568569ebD349E88A04DdE8586',vaultAddress: '0x1Bc479B641C7b39CF518908BE213F060BFF4b97D'},
    // {id: 4, alePoolId: -1, name: "ETH-BNB", farm: VAULT_FARMS.WARDEN ,  lpTokenAddress: '0x8485c5f255FF30AafaB0030329e508BD8dDE11c5',vaultAddress: '0x00a39560Df700ea695Fc409f5b82d365ABc0f984'},
]


export function getVaultAssets() : string[] {
  let assets: string[] = []
  for (var vault of VAULT_LISTS) {
     const tokens = vault.name.split("-")
     assets = [...tokens, ...assets]
  }   
  return [...new Set(assets)].sort()
}