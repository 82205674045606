// import { JSBI, TokenAmount, WETH } from '@uniswap/sdk'
import { JSBI, TokenAmount } from '@uniswap/sdk'
import { usePair } from 'data/Reserves'
import { useAllTokens } from 'hooks/Tokens'
import { useWindowSize } from 'hooks/useWindowSize'
import { darken } from 'polished'
import React, { useCallback, useState } from 'react'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Text } from 'rebass'
import { useWalletModalToggle } from 'state/application/hooks'
import styled from 'styled-components'
import { useFarmDataFromPoolID } from 'utils/aleSwapAPI'
import { FarmInfo, getTokenByName } from 'utils/farm'
import { BonusBox } from '.'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { StakingModal } from '../../components/earn/StakingModal'
import UnstakingModal from '../../components/earn/UnstakingModal'
import { BIG_INT_ZERO, BREWMASTER_ADDRESS, WETH } from '../../constants'
import { useUserStaked } from '../../data/TotalStaked'
import { useTotalSupply } from '../../data/TotalSupply'
import { useActiveWeb3React } from '../../hooks'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useColor } from '../../hooks/useColor'
import { useAleStakingInfo } from '../../state/stake/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, MEDIA_WIDTHS } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import useBUSDPrice from '../../utils/useUSDCPrice'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonEmpty, ButtonPrimary } from '../Button'
import Card, { RectLightCard } from '../Card'
import { AutoColumn } from '../Column'
import { OverlapDoubleCurrencyLogo } from '../DoubleLogo'
import Row, { AutoRow, RowBetween } from '../Row'
import { Dots } from '../swap/styleds'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const SingleFixedHeightRow = styled(RowBetween)`
  height: 36px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
export const StyledPositionCard = styled(RectLightCard) <{ bgColor: any }>`
  border: none;
  background: ${({ theme }) => theme.itemBG};
  position: relative;
  overflow: hidden;
  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;  
`
export const SingleClickableFixedHeightRow = styled(SingleFixedHeightRow)`
cursor: pointer;
`

export const ClickableFixedHeightRow = styled(FixedHeightRow)`
cursor: pointer;
`

export const PairText = styled(Text)`
  color: ${({ theme }) => theme.tokenText};
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`
export const TVLText = styled(Text)`

  color: #347AF0;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`
export const DataText = styled(Text)`
color: ${({ theme }) => theme.aprText};
font-family: var(--font-family-titillium_web);
font-size: 16px;
font-weight: 700;
font-style: normal;
`


const SubText = styled(Text)`
  color: ${({ theme }) => theme.subText};
  font-family: var(--font-family-titillium_web);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
`

export const DetailText = styled(Text)`
${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

export const PairRow = styled(Row)`
  width:130px;
  // flex-wrap: wrap;  
  flex-direction: row
`

// const alePerBlock = JSBI.BigInt(19)

// const totalAllocPoint = JSBI.BigInt(184333)
// const blockPerDay = JSBI.BigInt(28800)
// const dayPerYear = JSBI.BigInt(365)
// const ONE_HUNDRED = JSBI.BigInt(100)


// interface PairFarmCardProps {
//   //pair: Pair
//   token0: Token
//   token1: Token
//   token0Symbol: string
//   poolId : number
//   showUnwrapped?: boolean
//   border?: string
//   stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
// }


interface PairFarmCardProps {
    farm : FarmInfo
    // pairName: string
    // poolId: number
    showUnwrapped?: boolean
    border?: string
    stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
    // startTime?: string
  }
  
  
  //export function PairFarmCard({ //pair, token0,token1,token0Symbol, poolId, border, stakedBalance }: PairFarmCardProps) {
  export function PairFarmCard({ farm, border, stakedBalance }: PairFarmCardProps) {
    const { account, chainId } = useActiveWeb3React()
  
    const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

    const { width,  } = useWindowSize()
    const poolId = farm.id
    const pairName = farm.name
    const startTime = farm.startTime    
  
    const farmData = useFarmDataFromPoolID(poolId)
  
    const smallScreen = width && width < MEDIA_WIDTHS.upToSmall ? true : false
  
    const allTokens = useAllTokens()
  
    const tokens = pairName.split("-")
  
    const sToken0 = getTokenByName(tokens[0], chainId)
    const sToken1 = getTokenByName(tokens[1], chainId)
  
    const atoken0 = chainId && sToken0.name === "BNB" ? WETH[chainId] : allTokens[sToken0.address]
    const atoken1 = chainId && sToken1.name === "BNB" ? WETH[chainId] : allTokens[sToken1.address]
  
    const [, pair] = usePair(atoken0, atoken1)
  
    const stakingInfo = useAleStakingInfo(pair)?.[0]
  
    // const allocPoint = JSBI.BigInt(allocsPoint[poolId])
  
    // const alePrice = useBUSDPrice(chainId ? ALE[chainId] : undefined)
    // const alePerBlock = JSBI.BigInt(useRewardPerBlock())
  
    // const token0 = (pair.token0.symbol == token0Symbol) ? pair.token0 : pair.token1
    // const token1 = (pair.token1.symbol == token0Symbol) ? pair.token0 : pair.token1
  
    // const token0 = pair ? pair.token0 : undefined
    // const token1 = pair ? pair.token1 : undefined
  
    const currency0 = atoken0 ? unwrappedToken(atoken0) : undefined
    const currency1 = atoken1 ? unwrappedToken(atoken1) : undefined
  
    const [showMore, setShowMore] = useState(false)
  
    // const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
    // const totalPoolTokens = useTotalSupply(pair?.liquidityToken)
    // const totalStakedTokens = useTotalStaked(pair?.liquidityToken)
  
  
    // const [token0Deposited, token1Deposited] =
    //   !!pair &&
    //     !!totalPoolTokens &&
    //     !!totalStakedTokens &&
    //     // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    //     JSBI.greaterThan(totalPoolTokens.raw, JSBI.BigInt(0))
    //     && JSBI.greaterThanOrEqual(totalPoolTokens.raw, totalStakedTokens.raw)
    //     ? [
    //       pair.getLiquidityValue(pair.token0, totalPoolTokens, totalStakedTokens, false),
    //       pair.getLiquidityValue(pair.token1, totalPoolTokens, totalStakedTokens, false)
    //     ]
    //     : [undefined, undefined]
  
    // const token0Price = useBUSDPrice(chainId ? token0 : undefined)
    // const token1Price = useBUSDPrice(chainId ? token1 : undefined)
  
    //  console.log("token0Price "+token0Price?.raw.toFixed(2)+ " token1Price "+token1Price?.raw.toFixed(2))
  
    // const token0TVL = token0Deposited && token0Price ? token0Price.quote(token0Deposited) : undefined
    // const token1TVL = token1Deposited && token1Price ? token1Price.quote(token1Deposited) : undefined
  
    // const totalValueLocked = token0TVL && token1TVL ? token0TVL.add(token1TVL) : undefined
  
    // const farmAPR = totalValueLocked && alePrice ? alePrice.multiply(alePerBlock).multiply(blockPerDay).divide(totalValueLocked) : undefined
  
    // const farmAPR = totalValueLocked && JSBI.greaterThan(totalValueLocked.raw, JSBI.BigInt(0)) && alePrice && alePrice.greaterThan(JSBI.BigInt(0)) ?
    //   alePrice.divide(totalValueLocked).multiply(blockPerDay).multiply(alePerBlock).multiply(dayPerYear).multiply(allocPoint).divide(totalAllocPoint).multiply(ONE_HUNDRED) : 0
  
    const userLPBalance = useTokenBalance(account ?? undefined, pair?.liquidityToken)
    // const userLPBalance = useUserStaked(poolId,pair.liquidityToken)
  
    // if staked balance balance provided, add to standard liquidity amount
    // const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance
    // const userPoolBalance = useUserStaked(poolId, pair?.liquidityToken)
    const userPoolBalance = stakingInfo ? stakingInfo.stakedAmount : undefined
  
    // const userPoolEarned = useUserEarned(poolId,pair.liquidityToken)
    const userPoolEarned = stakingInfo ? stakingInfo.earnedAmount : undefined
  
    // const [userToken0Deposited, userToken1Deposited] =
    //   !!pair &&
    //     !!totalPoolTokens &&
    //     !!userPoolBalance &&
    //     // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    //     JSBI.greaterThan(totalPoolTokens.raw, JSBI.BigInt(0)) &&
    //     JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
    //     ? [
    //       pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
    //       pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
    //     ]
    //     : [undefined, undefined]
  
    // const userToken0Value = userToken0Deposited && token0Price ? token0Price.quote(userToken0Deposited) : undefined
    // const userToken1Value = userToken1Deposited && token1Price ? token1Price.quote(userToken1Deposited) : undefined
  
    // const userPoolValue = userToken0Value && userToken1Value ? userToken0Value.add(userToken1Value) : undefined

    const userPoolValue = farmData && farmData.pricePerShare && userPoolBalance && pair ? new TokenAmount(pair.liquidityToken,
      JSBI.divide(
        JSBI.multiply(userPoolBalance.raw, JSBI.BigInt(Math.trunc(farmData.pricePerShare * 100000))),
        JSBI.BigInt(100000))) : undefined
  
    //  const poolTokenPercentage = undefined
  
    const backgroundColor = useColor(pair?.token0)
  
    //const [approvalLP, approveLPCallback] = useApproveCallback(new TokenAmount(pair.liquidityToken,JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')), chainId ? BREWMASTER_ADDRESS[chainId] : '')
    const [approvalLP, approveLPCallback] = useApproveCallback(userLPBalance, chainId ? BREWMASTER_ADDRESS[chainId] : '')
  
    const [showStakingModal, setShowStakingModal] = useState(false)
    const [showUnstakingModal, setShowUnstakingModal] = useState(false)
    const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)
  
    const handleDepositClick = useCallback(() => {
      if (account) {
        setShowStakingModal(true)
      }
    }, [account])
  
    const renderer = ({ days, hours, minutes, seconds, completed } : CountdownRenderProps) => {
      if (completed) {
        // Render a completed state
        return <></>;
      } else {
        // Render a countdown
        return <span>{days} days {hours} hours {minutes} minutes {seconds} seconds</span>;
      }
    };
  
  
    return (
      <StyledPositionCard border={border} bgColor={backgroundColor} >
        <StakingModal
          isOpen={showStakingModal}
          onDismiss={() => setShowStakingModal(false)}
          stakingInfo={stakingInfo}
          userLiquidityUnstaked={userLPBalance} />
        <UnstakingModal
          isOpen={showUnstakingModal}
          onDismiss={() => setShowUnstakingModal(false)}
          stakingInfo={stakingInfo}
        />
        <ClaimRewardModal
          isOpen={showClaimRewardModal}
          onDismiss={() => setShowClaimRewardModal(false)}
          stakingInfo={stakingInfo}
        />
        <AutoColumn gap="12px">
          <ClickableFixedHeightRow onClick={() => setShowMore(!showMore)}>
            <AutoRow width="280px">
              <OverlapDoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
              {/* <DoubleTokenLogo token0="https://teerapat.com/aleswap/aleswapcoin.png" token1="https://tokens.bscswap.com/images/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png" pairName="ALE-BNB" multiplier0="20" /> */}
              {/* <DoubleTokenLogo currency0={currency0} currency1={currency1} multiplier="1" /> */}
              
              <PairText>
                &nbsp;{!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}-${currency1.symbol}`}
              </PairText>
              {farm.bonus !== "" && (<BonusBox>{farm.bonus}X</BonusBox>) }
            </AutoRow>
            {smallScreen ? (
              <></>
            ) : (
              <>
                <AutoRow width="150px" justify="center">
                  <AutoColumn justify="center">
                    <DataText>
                      {farmData ? farmData.apr.toLocaleString('en-US', { maximumFractionDigits: 1 }) : (<Dots></Dots>)}%
                    </DataText>
                    <SubText>
                      APR
                    </SubText>
                  </AutoColumn>
                </AutoRow>
                <AutoRow width="120px" justify="center">
                    <AutoColumn justify="center">
                      <DataText>
                        ${farmData ? farmData.tvl.toLocaleString('en-US', { maximumFractionDigits: 0 }) : (<Dots></Dots>)}
                      </DataText>
                      <SubText>
                        TVL
                      </SubText>                      
                    </AutoColumn>
                </AutoRow>
              </>
            )}
            {smallScreen ? (
              <></>
            ) : (
  
              <div>
                <ButtonEmpty
                  padding="6px 8px"
                  borderRadius="12px"
                  width="fit-content"
                  color="#B5BBC9"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? (
                    <>
                      {/* <DetailText>Details</DetailText> */}
                      <ChevronUp size="20" style={{ marginLeft: '10px' }} />
                    </>
                  ) : (
                    <>
                      {/* <DetailText>Details</DetailText> */}
                      <ChevronDown size="20" style={{ marginLeft: '10px' }} />
                    </>
                  )}
                </ButtonEmpty>
              </div>
            )}
  
          </ClickableFixedHeightRow>
  
          {(showMore || smallScreen) && (
            <AutoColumn gap="8px">
              {smallScreen ? (
                <>
                  <FixedHeightRow>
                    <Text fontSize={16} fontWeight={500}>
                      APR:
                    </Text>
                    <DataText>
                      {farmData ? farmData.apr.toLocaleString('en-US', { maximumFractionDigits: 1 }) : (<Dots></Dots>)}%
                    </DataText>
                  </FixedHeightRow>
                  <FixedHeightRow>
                    <Text fontSize={16} fontWeight={500}>
                      TVL:
                    </Text>
                    <DataText>
                      ${farmData ? farmData.tvl.toLocaleString('en-US', { maximumFractionDigits: 0 }) : (<Dots></Dots>)}
                    </DataText>
                  </FixedHeightRow>
                </>
              ) : (<></>)
              }
              <FixedHeightRow>
                {!currency0 || !currency1 ? <Dots>Loading</Dots> :
                  <ExternalLink
                    style={{ width: '100%', textAlign: 'left' }}
                    href={`#/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                  >
                    Add {currency0.symbol}-{currency1.symbol} LP <span style={{ fontSize: '11px' }}>↗</span>
                  </ExternalLink>}
              </FixedHeightRow>
              <FixedHeightRow>
                <ExternalLink
                  style={{ width: '100%', textAlign: 'left' }}
                  href={`http://bscscan.com/address/${farmData?.pair}`}
                >
                  View contract <span style={{ fontSize: '11px' }}>↗</span>
                </ExternalLink>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Your available LP:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {userLPBalance ? userLPBalance.toSignificant(4) : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Your deposited LP:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Your deposited LP Value:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  ${userPoolValue ? userPoolValue.toFixed(2, { groupSeparator: ',' }) : '-'}
                </Text>
              </FixedHeightRow>
              { startTime && startTime.length > 0 && new Date(startTime) >= new Date() ? (
              <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
              Earn ALE. Reward start in 
              </Text>
               <Text fontSize={16} fontWeight={700}>
                <Countdown date={startTime} renderer={renderer}></Countdown>
              </Text>
            </FixedHeightRow>
              ) : (
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Earned:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {userPoolEarned ? userPoolEarned.toSignificant(6) : '-'} ALE
                </Text>
              </FixedHeightRow>
              )}
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Deposit fee:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  0%
                </Text>
              </FixedHeightRow>  
              <RowBetween marginTop="10px">
  
                {approvalLP && approvalLP === ApprovalState.NOT_APPROVED || approvalLP === ApprovalState.PENDING ? (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    onClick={approveLPCallback}
                    disabled={approvalLP === ApprovalState.PENDING}
  
                    width="32%"
                  >
                    {approvalLP === ApprovalState.PENDING ? (
                      <Dots>Approving...</Dots>
                    ) : (
                      'Enable'
                    )}
                  </ButtonPrimary>
                )
                  : approvalLP && approvalLP === ApprovalState.APPROVED ? (
                    <ButtonPrimary
                      padding="8px"
                      borderRadius="8px"
                      onClick={handleDepositClick}
                      width="32%"
                    >
                      Stake
                    </ButtonPrimary>
                  ) : !account ? (
                    <ButtonPrimary
                        padding="8px"
                        borderRadius="8px"
                        onClick={toggleWalletModal}
                        width="32%"
                    >
                        Connect Wallet
                    </ButtonPrimary>
                    ) : (
                    <ButtonPrimary
                      padding="8px"
                      borderRadius="8px"
                      disabled={true}
                      onClick={handleDepositClick}
                      width="32%"
                    >
                      Enable
                    </ButtonPrimary>
                  )}
  
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width="32%"
                  onClick={() => setShowUnstakingModal(true)}
                  disabled={userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, BIG_INT_ZERO) ? false : true}
                >
                  Withdraw
                </ButtonPrimary>
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width="32%"
                  onClick={() => setShowClaimRewardModal(true)}
                  disabled={userPoolEarned && JSBI.greaterThan(userPoolEarned.raw, BIG_INT_ZERO) ? false : true}
                >
                  Harvest
                </ButtonPrimary>
              </RowBetween>
            </AutoColumn>
          )}
        </AutoColumn>
      </StyledPositionCard>
    )
  }
  