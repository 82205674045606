import { BigNumber } from '@ethersproject/bignumber'
import { Token, TokenAmount, WETH } from '@uniswap/sdk'
import { BREWMASTER_ADDRESS, STAKING_REWARDS_ADDRESS } from '../constants'
import { useAleStakingRewardsContract, useBrewmasterContract, usePrivateVaultContract, useTokenContract, useVaultContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useActiveWeb3React } from '../hooks'
import { NumberFormatValidator } from 'ajv'
import { Vault } from 'utils/vault'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched


export function useTotalStaked(token?: Token): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)
  const {chainId } = useActiveWeb3React()

  const totalStaked: BigNumber = useSingleCallResult(contract, 'balanceOf',[chainId ? BREWMASTER_ADDRESS[chainId]:undefined])?.result?.[0]

  return token && totalStaked ? new TokenAmount(token, totalStaked.toString()) : undefined
}

export function useTotalStakedBNBPool(token?: Token): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const totalStaked: BigNumber = useSingleCallResult(contract, 'balanceOf',[STAKING_REWARDS_ADDRESS])?.result?.[0]

  return token && totalStaked ? new TokenAmount(token, totalStaked.toString()) : undefined
}


export function useUserStakedBNBPool(token?: Token): TokenAmount | undefined {
  const contract = useAleStakingRewardsContract()
  const {account } = useActiveWeb3React()

  const userStaked: BigNumber = useSingleCallResult(contract, 'balanceOf',[account ? account: undefined])?.result?.[0]

  return token && userStaked ? new TokenAmount(token, userStaked.toString()) : undefined
}

export function useUserStaked(poolId: number,token?: Token): TokenAmount | undefined {
    const contract = useBrewmasterContract()
    const {account } = useActiveWeb3React()
  
    const userStaked: BigNumber = useSingleCallResult(contract, 'userInfo',[poolId,account ? account: undefined])?.result?.[0]
  
    return token && userStaked ? new TokenAmount(token, userStaked.toString()) : undefined
}

export function useUserVaultStaked(vault: Vault): TokenAmount | undefined {
  const vaultContract = useVaultContract(vault.vaultAddress)
  const lpToken = new Token(56,vault.lpTokenAddress,18)

  const {account } = useActiveWeb3React()

  const userStaked: BigNumber = useSingleCallResult(vaultContract, 'balanceOf',[account ? account: undefined])?.result?.[0]

  return lpToken && userStaked ? new TokenAmount(lpToken, userStaked.toString()) : undefined
}

export function useUserPrivateVaultStaked(vault: Vault): TokenAmount | undefined {
  const vaultContract = usePrivateVaultContract(vault.vaultAddress)
  const lpToken = new Token(56,vault.lpTokenAddress,18)

  const {account } = useActiveWeb3React()

  const userStaked: BigNumber = useSingleCallResult(vaultContract, 'balanceOf',[account ? account: undefined])?.result?.[0]

  return lpToken && userStaked ? new TokenAmount(lpToken, userStaked.toString()) : undefined
}

export function useUserVaultEarned(vault: Vault): TokenAmount | undefined {
  const vaultContract = usePrivateVaultContract(vault.vaultAddress)
  const lpToken = new Token(56,vault.lpTokenAddress,18)

  const {account } = useActiveWeb3React()

  const userStaked: BigNumber = useSingleCallResult(vaultContract, 'earned',[account ? account: undefined])?.result?.[0]

  return lpToken && userStaked ? new TokenAmount(lpToken, userStaked.toString()) : undefined
}

export function useUserEarned(poolId: number,token?: Token): TokenAmount | undefined {
    const contract = useBrewmasterContract()
    const {account } = useActiveWeb3React()
  
    const userEarned: BigNumber = useSingleCallResult(contract, 'pendingAle',[poolId,account ? account: undefined])?.result?.[0]
  
    return token && userEarned ? new TokenAmount(token, userEarned.toString()) : undefined
}

export function useUserEarnedBNBPool(token?: Token): TokenAmount | undefined {
  const contract = useAleStakingRewardsContract()
  const {account } = useActiveWeb3React()

  const userEarned: BigNumber = useSingleCallResult(contract, 'earned',[account ? account: undefined])?.result?.[0]

  return token && userEarned ? new TokenAmount(token, userEarned.toString()) : undefined
}

export function useRewardForDurationBNBPool(): TokenAmount | undefined {
  const contract = useAleStakingRewardsContract()

  const rewardForDuration: BigNumber = useSingleCallResult(contract, 'getRewardForDuration',[])?.result?.[0]

  return rewardForDuration ? new TokenAmount(WETH[56], rewardForDuration.toString()) : undefined
}