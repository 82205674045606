import { JSBI, Token, TokenAmount } from '@uniswap/sdk'
import { usePair, usePairFromLpAddress } from 'data/Reserves'
import { useAllTokens } from 'hooks/Tokens'
import { useWindowSize } from 'hooks/useWindowSize'
import { darken } from 'polished'
import React, { useCallback, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Text } from 'rebass'
import styled from 'styled-components'
import { getTokenByName } from 'utils/farm'
import { Vault } from 'utils/vault'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { VaultStakingModal } from './VaultStakingModal'
import { VaultUnstakingModal } from './VaultUnstakingModel'
import { BIG_INT_ZERO, WETH } from '../../constants'
import { useUserVaultStaked } from '../../data/TotalStaked'
import { useActiveWeb3React } from '../../hooks'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useColor } from '../../hooks/useColor'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useVaultStakingInfo } from '../../state/stake/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, MEDIA_WIDTHS } from '../../theme'
import { currencyAddress } from '../../utils/currencyId'
import { useFarmDataFromPoolID, useVaultData } from 'utils/aleSwapAPI'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonEmpty, ButtonPrimary } from '../Button'
import Card, { RectLightCard } from '../Card'
import Column, { AutoColumn } from '../Column'
import { CustomCurrencyLogo, VaultCurrencyLogo } from '../DoubleLogo'
import Row, { AutoRow, RowBetween } from '../Row'
import { Dots } from '../swap/styleds'
import { Input as NumericalInput } from '../NumericalInput'
import Slider from '../../components/Slider'
import { ArrowDown } from 'react-feather'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import useDebouncedChangeHandler from 'utils/useDebouncedChangeHandler'
import { parseEther } from 'ethers/lib/utils'
import {  Pair } from '@uniswap/sdk'
import { BonusBox } from 'components/FarmCard'
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import QuestionHelper, { LightQuestionHelper } from 'components/QuestionHelper'
import { HelpCircle as Question } from 'react-feather'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import { DataCard } from 'components/earn/styled'


const TooltipsRow = styled.div`
  display: flex;
  align-items: center;
`

interface VaultAPYTooltipsProps {
  vaultApy: number
  farmApr?: number
  tradingApy?: number
}


export default function VaultAPYTooltips({ farmApr, vaultApy, tradingApy }: VaultAPYTooltipsProps) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };  
  return (
    
    <Tooltip arrow placement="top" leaveTouchDelay={5000}
      open={open} onClose={handleTooltipClose} onOpen={handleTooltipOpen}    
      title={           
      <React.Fragment>
        <>
        <Column style={{ width: '200px' }}>
            <RowBetween>
              <div>Vault Compound APY: </div><div> {vaultApy.toFixed(2)}% </div>
            </RowBetween>      
            { tradingApy ? (
              <RowBetween>
                <div>Trading APY: </div><div> {tradingApy.toFixed(2)}% </div>
              </RowBetween>
              ) : (<></>)
            }                
            { farmApr ? (
              <RowBetween>
                <div>Staking APR: </div><div> {farmApr.toFixed(2)}% </div>
              </RowBetween>
              ) : (<></>)
            }
        </Column>
        </>
      </React.Fragment> 
        } >
      <TooltipsRow>APY<IconButton size="small" color="inherit" aria-label="apr" component="span" onClick={handleTooltipOpen}><Question size={16}/></IconButton></TooltipsRow>
    </Tooltip>
  );
}


const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`
const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bg1};
`

const InputPanel = styled.div<{ hideInput?: boolean,disabled?: boolean }>`
  width: 100%;
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  background-color: ${({ theme }) => theme.bg2};
  // background-color: ${({ disabled }) => 'black'};
  z-index: 1;
`

export const MaxButton = styled.button<{ width: string }>`
  background-color: ${({ theme }) => theme.bg0};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 0px;
  font-size: 0.8rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 500;
  cursor: pointer;

  overflow: hidden;
  color: ${({ theme }) => theme.primary1};

`

//   margin: 0.25rem;
// :hover {
//   border: 1px solid ${({ theme }) => theme.primary1};
// }
// :focus {
//   border: 1px solid ${({ theme }) => theme.primary1};
//   outline: none;
// }
//   background-color: ${({ theme }) => theme.primary5};
// border: 1px solid ${({ theme }) => theme.primary5};
// 

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

export const HeaderRow = styled(RowBetween)`
  height: 80px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 10px;
    height: 40px;
`};  
`

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
const StyledPositionCard = styled(RectLightCard) <{ bgColor: any }>`
  background: ${({ theme }) => theme.itemBG};
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 2px 
#00000040;  
`
const ClickableHeaderRow = styled(HeaderRow)`
cursor: pointer; 
`
const PairText = styled(Text)`
  color: ${({ theme }) => theme.tokenText};
  font-family: var(--font-family-titillium_web);
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
`
const TVLText = styled(Text)`

  color: ${({ theme }) => theme.aprText};
  font-family: var(--font-family-titillium_web);
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
`
const DataText = styled(Text)`
  color: ${({ theme }) => theme.aprText};
  font-family: var(--font-family-titillium_web);
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
`

const SubText = styled(Text)`
  color: ${({ theme }) => theme.subText};
  font-family: var(--font-family-titillium_web);
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
`


const SmallSubText = styled(Text)`
  color: ${({ theme }) => theme.subText};
  font-family: var(--font-family-titillium_web);
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
`

const DetailText = styled(Text)`
color: ${({ theme }) => theme.tokenText};
${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

export const PairRow = styled(Row)`
  width:130px;
  // flex-wrap: wrap;  
  flex-direction: row
`
export const LPText = styled.div`
  padding: 8px;
`

const DepositWithdrawWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};  
`

const DepositPanel = styled.div`
  padding-top:16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg0};
  padding: 16px;  
  margin: 8px;
  width: 50%;
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    margin: 0px;    
  
  `};    
`

const WithdrawPanel = styled.div`
  display: flex;
  padding: 16px;  
  flex-direction: column;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg0}; 
  margin: 8px;
  width: 50%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    margin: 0px;    
    margin-top: 16px;

  `};    
`

const RoundShadowCard = styled(DataCard)`

  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;
  background: ${({ theme }) => theme.white};
  overflow: hidden;
`

const StyledSelect = styled(Select)`
color: ${({ theme }) => theme.tokenText};

& .MuiSvgIcon-root {
  color: ${({ theme }) => theme.tokenText};
}

&.MuiInput-underline:before {
  border-bottom: 0px solid;
}

&.MuiInput-underline:hover:before {
  border-bottom: 0px solid;
}

&.MuiInput-underline:after {
  border-bottom: 0px solid;
}
`

interface PairVaultCardProps {
    vault: Vault
    showDepositedOnly: boolean
    showUnwrapped?: boolean
    stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
  }

export function PairVaultCard({ vault, showDepositedOnly, stakedBalance }: PairVaultCardProps) {

    const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected
  
    const { account, chainId } = useActiveWeb3React()
  
    const { width, } = useWindowSize()
  
    const smallScreen = width && width < MEDIA_WIDTHS.upToMedium ? true : false
  
    const allTokens = useAllTokens()
  
    const tokens = vault.name.split("-")
  
    const sToken0 = getTokenByName(tokens[0], chainId)
    const sToken1 = getTokenByName(tokens[1], chainId)
  
    const atoken0 = chainId && sToken0.name === "BNB" ? WETH[chainId] : allTokens[sToken0.address]
    const atoken1 = chainId && sToken1.name === "BNB" ? WETH[chainId] : allTokens[sToken1.address]
  
    const vaultData = useVaultData(vault)

    const farmData = useFarmDataFromPoolID(vault.alePoolId)   
    // const farmApy = farmData ? farmData.apy : 0 
    const farmApr = farmData ? farmData.apr : 0 
    const farmApd = farmData ? farmData.apd : 0 
    const tradingApy = vaultData ? vaultData.tradingApr : 0 


    const totalApy = vaultData ? vaultData.apy + farmApr + tradingApy : 0 
    const totalApd = vaultData ? vaultData.apd + farmApd + tradingApy/365 : 0 

    
    const apy = totalApy >= 100000 ? ">100,000" : totalApy.toFixed(2) 
    const apd = totalApd >= 100000 ? ">100,000" : totalApd.toFixed(2) 

    // const [, pair] = usePairFromLpAddress(vaultData?.pair)

    // const stakingInfo = useVaultStakingInfo(vault, pair)?.[0] // TODO: 
  
    const currency0 = atoken0 ? unwrappedToken(atoken0) : undefined
    const currency1 = atoken1 ? unwrappedToken(atoken1) : undefined
  
    const [showMore, setShowMore] = useState(false)
  
    const lpToken = new Token(56, vault.lpTokenAddress, 18, vaultData?.lpName)
    const userLPBalance = useTokenBalance(account ?? undefined, lpToken)
  
    const vaultPricePerShare = vaultData ? vaultData.vaultPricePerShare : 1

    // if staked balance balance provided, add to standard liquidity amount
    const userPoolBalance = useUserVaultStaked(vault)

    const userPoolBalanceAdjust = userPoolBalance ? new TokenAmount(lpToken,
      JSBI.divide(
        JSBI.multiply(userPoolBalance.raw, JSBI.BigInt(Math.trunc(vaultData.vaultPricePerShare * 100000))),
        JSBI.BigInt(100000))) : undefined
  
    const userPoolValue = vaultData && vaultData.pricePerShare && userPoolBalanceAdjust ? new TokenAmount(lpToken,
      JSBI.divide(
        JSBI.multiply(userPoolBalanceAdjust.raw, JSBI.BigInt(Math.trunc(vaultData.pricePerShare * 100000))),
        JSBI.BigInt(100000))) : undefined
  
    const backgroundColor = useColor(undefined)

    const vaultTokenSymbol = 'Barrel '+lpToken.symbol
  
    //const [approvalLP, approveLPCallback] = useApproveCallback(new TokenAmount(pair.liquidityToken,JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')), chainId ? BREWMASTER_ADDRESS[chainId] : '')
    const [approvalLP, approveLPCallback] = useApproveCallback(userLPBalance, vault.vaultAddress)
  
    const [showStakingModal, setShowStakingModal] = useState(false)
    const [showUnstakingModal, setShowUnstakingModal] = useState(false)
    // const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)
  
    const priceSubWithdrawFee = vaultData && !vaultData.paused  ? 0.999 : 1 
    // const lpName = vaultData ? vaultData.lpName : 'LP'

    const [outputToken, setOutputToken] = React.useState('LP');
    const [outputTokenText, setOutputTokenText] = React.useState('LP');    
    const [inputAmount, setInputAmount] = React.useState('');
    const [outputAmount, setOutputAmount] = React.useState('');
    const [multiplier, setMultiplier] = React.useState(0);
    const [innerLiquidityPercentage, setInnerLiquidityPercentage] = React.useState(0);

    const multiplier2 = multiplier !== 0 ? multiplier : vaultPricePerShare*priceSubWithdrawFee
    const outputAmount2 = inputAmount !== '' ? (parseFloat(inputAmount)*multiplier2).toFixed(12) : ''

    const atMaxWithdrawAmount = innerLiquidityPercentage === 100 


    const handleDepositClick = useCallback(() => {
      if (account) {
        setShowStakingModal(true)
      }
    }, [account])

    const handleOutputTokenChange = (event: any) => {
      const token = event.target.value
      setOutputToken(token);
      if (token === "LP" && lpToken.symbol)
        setOutputTokenText(lpToken.symbol)
      else
        setOutputTokenText(token)

      let _multiplier = 1
      if (token === "LP") _multiplier = vaultPricePerShare*priceSubWithdrawFee
      else if (token === "BNB") _multiplier = vaultData ? vaultData.tokensPricePerShare.BNB*priceSubWithdrawFee : 0
      else if (token === "BUSD") _multiplier = vaultData ? vaultData.tokensPricePerShare.BUSD*priceSubWithdrawFee : 0
      else if (token === "CRAFT") _multiplier = vaultData ? vaultData.tokensPricePerShare.CRAFT*priceSubWithdrawFee : 0
      
      setMultiplier(_multiplier)

      if (inputAmount !== '')
        setOutputAmount((parseFloat(inputAmount)*_multiplier).toFixed(12))      
    };    

    const handleUserInputAmount = (amount: string) => {
      setInputAmount(amount)
      if (amount !== '')
      {
        const floatAmount = parseFloat(amount) || 0;
        
        setOutputAmount((floatAmount*multiplier).toFixed(12))
        const percent = userPoolBalance ? Math.trunc(floatAmount/parseFloat(userPoolBalance.toFixed(12))*100) : 0
        setInnerLiquidityPercentage(percent)
      }
    };      

    const liquidityPercentChangeCallback = (percent: number) => {
      
      const intAmount = percent || 0;
      setInnerLiquidityPercentage(intAmount);
      const amount = userPoolBalance ? parseFloat(userPoolBalance.toFixed(12))*intAmount/100 : 0
      setInputAmount(amount.toFixed(12))
      setOutputAmount((amount*multiplier).toFixed(12))
    };          

  
    return (
      (userPoolBalance && JSBI.greaterThan(userPoolBalance.raw,JSBI.BigInt(0)) || (!showDepositedOnly) ? (
        <RoundShadowCard>
      <StyledPositionCard bgColor={backgroundColor} alignItems="center">
        <VaultStakingModal
          isOpen={showStakingModal}
          onDismiss={() => setShowStakingModal(false)}
          // stakingInfo={stakingInfo}
          vault={vault}
          lpToken={lpToken}
          // pair={pair}
          userLiquidityUnstaked={userLPBalance} />
        <VaultUnstakingModal
          isOpen={showUnstakingModal}
          onDismiss={() => setShowUnstakingModal(false)}
          vault={vault}
          // stakingInfo={stakingInfo}
          outputToken={outputToken}
          outputAmount={parseFloat(outputAmount)}
          atMaxWithdrawAmount={atMaxWithdrawAmount}
          withdrawAmount={new TokenAmount(lpToken,parseEther(inputAmount !== '' ? inputAmount : '0').toBigInt()) }
        />
        {/* <ClaimRewardModal
          isOpen={showClaimRewardModal}
          onDismiss={() => setShowClaimRewardModal(false)}
          stakingInfo={stakingInfo}
        /> */}
        <AutoColumn gap="12px">
          <ClickableHeaderRow justifyContent="space-between" onClick={() => setShowMore(!showMore)}>
            <AutoRow minWidth="295px">
                <div>
                  { vault.imageUrl ? ( 
                    <CustomCurrencyLogo url = {vault.imageUrl} />
                  ) : (
                    <VaultCurrencyLogo currency0={currency0} currency1={currency1} size={32} />
                  )}
                </div>
                <AutoColumn>
                  <PairText>
                    {/* {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}-${currency1.symbol}`} */}
                    {vault.name}
                  </PairText>
                  <SubText>Uses: {vault.farm.name}</SubText>
                </AutoColumn>
            </AutoRow>
            {smallScreen ? (
                <>
 
              </>
              
            ) : (
              <>
                <AutoRow>
                  <AutoColumn justify="center">
                    <SubText>&nbsp;</SubText>
                    <DataText>
                    {userLPBalance ? userLPBalance.toSignificant(4) : (<Dots></Dots>)}
                    </DataText>
                    <SubText>Wallet</SubText>
                  </AutoColumn>
                </AutoRow>            
                <AutoRow >
                  <AutoColumn justify="center">
                    <SubText>{userPoolValue ? "$"+userPoolValue.toFixed(2, { groupSeparator: ',' }) : ''}&nbsp;</SubText>
                    <DataText>
                    {userPoolBalanceAdjust ? userPoolBalanceAdjust.toSignificant(4) : (<Dots></Dots>)}
                    </DataText>
                    <SubText>Deposited</SubText>
                  </AutoColumn>
                </AutoRow>                  
                <AutoRow >
                  <AutoColumn justify="center">
                    <SubText> {farmData ? (<BonusBox>Boost</BonusBox>) : (<>&nbsp;</>) }</SubText>
                    <DataText>
                      {/* APR {farmAPR ? farmAPR.toFixed(1, { groupSeparator: ',' }) : (<Dots></Dots>)}% */}
                      {apy ? apy : (<Dots></Dots>)}%<br />
                    </DataText>
                    <SubText>
                      {(farmApr !== 0 || tradingApy !== 0) && vaultData ? (
                        <VaultAPYTooltips farmApr={farmApr} vaultApy={vaultData.apy} tradingApy={tradingApy} />
                      ) : (
                        <>APY</>
                      )}
                      </SubText>
                  </AutoColumn>
                </AutoRow>
                <AutoRow >
                  <AutoColumn justify="center">
                  <SubText>{farmData ? (<BonusBox>Boost</BonusBox>) : (<>&nbsp;</>) }</SubText>
                    <DataText>
                      {/* APR {farmAPR ? farmAPR.toFixed(1, { groupSeparator: ',' }) : (<Dots></Dots>)}% */}
                      {vaultData ? apd : (<Dots></Dots>)}%<br />
                    </DataText>
                    <SubText>Daily</SubText>
                  </AutoColumn>
                </AutoRow>              
                <AutoRow >
                  <AutoColumn justify="center">
                  <SubText>&nbsp;</SubText>
                    <TVLText>
                      ${vaultData ? vaultData.vaultTVL.toLocaleString('en-US', { maximumFractionDigits: 0 }) : (<Dots></Dots>)}
                    </TVLText>
                    <SubText>TVL</SubText>
                  </AutoColumn>
                </AutoRow>
              </>
            )}
            {false ? (
              <></>
            ) : (
              <div>
                <ButtonEmpty
                  padding="6px 8px"
                  borderRadius="12px"
                  width="fit-content"
                  color="#B5BBC9"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? (
                    <>
                      {/* <DetailText>Details</DetailText> */}
                      <ChevronUp size="20" />
                    </>
                  ) : (
                    <>
                      {/* <DetailText>Details</DetailText> */}
                      <ChevronDown size="20" />
                    </>
                  )}
                </ButtonEmpty>
              </div>
            )}
  
          </ClickableHeaderRow>
          {smallScreen && (
            <>
              <RowBetween height="50px" justifyContent="space-between" alignItems="center" marginBottom="30px">
                <AutoRow width="33%" justify="center">
                  <AutoColumn justify="center">
                    <SubText>&nbsp;</SubText>
                    <DataText>
                      {apy ? apy : (<Dots></Dots>)}%<br />
                    </DataText>
                    <SubText>
                    {farmApr !== 0 && vaultData ? (
                        <VaultAPYTooltips farmApr={farmApr} vaultApy={vaultData.apy} tradingApy={tradingApy} />
                      ) : (
                        <>APY</>
                      )}                      
                    </SubText>
                    {farmData ? (<BonusBox>Boost</BonusBox>) : (<>&nbsp;</>) }
                  </AutoColumn>
                </AutoRow>
                <AutoRow width="33%" justify="center">
                  <AutoColumn justify="center">
                    <SubText>&nbsp;</SubText>
                    <DataText>
                      {vaultData ? apd : (<Dots></Dots>)}%<br />
                    </DataText>
                    <SubText>Daily</SubText>
                    {farmData ? (<BonusBox>Boost</BonusBox>) : (<>&nbsp;</>) }
                  </AutoColumn>
                </AutoRow>
                <AutoRow width="33%" justify="center">
                  <AutoColumn justify="center">
                    <SubText>&nbsp;</SubText>
                    <TVLText>
                      ${vaultData ? vaultData.vaultTVL.toLocaleString('en-US', { maximumFractionDigits: 0 }) : (<Dots></Dots>)}
                    </TVLText>
                    <SubText>TVL</SubText>
                    <SubText>&nbsp;</SubText>
                  </AutoColumn>
                </AutoRow>
              </RowBetween>
              <RowBetween height="40px" justifyContent="space-between" alignItems="center" marginBottom="20px">
                <AutoRow justify="center">
                  <AutoColumn justify="center">
                    <SubText>&nbsp;</SubText>
                    <DataText>
                      {userLPBalance ? userLPBalance.toSignificant(4) : (<Dots></Dots>)}
                    </DataText>
                    <SubText>Wallet</SubText>
                  </AutoColumn>
                </AutoRow>
                <AutoRow justify="center">
                  <AutoColumn justify="center">
                    <SubText>{userPoolValue ? "$" + userPoolValue.toFixed(2, { groupSeparator: ',' }) : ''}&nbsp;</SubText>
                    <DataText>
                      {userPoolBalanceAdjust ? userPoolBalanceAdjust.toSignificant(4) : (<Dots></Dots>)}
                    </DataText>
                    <SubText>Deposited</SubText>
                  </AutoColumn>
                </AutoRow>
              </RowBetween>
            </>
          ) }
          {(showMore ) && (
            <>            
            <AutoColumn gap="8px">
              {/* <FixedHeightRow>
                {!currency0 || !currency1 ? <Dots>Loading</Dots> :
                  <ExternalLink
                    style={{ width: '100%', textAlign: 'left' }}
                    href={`${vault.farm.url}${currencyAddress(currency0, vault.farm)}/${currencyAddress(currency1, vault.farm)}`}
                  >
                    Add {currency0.symbol}-{currency1.symbol} LP <span style={{ fontSize: '11px' }}>↗</span>
                  </ExternalLink>}
              </FixedHeightRow> */}
              {/* <FixedHeightRow>
                <ExternalLink
                  style={{ width: '100%', textAlign: 'left' }}
                  href={`http://bscscan.com/address/${vaultData ? vaultData.pair : ''}`}
                >
                  View contract <span style={{ fontSize: '11px' }}>↗</span>
                </ExternalLink>
              </FixedHeightRow> */}

              </AutoColumn>              
              <DepositWithdrawWrapper>
                <DepositPanel>

                  <AutoColumn justify="center">

                    {vault.addLpUrl ? (
                      <ExternalLink
                        style={{ width: '100%', textAlign: 'left' }}
                        href={`${vault.farm.url}${vault.addLpUrl}`}
                      >
                        Add {vault.name} LP <span style={{ fontSize: '11px' }}>↗</span>
                      </ExternalLink>
                    ) : (
                     !currency0 || !currency1 ? <Dots>Loading</Dots> : 
                      <ExternalLink
                        style={{ width: '100%', textAlign: 'left' }}
                        href={`${vault.farm.url}${currencyAddress(currency0, vault.farm)}/${currencyAddress(currency1, vault.farm)}`}
                      >
                        Add {vault.name} LP <span style={{ fontSize: '11px' }}>↗</span>

                      </ExternalLink>
                    )}

                  </AutoColumn>                  
                  <AutoRow marginTop="10px" justifyItems="center" justify="center">

                    {approvalLP && approvalLP === ApprovalState.NOT_APPROVED || approvalLP === ApprovalState.PENDING ? (
                      <ButtonPrimary
                        padding="8px"
                        borderRadius="8px"
                        onClick={approveLPCallback}
                        disabled={approvalLP === ApprovalState.PENDING}

                        width="32%"
                      >
                        {approvalLP === ApprovalState.PENDING ? (
                          <Dots>Approving...</Dots>
                        ) : (
                          'Enable'
                        )}
                      </ButtonPrimary>
                    )
                      : vaultData.paused ? (
                      <ButtonPrimary
                        padding="8px"
                        borderRadius="8px"
                        onClick={handleDepositClick}
                        disabled={true}
                        width="48%"
                      >
                        Paused
                      </ButtonPrimary>                    
                      ) : approvalLP && approvalLP === ApprovalState.APPROVED ? (
                        <ButtonPrimary
                          padding="8px"
                          borderRadius="8px"
                          onClick={handleDepositClick}
                          width="48%"
                        >
                          Stake
                        </ButtonPrimary>
                      ) : !account ? (
                        <ButtonPrimary
                          padding="8px"
                          borderRadius="8px"
                          onClick={toggleWalletModal}
                          width="48%"
                        >
                          Connect Wallet
                        </ButtonPrimary>
                      ) : (
                        <ButtonPrimary
                          padding="8px"
                          borderRadius="8px"
                          disabled={true}
                          onClick={handleDepositClick}
                          width="48%"
                        >
                          Enable
                        </ButtonPrimary>
                      )}

                    {/* <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width="48%"
                  onClick={() => setShowUnstakingModal(true)}
                  disabled={userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, BIG_INT_ZERO) ? false : true}
                >
                  Withdraw
                </ButtonPrimary> */}
                  </AutoRow>
                  <AutoColumn justify="center" gap="8px">
                    {/* <SubText>Withdraw {inputAmount} {vaultTokenSymbol} to {outputAmount} {outputTokenText}</SubText> */}
                    <SubText>Deposit fee: 0%, Withdrawal fee: 0.1%</SubText>
                    <SmallSubText>You will receive {vaultTokenSymbol} token as a receipt for your deposited {lpToken.symbol} assets. This token is needed to withdraw your {lpToken.symbol}.</SmallSubText>
                  </AutoColumn>                  
                </DepositPanel>
                <WithdrawPanel>
                {/* <AutoColumn> */}
                  <RowBetween>
                    <AutoColumn justify="center">
                      <Text fontSize={14} fontWeight={500}>
                        Deposited: {userPoolBalance ? userPoolBalance.toSignificant(10) + ' '+vaultTokenSymbol : (<Dots></Dots>)}
                      </Text>
                    </AutoColumn>
                  </RowBetween>
                  <AutoRow>
                    <InputPanel>
                      <Container hideInput={false}>
                        <InputRow style={{ padding: '8px', borderRadius: '8px' }} selected={true} >
                          <NumericalInput
                            className="token-amount-input"
                            value={inputAmount}
                            onUserInput={handleUserInputAmount}
                            disabled={false}
                          />
                          <LPText>
                            LP
                          </LPText>
                        </InputRow>
                      </Container>
                    </InputPanel>
                  </AutoRow>
                  <AutoColumn justify="center">
                    <Slider value={innerLiquidityPercentage} onChange={liquidityPercentChangeCallback} />
                    <RowBetween marginTop="-8px" width="95%">
                      <MaxButton onClick={() => liquidityPercentChangeCallback(0)} width="20%">
                        0%
                      </MaxButton>                      
                      <MaxButton onClick={() => liquidityPercentChangeCallback(25)} width="20%">
                        25%
                      </MaxButton>
                      <MaxButton onClick={() => liquidityPercentChangeCallback(50)} width="20%">
                        50%
                      </MaxButton>
                      <MaxButton onClick={() => liquidityPercentChangeCallback(75)} width="20%">
                        75%
                      </MaxButton>
                      <MaxButton onClick={() => liquidityPercentChangeCallback(100)} width="20%">
                        Max
                      </MaxButton>
                    </RowBetween>
                  </AutoColumn>
                  <AutoColumn justify="center" gap="md">
                    <ArrowDown size="16" />
                  </AutoColumn>
                  <AutoRow marginTop="8px">
                    <InputPanel >
                      <Container hideInput={false}>
                        <InputRow style={{ padding: '1', borderRadius: '8px' }} selected={true} >
                          <NumericalInput
                            className="token-amount-output"
                            value={outputAmount2 ? parseFloat(outputAmount2) : ''}
                            onUserInput={val => { }}
                            disabled={true}
                          />
                          <div>
                            {/* <FormControl variant="outlined" > */}
                              <StyledSelect
                                labelId="outputTokenSelect"
                                id="outputTokenSelect"
                                value={outputToken}
                                onChange={handleOutputTokenChange}
                              >
                                <MenuItem color="inherit" value={'LP'}>LP</MenuItem>
                                <MenuItem color="inherit" value={'BNB'}>BNB</MenuItem>
                                <MenuItem color="inherit" value={'BUSD'}>BUSD</MenuItem>
                                <MenuItem color="inherit" value={'CRAFT'}>CRAFT</MenuItem>
                              </StyledSelect>
                            {/* </FormControl> */}
                          </div>
                        </InputRow>

                      </Container>

                    </InputPanel>
                  </AutoRow>
                  <RowBetween marginTop="16px">
                    <ButtonPrimary
                      padding="8px"
                      borderRadius="8px"
                      width="48%"
                      onClick={() => setShowUnstakingModal(true)}
                      disabled={userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, BIG_INT_ZERO) ? false : true}
                    >
                      Withdraw
                    </ButtonPrimary>
                    <ButtonPrimary
                      padding="8px"
                      borderRadius="8px"
                      width="48%"
                      onClick={() => { liquidityPercentChangeCallback(100); setShowUnstakingModal(true) }}
                      disabled={userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, BIG_INT_ZERO) ? false : true}
                    >
                      Withdraw All
                    </ButtonPrimary>


                  </RowBetween>
                  <AutoColumn>
                    <AutoRow marginTop="8px">
                    <SubText>Withdraw {inputAmount} {vaultTokenSymbol} to {outputAmount2} {outputTokenText}</SubText>
                    </AutoRow>
                  </AutoColumn>
                {/* </AutoColumn> */}
                </WithdrawPanel>
              </DepositWithdrawWrapper>
            </>
          )}
        </AutoColumn>
      </StyledPositionCard> 
      </RoundShadowCard>
      ) : (<></>)
      ))
  }
  