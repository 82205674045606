import { darken } from 'polished';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';



export const NavBar = styled.div`
  padding: 20px 20x;  
  display: flex;
  flex-direction: column;
  width: 240px;
  z-index: 2;
  border-right: 2px solid rgba(133, 133, 133, 0.1);
  background: ${({ theme }) => theme.headerBG};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};  
`

const activeClassName = 'ACTIVE'


export const StyledNavLink = styled(NavLink).attrs({
    activeClassName
  })`
    ${({ theme }) => theme.flexRowNoWrap}
    // margin-left: 20px;  
    align-items: left;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.textNavMenu};
    font-size: 1rem;
    width: 100%;
    font-weight: 500;
    padding: 8px 12px;
    gap: 8px;
  
    ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 1.1rem;
    `};      
    &.${activeClassName} {
      font-weight: 600;
      color: ${({ theme }) => theme.text1};
      background-color: ${({ theme }) => theme.bg2};
      svg {
        fill: ${({ theme }) => theme.text1};
      }      
    }
  
    :hover,
    :focus {
      color: ${({ theme }) => darken(0.1, theme.text1)};
      svg {
        fill: ${({ theme }) => darken(0.1, theme.text1)};
      }           
    }
    svg {
      fill: ${({ theme }) => theme.textNavMenu};
    }
    
  `

export const StyledMenu = styled.nav<{ open: boolean }>`
  display: flex;
  flex-direction: column;
//   justify-content: center;
  background: ${({ theme }) => theme.bg1};
  height: 100vh;
  text-align: left;
//   padding: 10px;
  position: absolute;
  width: 240px;
  top: 55;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};

  z-index: 10;
  
  @media (max-width: ${({ theme }) => theme.black}) {
    width: 100%;
  }

  
  }
`;