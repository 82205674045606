import {  Currency, ETHER, Token } from '@uniswap/sdk'
import { ChainId } from '../constants'
import { useActiveWeb3React } from 'hooks'
import { VaultFarm } from './vault'

export function currencyId(currency: Currency,chainId? : ChainId): string {
  // if (currency === ETHER) return 'ETH'
  if (currency === ETHER && chainId && chainId === ChainId.REI) return 'REI'
  if (currency === ETHER && chainId && chainId === ChainId.MAINNET) return 'BNB'  
  if (currency === ETHER) return 'BNB'  
  if (currency instanceof Token) return currency.address
  throw new Error('invalid currency')
}

export function currencyAddress(currency: Currency,vault: VaultFarm): string {
  // if (currency === ETHER) return 'ETH'
  if (vault.name === "Warden" && currency === ETHER) return 'ETH'
  if (vault.name === "Definix" && currency === ETHER) return 'ETH'
  if (vault.name === "ApeSwap" && currency === ETHER) return 'ETH'
  if (currency === ETHER) return 'BNB'
  if (currency instanceof Token) return currency.address
  throw new Error('invalid currency')
}
