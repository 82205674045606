import AprBox from 'components/AprBox';
import HomeHeader from 'components/HomeHeader';
import NewFeatureBox from 'components/NewFeatureBox';
import NewPoolAnnouncement from 'components/NewPoolAnnouncement';
import PartnerBox from 'components/PartnerBox';
import WalletBox from 'components/WalletBox';
import React from 'react';
import { useIsDarkMode } from 'state/user/hooks';
import styled from 'styled-components';
import FooterbgDark from '../../assets/images/footer-bg-dark.png';
import Footerbg from '../../assets/images/footer-bg.png';

// const PageWrapper = styled(AutoColumn)`
//   max-width: 640px;
//   width: 100%;
// `

export const LogoBox = styled.div`
  width: 100%;
  height: 185px;
  display: flex;
  align-items: center  
  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 100px;
  `};  
`

export const CardBGImage = styled.span<{ url : string }>`
  background: url(${({ url }) => url});
  width: 1000px;
  height: 600px;
  position: absolute;
  border-radius: 12px;
  user-select: none;
`
// const PageWrapper = styled.div`
//   display: flex;
//   flex-direction: row;  
// `  

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;  
  align-content: stretch;
  // border: 1px solid ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToMedium`  
    overflow-x: hidden;
  `};      
`
  // max-width: 640px;

const HomeHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;    
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  gap: 38px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0px;
    flex-direction: column; 
  `};  


`

//  border: 1px solid ${({ theme }) => theme.text1};

const HomeBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;    
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 16px;
  `};  
  

`
//   border: 1px solid ${({ theme }) => theme.text1};
const BodyFirstRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;   
  height:250px;

  padding-left: 40px;
  padding-right: 40px;
  gap: 16px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    min-height: 250px;    
    height: 100%;   
    padding-left: 0px;
    padding-right: 0px;
  `};      

`
//   border: 1px solid ${({ theme }) => theme.text1};  

const FooterCartoon = styled.div<{ background: string }>`
  position: fixed; 
  bottom: 0%;
  right: 0%;
  z-index: -2;
  width: 100%;

  background-image: url(${({ background }) => background}); 
  background-repeat: no-repeat;
  
  background-position:bottom right;
  background-size: auto;  
  
  min-height:200px;
  
`;

export default function Home() {
  const darkMode = useIsDarkMode()
  return (
    <PageWrapper>
      <HomeHeaderWrapper>
        <HomeHeader />
        <WalletBox />
      </HomeHeaderWrapper>

      <HomeBody>
        <BodyFirstRow>
          <NewFeatureBox /><AprBox /><PartnerBox />
        </BodyFirstRow>
        <FooterCartoon background={darkMode ? FooterbgDark : Footerbg} />
      </HomeBody>
    </PageWrapper>
  )
}
