import { JSBI, TokenAmount } from '@uniswap/sdk'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { useCallback, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Text } from 'rebass'
import { usePoolsData } from 'utils/aleSwapAPI'
import { APRText, DataText, DetailText, FixedHeightRow, PairText, SingleClickableFixedHeightRow, StyledPositionCard, SubText, TVLText } from '.'
import { ALE, BIG_INT_ZERO, ChainId, STAKING_REWARDS_ADDRESS, WETH } from '../../constants'
import { useRewardForDurationBNBPool, useTotalStakedBNBPool, useUserEarnedBNBPool, useUserStakedBNBPool } from '../../data/TotalStaked'
import { useActiveWeb3React } from '../../hooks'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useColor } from '../../hooks/useColor'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, MEDIA_WIDTHS } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import useBUSDPrice from '../../utils/useUSDCPrice'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonEmpty, ButtonPrimary } from '../Button'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import { EarnBNBClaimRewardModal } from '../earn/ClaimRewardModal'
import { EarnBNBStakingModal } from '../earn/StakingModal'
import { EarnBNBUnstakingModal } from '../earn/UnstakingModal'
import { AutoRow, RowBetween } from '../Row'
import { Dots } from '../swap/styleds'




const dayPerYear = JSBI.BigInt(365)
const ONE_HUNDRED = JSBI.BigInt(100)

interface StakingRewardsCardProps {
    // pair: Pair
    showUnwrapped?: boolean
    border?: string
    stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
  }
  
  export function StakingRewardsCard({ border, stakedBalance }: StakingRewardsCardProps) {
    const { account, chainId } = useActiveWeb3React()
  
    const { width,  } = useWindowSize()

    const poolDatas = usePoolsData()

    const poolData = poolDatas ? poolDatas[0] : undefined

    const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected
  
    const smallScreen = width && width < MEDIA_WIDTHS.upToSmall ? true : false
  
    const token = chainId ? ALE[chainId] : ALE[ChainId.MAINNET]
  
    const alePrice = useBUSDPrice(chainId ? ALE[chainId] : undefined)

  
    const currency0 = unwrappedToken(token)

    const rewardToken = unwrappedToken(WETH[56])
  
    const [showMore, setShowMore] = useState(false)
  
    const totalStakedTokens = useTotalStakedBNBPool(token)
    const rewardForDuration = useRewardForDurationBNBPool() 
  
    const bnbPrice = useBUSDPrice(WETH[56])
  
    const rewardForDurationInUSD = rewardForDuration && bnbPrice ? bnbPrice.quote(rewardForDuration) : new TokenAmount(WETH[56],JSBI.BigInt(0))

    const totalValueLocked = totalStakedTokens && alePrice?.quote(totalStakedTokens)
  
    const farmAPR = totalValueLocked && JSBI.greaterThan(totalValueLocked.raw, JSBI.BigInt(0))  ?
        rewardForDurationInUSD.divide(totalValueLocked).divide(JSBI.BigInt(7)).divide(totalValueLocked).multiply(dayPerYear).multiply(ONE_HUNDRED) : 0
  
  
    const userLPBalance = useTokenBalance(account ?? undefined, token)
  
    // if staked balance balance provided, add to standard liquidity amount
    const userPoolBalance = useUserStakedBNBPool(token)
  
    const userPoolEarned = useUserEarnedBNBPool(token)
  
    const backgroundColor = useColor(token)
  
    const [approvalLP, approveLPCallback] = useApproveCallback(new TokenAmount(token, userLPBalance?.raw || BIG_INT_ZERO), STAKING_REWARDS_ADDRESS)
  
    const [showStakingModal, setShowStakingModal] = useState(false)
    const [showUnstakingModal, setShowUnstakingModal] = useState(false)
    const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)
  
    const handleDepositClick = useCallback(() => {
      if (account) {
        setShowStakingModal(true)
      }
    }, [account])
  
  
    return (
      <StyledPositionCard border={border} bgColor={backgroundColor}>
        <EarnBNBStakingModal
          isOpen={showStakingModal}
          onDismiss={() => setShowStakingModal(false)}
          userLiquidityUnstaked={userLPBalance}
        />
        <EarnBNBUnstakingModal
          isOpen={showUnstakingModal}
          onDismiss={() => setShowUnstakingModal(false)}
          earnedAmount={userPoolEarned}
          stakedAmount={userPoolBalance}
        />
        <EarnBNBClaimRewardModal
          isOpen={showClaimRewardModal}
          onDismiss={() => setShowClaimRewardModal(false)}
          earnedAmount={userPoolEarned}
        />
        <AutoColumn gap="12px">
          <SingleClickableFixedHeightRow onClick={() => setShowMore(!showMore)}>
            <AutoRow width="150px">
              <AutoColumn gap="4px">
              <AutoRow gap="2px" width="130px">
                <CurrencyLogo currency={currency0} size={'24px'}/>
                <PairText>
                  {!currency0 ? <Dots>Loading</Dots> : `${currency0.symbol}`}
                </PairText>
              </AutoRow>

              {smallScreen ? (
              <></>
              ) : (              
                <AutoRow gap="2px" width="130px">
                  Earn: <PairText>BNB</PairText><CurrencyLogo currency={rewardToken} size="24px" />
                </AutoRow>                
              )}
              </AutoColumn>
            </AutoRow>
             

            {smallScreen ? (
              <></>
            ) : (
              <>

                <AutoRow width="150px" justify="center">
                  <AutoColumn justify="center">
                    <DataText>
                      {poolData ? poolData.apr.toLocaleString('en-US', { maximumFractionDigits: 1 }) : (<Dots></Dots>)}%
                    </DataText>
                    <SubText>
                      APR
                    </SubText>
                  </AutoColumn>
                </AutoRow>
                <AutoRow width="120px" justify="center">
                  <AutoColumn justify="center">
                    <DataText>
                      ${poolData ? poolData.tvl.toLocaleString('en-US', { maximumFractionDigits: 0 }) : (<Dots></Dots>)}
                    </DataText>
                    <SubText>
                      TVL
                    </SubText>
                  </AutoColumn>
                </AutoRow>                          
              </>
            )}
            {smallScreen ? (
              <></>
            ) : (
  
              <div>
                <ButtonEmpty
                  padding="6px 8px"
                  borderRadius="12px"
                  width="fit-content"
                  color="#B5BBC9"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? (
                    <>
                      <ChevronUp size="20" style={{ marginLeft: '10px' }} />
                    </>
                  ) : (
                    <>
                      <ChevronDown size="20" style={{ marginLeft: '10px' }} />
                    </>
                  )}
                </ButtonEmpty>
              </div>
            )}
          </SingleClickableFixedHeightRow>
  
          {(showMore || smallScreen) && (
            <AutoColumn gap="8px">
              {smallScreen ? (
                <>
                  <FixedHeightRow>
                    <Text fontSize={16} fontWeight={500}>
                      Earn:
                    </Text>
                    <AutoRow gap="2px" width="100px" justify="flex-end" >
                      <PairText>BNB</PairText><CurrencyLogo currency={rewardToken} size="24px" />
                    </AutoRow>
                  </FixedHeightRow>                
                  <FixedHeightRow>
                    <Text fontSize={16} fontWeight={500}>
                      APR:
                    </Text>
                    <DataText>
                      {poolData ? poolData.apr.toLocaleString('en-US', { maximumFractionDigits: 1 }) : (<Dots></Dots>)}%
                    </DataText>
                  </FixedHeightRow>
                  <FixedHeightRow>
                    <Text fontSize={16} fontWeight={500}>
                      TVL:
                    </Text>
                    <DataText>
                      ${poolData ? poolData.tvl.toLocaleString('en-US', { maximumFractionDigits: 0 }) : (<Dots></Dots>)}
                    </DataText>
                  </FixedHeightRow>
                </>
              ) : (<></>)
              }
              <FixedHeightRow>
                <ExternalLink
                  style={{ width: '100%', textAlign: 'left' }}
                  href={`#/swap?outputCurrency=${currencyId(currency0)}`}
                >
                  Buy ALE <span style={{ fontSize: '11px' }}>↗</span>
                </ExternalLink>
              </FixedHeightRow>
              <FixedHeightRow>
                <ExternalLink
                  style={{ width: '100%', textAlign: 'left' }}
                  href={`http://bscscan.com/address/${STAKING_REWARDS_ADDRESS}`}
                >
                  View contract <span style={{ fontSize: '11px' }}>↗</span>
                </ExternalLink>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Your available ALE:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {userLPBalance ? userLPBalance.toSignificant(4) : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Your deposited ALE:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Earned:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {userPoolEarned ? userPoolEarned.toSignificant(6) : '-'} BNB
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Deposit fee:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  0%
                </Text>
              </FixedHeightRow>            
              <RowBetween marginTop="10px">
                {approvalLP && approvalLP === ApprovalState.NOT_APPROVED || approvalLP === ApprovalState.PENDING ? (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    onClick={approveLPCallback}
                    disabled={approvalLP === ApprovalState.PENDING}
  
                    width="32%"
                  >
                    {approvalLP === ApprovalState.PENDING ? (
                      <Dots>Approving...</Dots>
                    ) : (
                      'Enable'
                    )}
                  </ButtonPrimary>
                )
                  : approvalLP && approvalLP === ApprovalState.APPROVED ? (
                    <ButtonPrimary
                      padding="8px"
                      borderRadius="8px"
                      onClick={handleDepositClick}
                      width="32%"
                    >
                      Stake
                    </ButtonPrimary>
                  ) : !account ? (
                    <ButtonPrimary
                        padding="8px"
                        borderRadius="8px"
                        onClick={toggleWalletModal}
                        width="32%"
                    >
                        Connect Wallet
                    </ButtonPrimary>                    
                  ) : (
                    <ButtonPrimary
                      padding="8px"
                      borderRadius="8px"
                      disabled={true}
                      onClick={handleDepositClick}
                      width="32%"
                    >
                      Enable
                    </ButtonPrimary>
                  )}
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width="32%"
                  onClick={() => setShowUnstakingModal(true)}
                  disabled={userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, BIG_INT_ZERO) ? false : true}
                >
                  Withdraw
                </ButtonPrimary>
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width="32%"
                  onClick={() => setShowClaimRewardModal(true)}
                  disabled={userPoolEarned && JSBI.greaterThan(userPoolEarned.raw, BIG_INT_ZERO) ? false : true}
                >
                  Harvest
                </ButtonPrimary>
              </RowBetween>
            </AutoColumn>
          )}
        </AutoColumn>
      </StyledPositionCard>
    )
  }
  
  