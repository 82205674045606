import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ButtonPrimary, ButtonSecondary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { BottomDataCard, DataCard, TopDataCard } from '../../components/earn/styled'
import InfoBox from '../../components/InfoBox'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { RowBetween, RowFixed } from '../../components/Row'
import { useActiveWeb3React } from '../../hooks'



const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopRoundShadowCard = styled(TopDataCard)`
  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;
  background: white;
  overflow: hidden;
`

const BottomRoundShadowCard = styled(BottomDataCard)`
  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;  
  background: white;
  overflow: hidden;
`

const RoundShadowCard = styled(DataCard)`

  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  // 0px 24px 32px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 2px 2px 
#00000040;
  background: white;
  overflow: hidden;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ExpandBox = styled.div`
  width: 100%;
  height: 380px;
  display: flex;
  align-items: center  
  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 100px;
  `};  
`


export default function Info() {
  const theme = useContext(ThemeContext)
  const { account,chainId } = useActiveWeb3React()


  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
          
          <InfoBox />

      </PageWrapper>
    </>
  )
}
