import React from 'react';
import styled from "styled-components";
import { useMaxVaultAPR, useVaultTVL } from 'utils/aleSwapAPI';
import VaultImg from '../../assets/images/VaultImg.png';
import { Dots } from '../swap/styleds';

export default function VaultHeader()  {
  const maxAPY = useMaxVaultAPR()
  const tvl = useVaultTVL()

  return (
      
    <HeaderBox>
      <PromoBox>Earn up to <OrangeText>{maxAPY ? maxAPY.toFixed(0) : '88'}%</OrangeText> APY</PromoBox>
      <VaultBarrel src={VaultImg} />

      <TvlBox>
        <TextRow>
          <WhiteText>Total Value Locked</WhiteText>
          <OrangeText>${!tvl ? <Dots></Dots> : parseInt(tvl).toLocaleString('en-US')}</OrangeText>
        </TextRow>
      </TvlBox>

    </HeaderBox>
 )
}


const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  font-weight:bold;
  width:100%;
  
  min-width:400px;
  height:100px;
  

align-items: center;
justify-content: flex-end;
gap: 20px;

${({ theme }) => theme.mediaWidth.upToMedium`
flex-direction: column;

justify-content: center;
min-width:100%;
height:auto;
`};

  
`;

const PromoBox = styled.div`
  display: flex;
  flex-direction: row;
  color:#FFFFFF;
  min-width:220px;
  font-weight:bold;
  font-size:36px;
  height:100%;
  gap:10px;
  


align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size:24px;
  `};

  
`;

const VaultBarrel = styled.img`
height:100%;
${({ theme }) => theme.mediaWidth.upToMedium`
height:200px;
`};

${({ theme }) => theme.mediaWidth.upToSmall`
height:150px;
`};



`;

const TvlBox = styled.div`
  display: flex;
  flex-direction: column;
  
  background-color:${({ theme }) => theme.itemBG};
  min-width:220px;
  font-weight:bold;
  
  height:100%;
  padding:15px 22px 15px 22px;
border-radius: 15px;

align-items: center;
  justify-content: center;


  
`;

const WhiteText = styled.div`
  color:${({ theme }) => theme.tokenText};
  font-size:24px;

  
`;

const OrangeText = styled.span`
  color:#e7ba41;
  font-size:36px;
  

  
`;

const TextRow = styled.div`
  
  
display: flex;
flex-direction: column;
  align-items: center;
  justify-content: center;
  
`;




  ;




  

  ;