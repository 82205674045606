import React, { useState, useCallback } from 'react'
// import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import {  ButtonError } from '../Button'
// import ProgressCircles from '../ProgressSteps'
import CurrencyInputPanel, { LPTokenInputPanel } from '../CurrencyInputPanel'
import { TokenAmount, Pair, Token } from '@uniswap/sdk'
import { useActiveWeb3React } from '../../hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useAleStakingRewardsContract, useBrewmasterContract, useVaultContract } from '../../hooks/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
// import { splitSignature } from 'ethers/lib/utils'
import { AleStakingInfo, useDerivedStakeInfo } from '../../state/stake/hooks'
import { wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { BREWMASTER_ADDRESS,ALE, STAKING_REWARDS_ADDRESS } from '../../constants'
// import { ChainId } from '../../constants'
import { ChainId } from '../../constants'
import { Vault } from 'utils/vault'


// const HypotheticalRewardRate = styled.div<{ dim: boolean }>`
//   display: flex;
//   justify-content: space-between;
//   padding-right: 20px;
//   padding-left: 20px;

//   opacity: ${({ dim }) => (dim ? 0.5 : 1)};
// `

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: AleStakingInfo
  userLiquidityUnstaked: TokenAmount | undefined
}

export function StakingModal({ isOpen, onDismiss, stakingInfo, userLiquidityUnstaked }: StakingModalProps) {
  const { chainId } = useActiveWeb3React()

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo?.stakedAmount.token, userLiquidityUnstaked)
  const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)

  // let hypotheticalRewardRate: TokenAmount = new TokenAmount(stakingInfo.rewardRate.token, '0')
  if (parsedAmountWrapped?.greaterThan('0')) {
    // hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
    //   stakingInfo.stakedAmount.add(parsedAmountWrapped),
    //   stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
    //   stakingInfo.totalRewardRate
    // )
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  const dummyPair = stakingInfo ? new Pair(new TokenAmount(stakingInfo.tokens[0], '0'), new TokenAmount(stakingInfo.tokens[1], '0')) : undefined
  // const pairContract = usePairContract(dummyPair?.liquidityToken.address)

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  // const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)
  const [approval] = useApproveCallback(parsedAmount, chainId ? BREWMASTER_ADDRESS[chainId] : '')

  // const isArgentWallet = useIsArgentWallet()
  // const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)
  const brewmasterContract = useBrewmasterContract()
  
  async function onStake() {
    setAttempting(true)
    if (brewmasterContract && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        brewmasterContract.deposit(stakingInfo?.poolId,`0x${parsedAmount.raw.toString(16)}`, { gasLimit: 350000 }) 
        .then((response: TransactionResponse) => {
                addTransaction(response, {
                  summary: `Stake liquidity`
                })
                setHash(response.hash)
              })
              .catch((error: any) => {
                setAttempting(false)
                console.log(error)
              })
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }

      // } else if (signatureData) {
      //   stakingContract
      //     .stakeWithPermit(
      //       `0x${parsedAmount.raw.toString(16)}`,
      //       signatureData.deadline,
      //       signatureData.v,
      //       signatureData.r,
      //       signatureData.s,
      //       { gasLimit: 350000 }
      //     )
      //     .then((response: TransactionResponse) => {
      //       addTransaction(response, {
      //         summary: `Stake liquidity`
      //       })
      //       setHash(response.hash)
      //     })
      //     .catch((error: any) => {
      //       setAttempting(false)
      //       console.log(error)
      //     })
      // } else {
      //   setAttempting(false)
      //   throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      // }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  // async function onAttemptToApprove() {
  //   if (!pairContract || !library || !deadline) throw new Error('missing dependencies')
  //   const liquidityAmount = parsedAmount
  //   if (!liquidityAmount) throw new Error('missing liquidity amount')

  //   if (isArgentWallet) {
  //     return approveCallback()
  //   }

  //   // try to gather a signature for permission
  //   const nonce = await pairContract.nonces(account)

  //   const EIP712Domain = [
  //     { name: 'name', type: 'string' },
  //     { name: 'version', type: 'string' },
  //     { name: 'chainId', type: 'uint256' },
  //     { name: 'verifyingContract', type: 'address' }
  //   ]
  //   const domain = {
  //     name: 'AleSwap',
  //     version: '1',
  //     chainId: chainId,
  //     verifyingContract: pairContract.address
  //   }
  //   const Permit = [
  //     { name: 'owner', type: 'address' },
  //     { name: 'spender', type: 'address' },
  //     { name: 'value', type: 'uint256' },
  //     { name: 'nonce', type: 'uint256' },
  //     { name: 'deadline', type: 'uint256' }
  //   ]
  //   const message = {
  //     owner: account,
  //     spender: stakingInfo.stakingRewardAddress,
  //     value: liquidityAmount.raw.toString(),
  //     nonce: nonce.toHexString(),
  //     deadline: deadline.toNumber()
  //   }
  //   const data = JSON.stringify({
  //     types: {
  //       EIP712Domain,
  //       Permit
  //     },
  //     domain,
  //     primaryType: 'Permit',
  //     message
  //   })

  //   library
  //     .send('eth_signTypedData_v4', [account, data])
  //     .then(splitSignature)
  //     .then(signature => {
  //       setSignatureData({
  //         v: signature.v,
  //         r: signature.r,
  //         s: signature.s,
  //         deadline: deadline.toNumber()
  //       })
  //     })
  //     .catch(error => {
  //       // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
  //       if (error?.code !== 4001) {
  //         approveCallback()
  //       }
  //     })
  // }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Stake</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={stakingInfo?.stakedAmount.token}
            pair={dummyPair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to stake: '}
            id="stake-liquidity-token"
          />

          {/* <HypotheticalRewardRate dim={!hypotheticalRewardRate.greaterThan('0')}>
            <div>
              <TYPE.black fontWeight={600}>Weekly Rewards</TYPE.black>
            </div>

            <TYPE.black>
              {hypotheticalRewardRate.multiply((60 * 60 * 24 * 7).toString()).toSignificant(4, { groupSeparator: ',' })}{' '}
              UNI / week
            </TYPE.black>
          </HypotheticalRewardRate> */}

          <RowBetween>
            {/* <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
              disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
            >
              Approve
            </ButtonConfirmed> */}
            <ButtonError
              disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? 'Stake'}
            </ButtonError>
          </RowBetween>
          {/* <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} /> */}
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Staking</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} LP</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} LP</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}

interface SingleStakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  // stakingInfo: AleStakingInfo
  userLiquidityUnstaked: TokenAmount | undefined
}

export function SingleStakingModal({ isOpen, onDismiss, userLiquidityUnstaked }: SingleStakingModalProps) {
  const { chainId }  = useActiveWeb3React()

  // const token = chainId? ALE[ChainId.MAINNET] : ALE[ChainId.MAINNET]
  const token = ALE[ChainId.MAINNET]
  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  // const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo.tokens[0], userLiquidityUnstaked)
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, token , userLiquidityUnstaked)
  // const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)


  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  // const dummyPair = new Pair(new TokenAmount(stakingInfo.tokens[0], '0'), new TokenAmount(stakingInfo.tokens[1], '0'))
  // const tokenContract = useTokenContract(token.address)

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  // const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)
  const [approval ] = useApproveCallback(parsedAmount, chainId ? BREWMASTER_ADDRESS[chainId] : '')

  // const isArgentWallet = useIsArgentWallet()
  // const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)
  const brewmasterContract = useBrewmasterContract()
  
  async function onStake() {
    setAttempting(true)
    if (brewmasterContract && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        brewmasterContract.enterStaking(`0x${parsedAmount.raw.toString(16)}`, { gasLimit: 350000 }) 
        .then((response: TransactionResponse) => {
                addTransaction(response, {
                  summary: `Stake ALE`
                })
                setHash(response.hash)
              })
              .catch((error: any) => {
                setAttempting(false)
                console.log(error)
              })
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }

      // } else if (signatureData) {
      //   stakingContract
      //     .stakeWithPermit(
      //       `0x${parsedAmount.raw.toString(16)}`,
      //       signatureData.deadline,
      //       signatureData.v,
      //       signatureData.r,
      //       signatureData.s,
      //       { gasLimit: 350000 }
      //     )
      //     .then((response: TransactionResponse) => {
      //       addTransaction(response, {
      //         summary: `Stake liquidity`
      //       })
      //       setHash(response.hash)
      //     })
      //     .catch((error: any) => {
      //       setAttempting(false)
      //       console.log(error)
      //     })
      // } else {
      //   setAttempting(false)
      //   throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      // }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])


  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Stake</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>

          
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={token} // TODO: รูปเหรียญ ALE ไม่ขึ้น
            // pair={dummyPair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to stake: '}
            id="stake-liquidity-token"
          />

          <RowBetween>
            <ButtonError
              disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? 'Stake'}
            </ButtonError>
          </RowBetween>
          {/* <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} /> */}
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Staking</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} ALE</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} ALE</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}


interface EarnBNBStakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  // stakingInfo: AleStakingInfo
  userLiquidityUnstaked: TokenAmount | undefined
}

export function EarnBNBStakingModal({ isOpen, onDismiss, userLiquidityUnstaked }: EarnBNBStakingModalProps) {
  const { chainId }  = useActiveWeb3React()

  // const token = chainId? ALE[ChainId.MAINNET] : ALE[ChainId.MAINNET]
  const token = ALE[ChainId.MAINNET]
  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  // const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo.tokens[0], userLiquidityUnstaked)
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, token , userLiquidityUnstaked)
  // const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)


  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  // const dummyPair = new Pair(new TokenAmount(stakingInfo.tokens[0], '0'), new TokenAmount(stakingInfo.tokens[1], '0'))
  // const tokenContract = useTokenContract(token.address)

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  // const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)
  const [approval ] = useApproveCallback(parsedAmount, chainId ? STAKING_REWARDS_ADDRESS : '')

  // const isArgentWallet = useIsArgentWallet()
  // const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)
  const bnbPoolContract = useAleStakingRewardsContract()
  
  async function onStake() {
    setAttempting(true)
    if (bnbPoolContract && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        bnbPoolContract.stake(`0x${parsedAmount.raw.toString(16)}`, { gasLimit: 350000 }) 
        .then((response: TransactionResponse) => {
                addTransaction(response, {
                  summary: `Stake ALE`
                })
                setHash(response.hash)
              })
              .catch((error: any) => {
                setAttempting(false)
                console.log(error)
              })
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }

      // } else if (signatureData) {
      //   stakingContract
      //     .stakeWithPermit(
      //       `0x${parsedAmount.raw.toString(16)}`,
      //       signatureData.deadline,
      //       signatureData.v,
      //       signatureData.r,
      //       signatureData.s,
      //       { gasLimit: 350000 }
      //     )
      //     .then((response: TransactionResponse) => {
      //       addTransaction(response, {
      //         summary: `Stake liquidity`
      //       })
      //       setHash(response.hash)
      //     })
      //     .catch((error: any) => {
      //       setAttempting(false)
      //       console.log(error)
      //     })
      // } else {
      //   setAttempting(false)
      //   throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      // }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])


  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Stake</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>

          
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={token} // TODO: รูปเหรียญ ALE ไม่ขึ้น
            // pair={dummyPair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to stake: '}
            id="stake-liquidity-token"
          />

          <RowBetween>
            <ButtonError
              disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? 'Stake'}
            </ButtonError>
          </RowBetween>
          {/* <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} /> */}
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Staking</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} ALE</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} ALE</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}


interface VaultStakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: AleStakingInfo
  vault: Vault
  userLiquidityUnstaked: TokenAmount | undefined
}

export function VaultStakingModal({ isOpen, onDismiss, stakingInfo, vault, userLiquidityUnstaked }: VaultStakingModalProps) {
  const { chainId } = useActiveWeb3React()

  // const vaultAddress = '0xFE663A7534BB00a54fE676ab7a4a15829Ab5DE1c';
  // const vaultAddress = '0x7a135E1b8597c8E7faF8b5A6634466cc23626f12';
  const lpToken = new Token(56,vault.lpTokenAddress,18);

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  // const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo?.stakedAmount.token, userLiquidityUnstaked)
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, lpToken , userLiquidityUnstaked)
  const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)

  // let hypotheticalRewardRate: TokenAmount = new TokenAmount(stakingInfo.rewardRate.token, '0')
  if (parsedAmountWrapped?.greaterThan('0')) {
    // hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
    //   stakingInfo.stakedAmount.add(parsedAmountWrapped),
    //   stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
    //   stakingInfo.totalRewardRate
    // )
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  const dummyPair = stakingInfo ? new Pair(new TokenAmount(stakingInfo.tokens[0], '0'), new TokenAmount(stakingInfo.tokens[1], '0')) : undefined
  // const pairContract = usePairContract(dummyPair?.liquidityToken.address)

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  // const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)
  const [approval] = useApproveCallback(parsedAmount, vault.vaultAddress)

  // const isArgentWallet = useIsArgentWallet()
  // const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)
  // const brewmasterContract = useBrewmasterContract()
  const vaultContract = useVaultContract(vault.vaultAddress)
  
  async function onStake() {
    setAttempting(true)
    if (vaultContract && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        vaultContract.deposit(`0x${parsedAmount.raw.toString(16)}`, { gasLimit: 350000 }) 
        .then((response: TransactionResponse) => {
                addTransaction(response, {
                  summary: `Stake liquidity`
                })
                setHash(response.hash)
              })
              .catch((error: any) => {
                setAttempting(false)
                console.log(error)
              })
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }

    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])


  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Stake</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={lpToken}
            pair={dummyPair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to stake: '}
            id="stake-liquidity-token"
          />

          {/* <HypotheticalRewardRate dim={!hypotheticalRewardRate.greaterThan('0')}>
            <div>
              <TYPE.black fontWeight={600}>Weekly Rewards</TYPE.black>
            </div>

            <TYPE.black>
              {hypotheticalRewardRate.multiply((60 * 60 * 24 * 7).toString()).toSignificant(4, { groupSeparator: ',' })}{' '}
              UNI / week
            </TYPE.black>
          </HypotheticalRewardRate> */}

          <RowBetween>
            {/* <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
              disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
            >
              Approve
            </ButtonConfirmed> */}
            <ButtonError
              disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? 'Stake'}
            </ButtonError>
          </RowBetween>
          {/* <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} /> */}
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Staking</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} LP</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} LP</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}