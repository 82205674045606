
import React, { useContext } from 'react'
import { useIsDarkMode } from 'state/user/hooks'
import styled, { ThemeContext } from 'styled-components'
import { ButtonCraft, ButtonPrimary, ButtonSecondary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { DataCard } from '../../components/earn/styled'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween, RowFixed } from '../../components/Row'
import { useActiveWeb3React } from '../../hooks'
import { Link } from 'react-router-dom'
import CraftCoinImg from '../../assets/images/CraftCoinHead.png'
import CraftCoinDarkImg from '../../assets/images/CraftCoinHead-dark.png'
import bgImage from '../../assets/images/land-bg.png'
import bgImageDark from '../../assets/images/land-bg-dark.png'
import ExternalLink from '../../assets/images/ExternalLink.png'
// import BlueBox from '../../assets/images/bluebox-bg.png'

import CraftRedeem from '../../assets/images/CraftRedeem.png'
import CraftMint from '../../assets/images/CraftMint.png'



const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const RoundShadowCard = styled(DataCard)`
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  background: white;
  overflow: hidden;
`
  // background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`


function CraftCoinLanding() {
  const darkMode = useIsDarkMode()

  return (
    <LandingContainer>


    <LandingScreen>
    
      <LandingRow>
      <WhiteBox url={darkMode ? bgImageDark : bgImage}><CraftCoinHead src={darkMode ? CraftCoinDarkImg : CraftCoinImg} /></WhiteBox>
      <WhiteBoxText url={darkMode ? bgImageDark : bgImage}><BoldText>CRAFT</BoldText> is our first asset backed stable coin in AleSwap. <br />
        1 CRAFT = 1 BUSD<br />
        Want to know more about CRAFT <a href="https://aleswapth.gitbook.io/aleswap/craft-ale-usage-guide"><ExternalImg src={ExternalLink} /></a></WhiteBoxText>
    
      <CraftRow>
        <ButtonCraft as={Link} to="/merchant/invoice"><CraftItemImg src={CraftMint} />Create Invoice</ButtonCraft>
        {/* <ButtonCraft as={Link} to="/craft/redeem"><CraftItemImg src={CraftRedeem} />REDEEM</ButtonCraft> */}
      </CraftRow> 
      </LandingRow>
    
    
    </LandingScreen>
    
    </LandingContainer>

    // <CraftCoinContainer>

    //   <MerchantScreen>
    //     <WhiteBox><CraftCoinHead src={CraftCoinImg} /></WhiteBox>
    //     <WhiteBoxText><BoldText>CRAFT</BoldText> is our first asset backed stable coin in AleSwap. <br />
    //       1 CRAFT = 1 BUSD<br />
    //       Want to know more about CRAFT <a href="https://aleswap-finance.gitbook.io/aleswap/craft-ale-usage-guide"><ExternalImg src={ExternalLink} /></a></WhiteBoxText>

    //     <CraftRow>
    //       <ButtonCraft as={Link} to="/craft/mint"><CraftItemImg src={CraftMint} />MINT</ButtonCraft>
    //       <ButtonCraft as={Link} to="/craft/redeem"><CraftItemImg src={CraftRedeem} />REDEEM</ButtonCraft>
    //     </CraftRow>



    //   </MerchantScreen>

    // </CraftCoinContainer>


  );
}


const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top:10px;
`;




const LandingRow = styled.div`
display:grid;
  width: 100%;
  
  align-items: center;
justify-items: center;


`;



const LandingScreen = styled.div`
  width: 100%;
  height: 100%;
  
  flex-direction: column;
 
  display: flex;
  align-items: center;
  
   margin: 0;
  
  
  
`;

// const CraftCoinContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 60%;
  
  
// `;


const WhiteBox = styled.div<{ url : string }>`
  background: url(${({ url }) => url});
// @media (max-width: 992px) {
//   width: 520px;
// }

// @media (max-width: 600px) {
//   width: 400px;
//   margin-top:50px;
// }
width:100%;
max-width:520px;
background-color:#ffffff;
color:#0B2553;
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;
display:grid;
align-items: center;
justify-items: center;
margin-bottom:20px;
padding-top:10px;
padding-bottom:10px;

  font-size: 1.5vw;
  text-align:center;
  


`;


const WhiteBoxText = styled.div<{ url : string }>`
background: url(${({ url }) => url});

// @media (max-width: 992px) {
//   width: 520px;
// }

// @media (max-width: 600px) {
//   width: 400px;
//   font-size: 3vw;
// }
width:100%;
max-width:520px;
background-color:#ffffff;
color:${({ theme }) => theme.text6};
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;

align-items: center;
justify-items: center;
margin-bottom:20px;
padding-top:10px;
padding-bottom:10px;


  text-align:center;


`;

const BoldText = styled.span`

font-weight:bold;



`;

const CraftCoinHead = styled.img`

width: 60%;

display:grid;
align-items: center;
justify-items: center;


`;

const ExternalImg = styled.img`

width: 18px;
display: inline-block;



`;

const CraftRow = styled.div`
// @media (max-width: 992px) {
//   width: 520px;
// }

// @media (max-width: 600px) {
//   width: 400px;
//   font-size: 1.5vw;
// }
width:100%;
max-width:520px;
display: grid;
    grid-template-columns: 1fr 1fr;
    
    column-gap:20px;
    row-gap: 20px;


`;

// const CraftItem = styled.div`


// padding: 10px;

// padding-top: 20px;




// background-color:#347AF0;
// color:#FFFFFF;
// box-shadow: 0px 1px 1px #00000040;
// border-radius: 15px;

// align-items: center;
// justify-items: center;
// margin-bottom:20px;

// background: url(${BlueBox}); 
// display:grid;
// font-weight:bold;
// font-size:1.8vw;


// `;

const CraftItemImg = styled.img`
margin-left:10%;
margin-bottom:20px;
margin-top:10px;

width: 80%;




`;




// const MerchantScreen = styled.div`
//   width: 40%;
//   height: 700px;
//   padding-top:10px;

  
  
//   flex-direction: column;
  
  
 
//   display: flex;
//   align-items: center;
  
//    margin: 0;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   -ms-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
  
// `;


export default function Merchant() {

  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        <AutoRow>
        <CraftCoinLanding />
        </AutoRow>

   
      </PageWrapper>
    </>
  )
}
